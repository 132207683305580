import { useMutation, useQueryClient } from '@tanstack/react-query';
import { followQueryKeys } from 'query-hooks/followings/contastants/query-keys.constants';

import { FollowService } from 'services/follow/follow.service';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const followTeamMutation = async (teamId: number) => {
  const followService = container.get<FollowService>(TYPES.FollowService);

  const response = await followService.followTeam(teamId);

  return response.success;
};

export const useFollowTeamMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: followTeamMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: followQueryKeys.prefix });
    },
  });

  return {
    followTeamMutation: mutation.mutate,
    isFetching: mutation.isPending,
  };
};
