import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';

import { NULL_PLAYER_OPTION_COLOR } from 'configs/controls.config';

export const preparePlayersSelectOptions = (players: IPlayerStats[]) => {
  const nullPlayerOption = {
    color: NULL_PLAYER_OPTION_COLOR,
    fontColor: PlayerFontColor.Light,
    logo: null,
    value: null,
    label: 'Player',
    mediumLogoUrl: '',
    smallLogoUrl: '',
    firstname: '',
    lastname: '',
  };

  const mappedTeamOptions = players.map((player) => ({
    color: player.color,
    fontColor: player.fontColor,
    logo: player.smallLogoUrl,
    label: `${player.firstname} ${player.lastname}`,
    mediumLogoUrl: player.mediumLogoUrl,
    smallLogoUrl: player.smallLogoUrl,
    firstname: player.firstname,
    lastname: player.lastname,
    value: player.id,
  }));

  return [nullPlayerOption, ...mappedTeamOptions];
};
