import { useCallback, useMemo, useState } from 'react';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';

import { FILES_IMAGE_TYPES, FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { EditorAttachmentsType } from 'components/editor/components/base-editor/base-editor.component';
import { GifPicker } from 'components/modals/gif-picker/gif-picker.component';
import { PostImageInput } from 'components/ui/form-fields/post-image-input/post-image-input.component';
import { UploadImageType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import './emoji-picker-override.css';
import styles from './toolbar.module.less';

interface IBottomToolbarPluginProps {
  disableAttachments: boolean;
  attachments: Maybe<EditorAttachmentsType>;
  isVideoLoading: boolean;
  isLoadingImages: boolean;
  isGifPickerOpen: boolean;
  onImageLoad: UploadImageType;
  onUploadVideo?: (video: File) => void;
  onGifSelect: (gif: string) => void;
  setLoadingImages?: (value: string[]) => void;
  setIsGifPickerOpen: (value: boolean) => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
}

export const BottomToolbarPlugin = (props: IBottomToolbarPluginProps) => {
  const {
    disableAttachments,
    isGifPickerOpen,
    attachments,
    isLoadingImages,
    isVideoLoading,
    setIsGifPickerOpen,
  } = props;

  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);

  const handleToggleShowGifPicker = useCallback(() => {
    setIsGifPickerOpen(!isGifPickerOpen);
  }, [isGifPickerOpen, setIsGifPickerOpen]);

  const handleAddVideo = useCallback(() => {
    setIsVideoInputOpen(true);
  }, [setIsVideoInputOpen]);

  const isVideoButtonDisabled = useMemo(() => {
    return Boolean(
      attachments?.videos?.length ||
        Number(attachments?.gifs?.length) + Number(attachments?.images?.length) > 8 ||
        attachments?.poll ||
        isVideoLoading,
    );
  }, [attachments, isVideoLoading]);

  const idGifButtonDisabled = useMemo(() => {
    return Boolean(
      Number(attachments?.gifs?.length) + Number(attachments?.images?.length) > 8 ||
        attachments?.poll,
    );
  }, [attachments]);

  return (
    <div className={styles.Toolbar}>
      {!disableAttachments && (
        <IconButton
          theme={IconButtonTheme.Secondary}
          iconName={IconFontName.VideoCamera}
          iconSize={IconFontSize.Big}
          onClick={handleAddVideo}
          disabled={isVideoButtonDisabled}
        />
      )}
      {!disableAttachments && (
        <PostImageInput
          convertImageItemToAttachment={props.convertImageItemToAttachment}
          accept={FILES_IMAGE_TYPES.join(', ')}
          onImageLoad={props.onImageLoad}
          id="attachImage"
          name="attachImage"
          isDisabled={isLoadingImages}
          setLoadingImages={props.setLoadingImages}
        />
      )}
      {!disableAttachments && (
        <IconButton
          theme={IconButtonTheme.Secondary}
          iconName={IconFontName.Gif}
          iconSize={IconFontSize.Big}
          onClick={handleToggleShowGifPicker}
          disabled={idGifButtonDisabled}
        />
      )}
      {isGifPickerOpen && (
        <GifPicker
          onGifSelect={props.onGifSelect}
          onClose={handleToggleShowGifPicker}
          visible={isGifPickerOpen}
        />
      )}
      <div className={styles.Video}>
        <PostVideoInput
          isVideoInputOpen={isVideoInputOpen}
          setIsVideoInputOpen={setIsVideoInputOpen}
          accept={FILES_VIDEO_TYPES.join(', ')}
          onVideoLoad={props.onUploadVideo}
          id="attachVideo"
          name="attachVideo"
          onError={props.onVideoError}
          isVideoPermissionsRequested={isVideoPermissionsRequested}
          setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
        />
      </div>
    </div>
  );
};
