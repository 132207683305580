import { useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useFollowTeamMutation } from 'query-hooks/followings/mutations/use-follow-team-mutation';
import { useUnFollowTeamMutation } from 'query-hooks/followings/mutations/use-unfollow-team-query';
import { useFollowData } from 'query-hooks/followings/use-follow-data';

import { ApplicationStore } from 'stores/application/application.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutStore } from 'stores/layout/layout.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { TeamProfileHeader } from 'components/team-profile/team-profile-header/team-profile-header.component';
import { TeamProfileHeaderInfo } from 'components/team-profile/team-profile-header-info/team-profile-header-info.component';

export const TeamProfileHeaderInfoContainer = observer(() => {
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);

  const { teamsStats } = teamsStatsStore;

  const { followData, isFetchingFollow, getIsFollowTeam, getTeamById } = useFollowData();

  const currentTeam = getTeamById(teamsStore.teamId);

  const { followTeamMutation } = useFollowTeamMutation();

  const { unFollowTeamMutation } = useUnFollowTeamMutation();

  const isFollowedTeam = useMemo(() => {
    return teamsStore.teamId ? getIsFollowTeam(teamsStore.teamId) : false;
  }, [teamsStore.teamId, getIsFollowTeam]);

  const handleFollowTeam = useCallback(async () => {
    if (!teamsStore.teamId) {
      return;
    }

    await applicationStore.triggerNotificationPermissionsCheck();
    followTeamMutation(teamsStore.teamId);
  }, [applicationStore, followTeamMutation, teamsStore.teamId]);

  const handleUnFollowTeam = useCallback(async () => {
    if (!teamsStore.teamId) {
      return;
    }

    unFollowTeamMutation(teamsStore.teamId);
  }, [teamsStore.teamId, unFollowTeamMutation]);

  const renderHeader = useCallback(() => {
    if (currentTeam) {
      return (
        <TeamProfileHeader
          isVisibleForMobile={layoutStore.isMobilePlayerOrTeamHeaderVisible}
          isFollowFetched={!!followData.length}
          isFollowedTeam={isFollowedTeam}
          isFollowFetching={isFetchingFollow}
          avatarUrl={currentTeam.mediumLogoUrl}
          name={currentTeam.name}
          onFollowTeam={handleFollowTeam}
          onUnfollowTeam={handleUnFollowTeam}
        />
      );
    }

    return null;
  }, [
    currentTeam,
    isFetchingFollow,
    followData,
    handleFollowTeam,
    handleUnFollowTeam,
    layoutStore.isMobilePlayerOrTeamHeaderVisible,
    isFollowedTeam,
  ]);

  useLayoutEntity({
    type: LayoutEntity.HeaderCenter,
    value: renderHeader,
  });

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = teamsStats
        ? {
            name: teamsStats.nickname,
            logoUrl: teamsStats.smallLogoUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, teamsStats],
  );

  return (
    <TeamProfileHeaderInfo
      isFollowFetching={isFetchingFollow}
      isFollowFetched={!!followData.length}
      isFollowedTeam={isFollowedTeam}
      teamsStats={teamsStats}
      onOpenPreview={handleOpenPreview}
      onFollowTeam={handleFollowTeam}
    />
  );
});
