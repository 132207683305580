import { memo, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Keyboard } from '@capacitor/keyboard';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';

import { editorCommentSchema } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorType,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { IForm } from 'components/forms/form.interface';
import { Loader } from 'components/ui/loader/loader.component';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import styles from './editor-bottom-sheet.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

const commentCreateSchema = z
  .object({
    editor: editorCommentSchema,
  })
  .strict();

type CommentCreateDataType = z.infer<typeof commentCreateSchema>;

interface ICommentEditorBottomSheetProps extends IForm<CommentCreateDataType> {
  visible: boolean;
  title?: string;
  placeholder?: string;
  withLoader?: boolean;
  onCloseBottomSheet: () => void;
}

export const TextCommentEditorBottomSheet = memo((props: ICommentEditorBottomSheetProps) => {
  const {
    visible,
    processing,
    initialValues,
    withLoader = true,
    onSubmit,
    title,
    onCloseBottomSheet,
    placeholder = 'Add comment',
  } = props;

  const { isNativeApp, isNativeAndroidApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const { control, formState, handleSubmit, reset } = useForm<CommentCreateDataType>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(commentCreateSchema),
  });

  const { errors, isValid } = formState;

  const editorBottomSheetClasses = useMemo<string>(
    () =>
      cn(styles.EditorBottomSheet, {
        [styles['EditorBottomSheet--processing']]: processing,
        [styles['EditorBottomSheet--keyboard']]: isKeyboardOpen,
      }),
    [processing, isKeyboardOpen],
  );

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleBottomSheetClose = useCallback(() => {
    onCloseBottomSheet();

    // setTimeout is used to clear the editor's content after the bottom sheet animation
    setTimeout(() => {
      reset();
    });
  }, [reset, onCloseBottomSheet]);

  useEffect(() => {
    if (isNativeApp && !isNativeAndroidApp) {
      Keyboard.setAccessoryBarVisible({ isVisible: !visible });
    }
  }, [visible, isNativeApp, isNativeAndroidApp]);

  return (
    <BaseIonBottomSheet
      visible={visible}
      isAutoHeight
      safeAreaBottom={0}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      onClose={handleBottomSheetClose}
    >
      {title && <div className={styles.Title}>{title}</div>}
      <Form className={editorBottomSheetClasses} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="editor"
          control={control}
          render={({ field }) => (
            <Editor
              editorType={EditorType.CommentMobile}
              disableAttachments
              placeholder={placeholder}
              id="editor"
              name="editor"
              isActive={visible}
              disabled={!isValid || processing}
              value={field.value}
              error={errors.editor?.content?.length?.message}
              onChange={handleEditorChange(field.onChange)}
            />
          )}
        />
      </Form>
      {withLoader && <Loader isShow={!!processing} />}
    </BaseIonBottomSheet>
  );
});
