import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { WithActiveType } from 'types/with-active.type';

import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarMultipleAction } from 'components/bars/bar-action.type';
import { BarActionsListType } from 'components/bars/bar-actions-list-type.enum';
import { IBarItem } from 'components/bars/bar-item.interface';
import { SubBottomBarItem } from 'components/bars/bottom-bar-mobile/sub-bottom-bar-item/sub-bottom-bar-item.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './sub-bottom-bar.module.less';

interface ISubBottomBarProps {
  activePath: string;
  onClick: (action: BarMultipleAction<BarActionsListType>) => void;
  items: IBarItem<BarMultipleAction<BarActionsListType>>[];
  onHideClick: () => void;
  subMenuBackgroundColor: Maybe<string>;
  subMenuTextColor: Maybe<string>;
  isOpenMore: boolean;
}

export const SubBottomBar: FC<ISubBottomBarProps> = memo((props: ISubBottomBarProps) => {
  const { activePath, onClick, items, subMenuBackgroundColor, subMenuTextColor, isOpenMore } =
    props;

  const isActiveNavItem = useCallback(
    (action: BarMultipleAction<BarActionsListType>) => {
      if (action.type !== BarAction.Link) {
        return false;
      }

      const isActiveUsersFeedRoute = (path: string): boolean => {
        return (
          path.includes(paths.FEED_PART) ||
          path.includes(paths.PREDICTIONS_PART) ||
          path.includes(paths.BOOKMARKS)
        );
      };

      if (isActiveUsersFeedRoute(activePath) && action.payload === BarActionsListType.Feed) {
        return true;
      }

      const isActivePlayerStatsRoute = (path: string): boolean => {
        return (
          path.includes(paths.GAMES_PART) ||
          path.includes(paths.STATS_PART) ||
          path.includes(paths.SPLITS_PART)
        );
      };

      if (
        action.payload === BarActionsListType.PlayerStats &&
        isActivePlayerStatsRoute(activePath)
      ) {
        return true;
      }

      const isActiveTeamStatsRoute = (path: string): boolean => {
        return path.includes(paths.SCORES_PART) || path.includes(paths.STATS_PART);
      };

      if (action.payload === BarActionsListType.TeamScores && isActiveTeamStatsRoute(activePath)) {
        return true;
      }

      const isActiveCommonFeedRoute = (path: string): boolean => {
        return path.includes(paths.FEED_PART) || path.includes(paths.FAN_ZONE_PART);
      };

      if (action.payload === BarActionsListType.Feed && isActiveCommonFeedRoute(activePath)) {
        return true;
      }

      if (action.path === paths.HOME_STATS_PLAYERS && activePath.includes(paths.HOME_STATS_TEAMS)) {
        return true;
      }

      if (action.path === paths.PLAYERS_RANKINGS && activePath.includes(paths.PLAYERS_RANKINGS)) {
        return true;
      }

      return action.path.includes(activePath);
    },
    [activePath],
  );

  const preparedNavItems = useMemo<
    Array<WithActiveType<IBarItem<BarMultipleAction<BarActionsListType>>>>
  >(
    () =>
      items.map((item) => ({
        ...item,
        isActive: isActiveNavItem(item.action),
      })),
    [items, isActiveNavItem],
  );

  const handleItemClick = useCallback(
    (action: BarMultipleAction<BarActionsListType>) => () => {
      onClick(action);
    },
    [onClick],
  );

  const backgroundColorStyles = useMemo(() => {
    if (subMenuBackgroundColor && subMenuTextColor) {
      return {
        background: subMenuBackgroundColor,
        color: subMenuTextColor,
        borderColor: subMenuBackgroundColor,
      };
    }

    return {};
  }, [subMenuBackgroundColor, subMenuTextColor]);

  const subBottomBarClasses = useMemo(
    () =>
      cn(styles.SubBottomBar, {
        [styles['SubBottomBar--hide']]: !isOpenMore,
      }),
    [isOpenMore],
  );

  return (
    <nav style={backgroundColorStyles} className={subBottomBarClasses}>
      <ul className={styles.SubBottomBar__ItemsList}>
        {preparedNavItems.map((item) => (
          <SubBottomBarItem
            key={item.name}
            isActive={item.isActive}
            icon={item.icon}
            name={item.name}
            onClick={handleItemClick(item.action)}
          />
        ))}

        <SubBottomBarItem
          key="more"
          isActive
          icon={{ default: IconFontName.ChevronRight, active: IconFontName.ChevronRight }}
          name=""
          onClick={props.onHideClick}
        />
      </ul>
    </nav>
  );
});
