import { useMutation, useQueryClient } from '@tanstack/react-query';
import { followQueryKeys } from 'query-hooks/followings/contastants/query-keys.constants';

import { FollowService } from 'services/follow/follow.service';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const followPlayerMutation = async (playerId: number) => {
  const followService = container.get<FollowService>(TYPES.FollowService);

  const response = await followService.followPlayer(playerId);

  return response.success;
};

export const useFollowPlayerMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: followPlayerMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: followQueryKeys.prefix });
    },
  });

  return {
    followPlayerMutation: mutation.mutate,
    isFetchingFollowPlayer: mutation.isPending,
  };
};
