import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useFollowData } from 'query-hooks/followings/use-follow-data';

import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';

import { AuthStore } from 'stores/auth/auth.store';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { CollaborationCommentsStore } from 'stores/collaboration-comments/collaboration-comments.store';
import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { checkIsUserStaff } from 'helpers/check-is-user-staff.util';
import { getPath } from 'helpers/get-path.util';
import { trimEditorContent } from 'helpers/trim-editor-content.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';
import { useTeamClick } from 'hooks/use-team-click';

import { TextCommentEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/text-comment-editor-bottom-sheet.component';
import { CollaborationComment } from 'components/collaboration-section/collaboration-comment/collaboration-comment.component';
import { EmptyState } from 'components/empty-state/empty-state.component';
import {
  CommentCreateDataType,
  TextCommentCreateForm,
} from 'components/forms/text-comment-create/text-comment-create-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { FloatingButton } from 'components/ui/floating-button/floating-button.component';
import { Loader } from 'components/ui/loader/loader.component';

import noCommentsSrc from 'assets/images/svg/no-comments.svg';

import styles from './collaboration-comments.module.less';

export const CollaborationCommentsContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const collaborationStore = useInjection<CollaborationStore>(TYPES.CollaborationStore);
  const collaborationCommentsStore = useInjection<CollaborationCommentsStore>(
    TYPES.CollaborationCommentsStore,
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [commentDeleteId, setCommentDeleteId] = useState<Maybe<number>>(null);
  const [isCreateCommentSuccess, setIsCreateCommentSuccess] = useState(false);
  const [isNeedActivateEditor, setIsNeedActivateEditor] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isTextEditorShown, setIsTextEditorShown] = useState(false);

  const handleAddCommentClick = useCallback(() => {
    if (!authStore.isAuthorised) return;

    if (isDesktopPlus) {
      setIsNeedActivateEditor(true);
    } else {
      setIsTextEditorShown(true);
    }
  }, [authStore.isAuthorised, isDesktopPlus]);

  const resetEditor = useCallback(() => {
    setIsCreateCommentSuccess(true);
    setIsTextEditorShown(false);
  }, []);

  const handleSubmitComment = useCallback(
    () =>
      async ({ editor }: CommentCreateDataType) => {
        if (!authStore.isAuthorised) {
          return;
        }

        const comment: ICommentCreatePayload = {
          attachments: {},
        };

        if (editor.content?.length && editor.content.length > 0) {
          comment.content = editor.content.value;

          const editorState = JSON.parse(editor?.content?.value);
          const content = editorState?.root?.children;

          const newEditorState = {
            ...editorState,
            root: {
              ...editorState.root,
              children: trimEditorContent(content),
            },
          };

          comment.content = JSON.stringify(newEditorState);
        }

        await collaborationCommentsStore.createCollaborationComment(comment);

        resetEditor();
      },
    [authStore.isAuthorised, collaborationCommentsStore, resetEditor],
  );

  const handleCommentLike = useCallback(
    async (commentId: number) => {
      if (!authStore.isAuthorised) return;

      await collaborationCommentsStore.toggleLikeCollaborationComment(commentId);
    },
    [authStore.isAuthorised, collaborationCommentsStore],
  );

  const handleShowMoreComments = useCallback(() => {
    if (collaborationStore.collaborationId && collaborationStore.collaborationItemId) {
      collaborationCommentsStore.fetchNextCollaborationComments(
        collaborationStore.collaborationId,
        collaborationStore.collaborationItemId,
      );
    }
  }, [
    collaborationStore.collaborationId,
    collaborationStore.collaborationItemId,
    collaborationCommentsStore,
  ]);

  const handleCommentDelete = useCallback(
    async (collaborationCommentId: number) => {
      if (!authStore.triggerAuthorisationCheck()) return;

      setCommentDeleteId(collaborationCommentId);

      setIsConfirmModalOpen(true);
    },
    [authStore],
  );

  const handleOnConfirmDeleteComment = useCallback(async () => {
    if (commentDeleteId) {
      await collaborationCommentsStore.deleteCollaborationComment(commentDeleteId);

      setCommentDeleteId(null);
      setIsConfirmModalOpen(false);
    }
  }, [collaborationCommentsStore, commentDeleteId]);

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleOpenEditorBottomSheet = useCallback(() => {
    if (authStore.triggerAuthorisationCheck()) {
      setIsTextEditorShown(true);
    }
  }, [authStore]);

  useEffect(() => {
    if (!location.state?.focusEditor) {
      return;
    }

    if (isDesktopPlus) {
      setIsNeedActivateEditor(true);
      navigate(location.pathname, {
        replace: true,
      });

      return;
    }

    setIsTextEditorShown(true);
  }, [location, isDesktopPlus, navigate]);

  const { getPlayerById, getTeamById } = useFollowData();

  return (
    <>
      {!collaborationCommentsStore.fetched &&
      collaborationCommentsStore.fetching &&
      !collaborationCommentsStore.entries.length ? (
        <div className={styles.CommentLoaderWrapper}>
          <Loader isShow />
        </div>
      ) : collaborationCommentsStore.entries.length ? (
        <div className={styles.CommentsWrapper}>
          {collaborationCommentsStore.entries.map(
            ({ id, user, createdAt, isLiked, content, likes }) => (
              <CollaborationComment
                isAthlete={Boolean(user.athlete)}
                isLiked={isLiked}
                onLikes={handleCommentLike}
                key={id}
                author={{
                  uuid: user.uuid,
                  name: user.name,
                  username: user.username,
                  smallAvatarUrl: user.smallAvatarUrl,
                }}
                getPlayerById={getPlayerById}
                getTeamById={getTeamById}
                favoriteTeamId={user.favoriteTeamId}
                favoritePlayerId={user.favoritePlayerId}
                likes={likes}
                commentData={{
                  comment: content,
                  createAt: createdAt.relativeShort,
                }}
                commentId={id}
                onDeleteComment={handleCommentDelete}
                onTeamClick={handleTeamClick}
                onPlayerClick={handlePlayerClick}
                isAllowedCommentDelete={
                  user.uuid === authStore.userMe?.id || checkIsUserStaff(authStore.userMe?.roles)
                }
              />
            ),
          )}
        </div>
      ) : !isDesktopPlus ? (
        <div className={styles.CommentsWrapper__EmptyState}>
          <EmptyState
            title="No Comments Yet"
            message="Be the first to say what you think"
            imgSrc={noCommentsSrc}
            btnText="Add Comment"
            onBtnClick={handleAddCommentClick}
          />
        </div>
      ) : null}
      {!!collaborationCommentsStore.entries.length && !collaborationCommentsStore.isLastPage && (
        <div className={styles.CommentsWrapper__ShowMore}>
          <Button
            fluid
            onClick={handleShowMoreComments}
            size={ButtonSize.SmallSecondary}
            theme={ButtonTheme.Tertiary}
            name="showMoreComment"
          >
            Show more comments
          </Button>
        </div>
      )}
      {isDesktopPlus && (
        <div className={styles.CommentCreate}>
          <TextCommentCreateForm
            placeholder="Add comment"
            isFixedPosition
            isCreateCommentSuccess={isCreateCommentSuccess}
            isNeedActivateEditor={isNeedActivateEditor}
            processing={collaborationCommentsStore.fetchingCreateComment}
            setIsCreateCommentSuccess={setIsCreateCommentSuccess}
            setIsNeedActivateEditor={setIsNeedActivateEditor}
            onSubmit={handleSubmitComment()}
          />
        </div>
      )}
      {isConfirmModalOpen && (
        <ConfirmationModal
          content="Are you sure you want to delete this comment? This cannot be undone."
          onSuccessCallback={handleOnConfirmDeleteComment}
          onClose={() => setIsConfirmModalOpen(false)}
          title="Delete Comment"
          visible
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        />
      )}
      {!isDesktopPlus && (
        <FloatingButton
          hasSmartBanner={layoutStore.isDisplayedSmartBanner}
          onClick={handleOpenEditorBottomSheet}
        />
      )}
      {!isDesktopPlus && (
        <TextCommentEditorBottomSheet
          visible={isTextEditorShown}
          processing={collaborationCommentsStore.fetchingCreateComment}
          onCloseBottomSheet={() => setIsTextEditorShown(false)}
          onSubmit={handleSubmitComment()}
        />
      )}
    </>
  );
});
