import { FC } from 'react';

import { IFollow } from 'services/follow/interfaces/follow.interface';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';

import { Favorites } from 'components/favorites/favorites';

import { BaseIonBottomSheet } from '../base-ion-bottom-sheet/base-ion-bottom-sheet.component';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 0.8;

interface IFavoritesIonBottomSheetProps {
  visible: boolean;
  onClose: () => void;
  favorites: IFollow[];
  digitsAthletes: IAthlete[];
  onFollowClick: () => void;
  onFollowedItemClick: (following: IFollow) => () => void;
  onAthleteClick: (athlete: IAthlete) => () => void;
}

export const FavoritesIonBottomSheet: FC<IFavoritesIonBottomSheetProps> = (props) => {
  const { visible, favorites, digitsAthletes } = props;

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      isAutoHeight
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      onClose={props.onClose}
    >
      <Favorites
        digitsAthletes={digitsAthletes}
        favorites={favorites}
        onFollowClick={props.onFollowClick}
        onFollowedItemClick={props.onFollowedItemClick}
        onAthleteClick={props.onAthleteClick}
      />
    </BaseIonBottomSheet>
  );
};
