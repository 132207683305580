import { memo, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 } from 'uuid';
import { z } from 'zod';

import {
  ConvertImageItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';

import { pollOptionsSchema } from 'validation/schemas/poll-options.schema';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IPollAnswer } from 'components/ui/form-fields/poll-form/interfaces/poll.interface';
import { Toggle } from 'components/ui/form-fields/toggle/toggle.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { PollAnswerControl } from './poll-option-input/poll-option-input.component';

import styles from './poll-form.module.less';

const MAX_ANSWERS_AMOUNT = 4;

const pollPostFormSchema = z.object({
  options: pollOptionsSchema,
});

type PollPostFormDataType = z.infer<typeof pollPostFormSchema>;

interface IPollFormProps {
  onPullUpdate: (options: IPollAnswer[], triggerValidation?: boolean) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
}

export const PollForm = memo((props: IPollFormProps) => {
  const { onPullUpdate, convertImageItemToAttachment } = props;

  const [isMediaToggleChecked, setIsMediaToggleChecked] = useState(false);

  const { control, setValue, formState, watch, trigger } = useForm<PollPostFormDataType>({
    defaultValues: {
      options: [
        {
          id: v4(),
          title: '',
          order: 0,
        },
        {
          id: v4(),
          title: '',
          order: 1,
        },
      ],
    },
    resolver: zodResolver(pollPostFormSchema),
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'options',
  });
  const { errors } = formState;

  const handleToggleChange = useCallback((value: boolean) => {
    setIsMediaToggleChecked(value);
  }, []);

  const handlePollOptionChange = useCallback(
    (value: IUploadImageData | string, order: number, field: 'title' | 'imageData') => {
      setValue(`options.${order}.${field}`, value);
    },
    [setValue],
  );

  const handlePollOptionBlur = useCallback(
    (order: number, field: 'title' | 'imageData') => {
      trigger(`options.${order}.${field}`);
    },
    [trigger],
  );

  const handleRemoveOption = useCallback(
    (index: number) => {
      remove(index);
      const leftOptions = fields.filter((_, i) => i !== index);

      leftOptions.forEach((field, i) => {
        update(i, { ...field, order: i });
      });
    },
    [remove, fields, update],
  );

  const handleAppendOption = useCallback(() => {
    append({ id: v4(), title: '', order: fields.length });
  }, [append, fields]);

  useEffect(() => {
    const subscription = watch(({ options }) => {
      if (options && options.length) {
        // Options are of type IPollAnswer[] but watch function marks them as DeepPartial<IPollAnswer>[]
        // as is used here as a workaround of the type mismatch
        onPullUpdate(options as IPollAnswer[]);
      }
    });

    return () => subscription.unsubscribe();
  }, [onPullUpdate, watch]);

  useEffect(() => {
    onPullUpdate(fields);
  }, [onPullUpdate, fields]);

  const options = watch('options');

  return (
    <div className={styles.PollForm}>
      <div className={styles.PollForm__Header}>
        <span className={styles.PollForm__HeaderLabel}>Options</span>
        <div className={styles.PollForm__Header__Toggle}>
          <span>Add Media</span>
          <Toggle
            id="add-media"
            name="add-media"
            onChange={handleToggleChange}
            value={isMediaToggleChecked}
          />
        </div>
      </div>
      {options.map((option, index) => (
        <PollAnswerControl
          key={option.id}
          answer={option}
          isRemoveButtonActive={options.length > 2}
          onAnswerRemove={() => handleRemoveOption(index)}
          onPollOptionChange={handlePollOptionChange}
          onPollOptionBlur={handlePollOptionBlur}
          withMedia={isMediaToggleChecked}
          convertImageItemToAttachment={convertImageItemToAttachment}
          errors={errors?.options?.[index]}
        />
      ))}
      {options.length < MAX_ANSWERS_AMOUNT && (
        <div className={styles.PollForm__Footer}>
          <div className={styles.PollForm__Button}>
            <Button
              theme={ButtonTheme.Secondary}
              size={ButtonSize.SmallSecondary}
              iconSize={IconFontSize.Small}
              iconName={IconFontName.Add}
              onClick={handleAppendOption}
            >
              Add an Option
            </Button>
          </div>
          <div className={styles.PollForm__Tip}>
            <span>{`You can add ${MAX_ANSWERS_AMOUNT - fields.length} more options`}</span>
          </div>
        </div>
      )}
    </div>
  );
});
