import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useFollowPlayerMutation } from 'query-hooks/followings/mutations/use-follow-player-mutation';
import { useFollowData } from 'query-hooks/followings/use-follow-data';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { GalleryStore } from 'stores/gallery/gallery.store';
import { PlayerStore } from 'stores/player/player.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { TYPES } from 'configs/di-types.config';

import { useTeamClick } from 'hooks/use-team-click';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { PlayerProfileHeaderInfo } from 'components/player-profile/player-profile-header-info/player-profile-header-info.component';

export const PlayerProfileHeaderInfoContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);

  const handleTeamClick = useTeamClick();

  const { getIsFollowPlayer, followData } = useFollowData();

  const handleOpenPreview = useCallback(
    (image: IInteractiveMediaAttachment) => {
      const watermarkEntity = playerStore.playerDetails
        ? {
            name: `${playerStore.playerDetails.firstname} ${playerStore.playerDetails.lastname}`,
            logoUrl: playerStore.playerDetails.smallLogoUrl,
          }
        : null;

      galleryStore.setGalleryAttachments([image]);
      galleryStore.setTheme(GalleryTheme.Preview);
      galleryStore.setWatermarkEntity(watermarkEntity);
      galleryStore.setIsGalleryOpen(true);
    },
    [galleryStore, playerStore.playerDetails],
  );

  const { followPlayerMutation, isFetchingFollowPlayer } = useFollowPlayerMutation();

  const handleFollowPlayer = useCallback(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileFollow,
          player: `${firstname} ${lastname}`,
        },
      });
      followPlayerMutation(playerStore.playerDetails.id);
    }
  }, [playerStore.playerDetails, followPlayerMutation]);

  if (!playerStore.playerDetails) {
    return null;
  }

  return (
    <PlayerProfileHeaderInfo
      socialLinks={playerStore.athlete?.socialLinks || []}
      isFollowFetching={isFetchingFollowPlayer}
      isFollowFetched={!!followData.length}
      isFollowedPlayer={getIsFollowPlayer(playerStore.playerDetails.id)}
      playerDetails={playerStore.playerDetails}
      onTeamClick={handleTeamClick}
      onOpenPreview={handleOpenPreview}
      onFollowPlayer={handleFollowPlayer}
    />
  );
});
