import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { IGetCommentPayload } from 'services/comments/interfaces/comments.interface';
import { ITogglePostCommentBookmarkPayload } from 'services/posts/interfaces/create-post-payload.interface';

import { IBookmarkUser } from 'stores/bookmarks/interfaces/bookmarks.interface';
import { BookmarksFilterType, BookmarksType } from 'stores/bookmarks/types/bookmarks.type';
import { OpenPreviewType } from 'stores/gallery/types/gallery.type';
import { SelectedHeaderTabType } from 'stores/layout/types/layout-entities.type';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ISharePayload } from 'containers/posts/interfaces/share-payload.interface';

import {
  NO_BOOKMARKS_MESSAGE,
  NO_BOOKMARKS_TITLE,
  NO_RESULTS_MESSAGE,
  NO_RESULTS_TITLE,
} from 'components/bookmarks/bookmarks.config';
import { BookmarksFilter } from 'components/bookmarks/bookmarks-filter/bookmarks-filter.component';
import { BookmarksHeader } from 'components/bookmarks/bookmarks-header/bookmarks-header.component';
import { BookmarkList } from 'components/bookmarks/bookmarks-list/bookmarks-list.component';
import { BookmarksSearch } from 'components/bookmarks/bookmarks-search/bookmarks-search.component';
import { ISearchFormData } from 'components/forms/search-form/search-form.component';
import { Loader } from 'components/ui/loader/loader.component';

import emptyBookmarksSrc from 'assets/images/svg/no-bookmarks-purple.svg';
import noSearchResultsSrc from 'assets/images/svg/no-search-results.svg';

import { EmptyState } from '../empty-state/empty-state.component';
import { PostCardFeedsTheme } from '../posts/post-card/post-card-feeds/post-card-feeds.component';
import { IPollUpdateData, IPollVoteData } from '../ui/poll/interfaces/poll.interface';

import styles from './bookmarks.module.less';

interface IBookmarksProps {
  feedsTheme: PostCardFeedsTheme;
  users: Array<IBookmarkUser>;
  types: BookmarksFilterType;
  sortBy: string;
  bookmarks: Array<BookmarksType>;
  searchValue: ISearchFormData;
  isHeaderVisible: boolean;
  isLoading: boolean;
  isOpenModule: boolean;
  isForSidePanel: boolean;
  isFilterOpen: boolean;
  userId: Maybe<string>;
  isAthlete: boolean;
  selectedBookmarksUsers: Array<IBookmarkUser>;
  hasMoreBookmarks: boolean;
  activeTab: SelectedHeaderTabType;
  setSidePanelActiveTab: (tabName: SelectedHeaderTabType) => void;
  onResetFilters: () => void;
  onCloseBookmarks: () => void;
  onTogglePostBookmark: (id: string, teamId?: Maybe<number>, playerId?: Maybe<number>) => void;
  onToggleCollaborationItemBookmark: (collaborationId: number, collaborationItemId: number) => void;
  setIsFilterOpen: (id: boolean) => void;
  onToggleCommentBookmark: (payload: ITogglePostCommentBookmarkPayload) => void;
  onSetSearchValue: (value: ISearchFormData) => void;
  onApplyButtonClick: (
    sortOrder: string,
    bookmarksTypes: BookmarksFilterType,
    selectedUsers: Array<IBookmarkUser>,
  ) => void;
  onPollVote: (voteData: IPollVoteData) => void;
  onPollUpdate: (pollData: IPollUpdateData) => void;
  onPollExpired: (postId: string) => void;
  onLoadMore: () => void;
  onOpenComment: (payload: IGetCommentPayload) => void;
  onShareClick: (payload: ISharePayload) => void;
  onOpenPreview: OpenPreviewType;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  getPlayerById: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  getTeamById: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
}

export const Bookmarks: FC<IBookmarksProps> = (props) => {
  const {
    feedsTheme,
    activeTab,
    types,
    users = [],
    sortBy,
    bookmarks = [],
    searchValue,
    isOpenModule = false,
    isHeaderVisible,
    userId,
    isAthlete,
    onSetSearchValue,
    onResetFilters,
    selectedBookmarksUsers,
    isForSidePanel = true,
    setIsFilterOpen,
    isFilterOpen,
    setSidePanelActiveTab,
    hasMoreBookmarks,
    isLoading,
    getPlayerById,
    getTeamById,
  } = props;

  const [filtersCount, setFiltersCount] = useState(0);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleBackButtonClick = useCallback(() => {
    setIsFilterOpen(false);
  }, [setIsFilterOpen]);

  useEffect(() => {
    if (!activeTab) {
      setIsFilterOpen(false);
    }
  }, [activeTab, setIsFilterOpen]);

  const handleFilterButtonClick = useCallback(() => {
    if (activeTab === 'bookmarked' && isFilterOpen) {
      setIsFilterOpen(false);
      setSidePanelActiveTab(null);

      return;
    }

    if (activeTab === 'bookmarked' && !isFilterOpen) {
      setIsFilterOpen(true);

      return;
    }

    setIsFilterOpen(true);
    setSidePanelActiveTab('bookmarked');
  }, [isFilterOpen, setIsFilterOpen, setSidePanelActiveTab, activeTab]);

  const handleSearchButtonClick = useCallback(() => {
    setIsSearchActive(true);
  }, [setIsSearchActive]);

  const handleResetButtonClick = useCallback(() => {
    setIsFilterOpen(false);
    onResetFilters();
  }, [setIsFilterOpen, onResetFilters]);

  const handleCloseSearch = useCallback(() => {
    setIsSearchActive(false);
    onSetSearchValue({ searchPhrase: '' });
  }, [onSetSearchValue, setIsSearchActive]);

  useEffect(() => {
    let count = selectedBookmarksUsers.length;

    types.forEach((item) => {
      if (item.selected) {
        count += 1;
      }
    });

    setFiltersCount(count);
  }, [types, selectedBookmarksUsers]);

  const isEmptyState = useMemo(() => {
    return Boolean(
      !isLoading &&
        !bookmarks.length &&
        (searchValue.searchPhrase || filtersCount) &&
        (!isForSidePanel || (isForSidePanel && !isFilterOpen)),
    );
  }, [isLoading, bookmarks, searchValue.searchPhrase, filtersCount, isForSidePanel, isFilterOpen]);

  const isEmptyBookmarkState = useMemo(() => {
    return Boolean(
      !isLoading &&
        !bookmarks.length &&
        !searchValue.searchPhrase &&
        !filtersCount &&
        (!isForSidePanel || (isForSidePanel && !isFilterOpen)),
    );
  }, [isLoading, bookmarks, searchValue.searchPhrase, filtersCount, isForSidePanel, isFilterOpen]);

  const isShowBookmarksList = useMemo(() => {
    return Boolean((!isFilterOpen && bookmarks.length) || !isForSidePanel);
  }, [bookmarks, isForSidePanel, isFilterOpen]);

  if (!isOpenModule) {
    return null;
  }

  return (
    <div className={styles.Bookmarks}>
      {isHeaderVisible && (
        <BookmarksHeader
          isForSidePanel={isForSidePanel}
          filtersCount={filtersCount}
          isFilterOpen={isFilterOpen}
          onSearchButtonClick={handleSearchButtonClick}
          onFilterButtonClick={handleFilterButtonClick}
          onBackButtonClick={handleBackButtonClick}
          onCloseButtonClick={props.onCloseBookmarks}
          isSearchActive={isSearchActive}
        />
      )}
      {isForSidePanel && isFilterOpen && (
        <BookmarksFilter
          users={users}
          sortBy={sortBy}
          types={types}
          selectedBookmarksUsers={selectedBookmarksUsers}
          onResetButtonClick={handleResetButtonClick}
          onApplyButtonClick={props.onApplyButtonClick}
          setIsFilterOpen={setIsFilterOpen}
        />
      )}
      {isForSidePanel && !isFilterOpen && isSearchActive && (
        <BookmarksSearch
          onCancelButtonClick={handleCloseSearch}
          onChange={props.onSetSearchValue}
          value={searchValue}
        />
      )}
      {isEmptyBookmarkState && (
        <EmptyState
          title={NO_BOOKMARKS_TITLE}
          message={NO_BOOKMARKS_MESSAGE}
          imgSrc={emptyBookmarksSrc}
          isForSidePanel={isForSidePanel}
        />
      )}
      {isEmptyState && (
        <EmptyState
          message={NO_RESULTS_MESSAGE}
          title={NO_RESULTS_TITLE}
          imgSrc={noSearchResultsSrc}
        />
      )}
      {isShowBookmarksList && (
        <BookmarkList
          getPlayerById={getPlayerById}
          getTeamById={getTeamById}
          feedsTheme={feedsTheme}
          onOpenComment={props.onOpenComment}
          isForSidePanel={isForSidePanel}
          userId={userId}
          isAthlete={isAthlete}
          hasMore={hasMoreBookmarks}
          onLoadMore={props.onLoadMore}
          bookmarks={bookmarks}
          onToggleCommentBookmark={props.onToggleCommentBookmark}
          onTogglePostBookmark={props.onTogglePostBookmark}
          onPollVote={props.onPollVote}
          onPollUpdate={props.onPollUpdate}
          onPollExpired={props.onPollExpired}
          onShareClick={props.onShareClick}
          onOpenPreview={props.onOpenPreview}
          onCloseBookmarks={props.onCloseBookmarks}
          onTeamClick={props.onTeamClick}
          onPlayerClick={props.onPlayerClick}
          onToggleCollaborationItemBookmark={props.onToggleCollaborationItemBookmark}
        />
      )}
      <Loader isShow={isLoading} isLocal={!!bookmarks.length || !isForSidePanel} />
    </div>
  );
};
