import { FC, ReactNode, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Camera } from '@capacitor/camera';
import cn from 'classnames';
import { MediaType } from 'types/media-type.type';

import { IWatermarkEntity } from 'stores/gallery/interfaces/watermark-entity.interface';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { splitByTheLastSymbol } from 'helpers/split-by-the-last-symbol';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { handleIosPhotosDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-photos-denied.util';
import {
  IframePreview,
  IframePreviewSize,
} from 'components/iframe-preview/iframe-preview.component';
import { IModal } from 'components/modals/base-modal/base-modal.component';
import { ImagePreview } from 'components/modals/gallery-modal/components/image-preview/image-preview.component';
import { SmallPreview } from 'components/modals/gallery-modal/components/small-preview/small-preview.component';
import {
  isGifFormat,
  watermarkImageWithText,
} from 'components/modals/gallery-modal/gallery-modal.utils';
import { createImage } from 'components/modals/image-crop-modal/crop-image.utils';
import { IAuthor } from 'components/modals/share-modal/share-modal.component';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import { SliderWithTooltip } from 'components/tooltip-slider/tooltip-slider.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { SlideArrow } from 'components/ui/images-carousel-preview/components/slide-arrow/slide-arrow.component';
import {
  Video,
  VideoSize,
} from 'components/ui/images-carousel-preview/components/video/video.component';
import { Loader, LoaderSizeEnum } from 'components/ui/loader/loader.component';
import { Portal, PortalType } from 'components/ui/portal/portal.component';

import logo from 'assets/images/small-logo.png';

import {
  INITIAL_ROTATE_ANGEL,
  INITIAL_ZOOM,
  MAX_ROTATE_ANGEL,
  MAX_ZOOM,
  MIN_ZOOM,
  ROTATE_ANGEL_STEP,
  SLIDER_POINT_ACTIVE_COLOUR,
  SLIDER_POINT_ACTIVE_HEIGHT,
  SLIDER_POINT_ACTIVE_MARGIN,
  SLIDER_POINT_ACTIVE_WIDTH,
  SLIDER_TRACK_COLOUR,
  SLIDER_TRACK_HEIGHT,
  ZOOM_STEP,
  ZOOM_STEP_FOR_SLIDER,
} from './gallery-modal.config';

import styles from './gallery-modal.module.less';

export enum GalleryTheme {
  Default = 'default',
  Preview = 'preview',
}

interface IGalleryProps extends IModal {
  attachments: IInteractiveMediaAttachment[];
  author: Maybe<IAuthor>;
  date: string;
  currentAttachmentId: number;
  theme: GalleryTheme;
  watermarkEntity: Maybe<IWatermarkEntity>;
  setCurrentAttachmentId: (index: number) => void;
  onFetchMediaFile: (path: string) => Promise<string>;
  onDownloadMediaFileToGallery: (path: string, mediaType: MediaType) => Promise<void>;
}

export const GalleryModal: FC<IGalleryProps> = (props: IGalleryProps) => {
  const {
    visible,
    author,
    attachments,
    date,
    currentAttachmentId,
    theme,
    watermarkEntity,
    onClose,
    setCurrentAttachmentId,
    onDownloadMediaFileToGallery,
    onFetchMediaFile,
  } = props;

  const [isContentOnHover, setIsContentOnHover] = useState(false);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [rotateAngle, setRotateAngle] = useState(INITIAL_ROTATE_ANGEL);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp, isNativeAndroidApp } = useMainProvider();

  useEffect(() => {
    if (!visible) {
      setIsDownloading(false);
    }
  }, [visible]);

  const handleClose = useCallback(() => {
    onClose();
    setIsDownloading(false);
    setZoom(INITIAL_ZOOM);
    setCurrentAttachmentId(0);
    setRotateAngle(INITIAL_ROTATE_ANGEL);
  }, [setZoom, onClose, setCurrentAttachmentId]);

  const handleZoomIn = useCallback(() => {
    const newZoom = zoom + ZOOM_STEP;

    if (newZoom <= 0) {
      setZoom(0);
    } else if (newZoom <= 0.5) {
      setZoom(0.5);
    } else if (newZoom <= 1) {
      setZoom(1);
    } else if (newZoom <= 1.5) {
      setZoom(1.5);
    } else {
      setZoom(2);
    }
  }, [zoom, setZoom]);

  const handleZoomOut = useCallback(() => {
    const newZoom = zoom - ZOOM_STEP;

    if (newZoom >= 1.5) {
      setZoom(1.5);
    } else if (newZoom >= 1) {
      setZoom(1);
    } else if (newZoom >= 0.5) {
      setZoom(0.5);
    } else if (newZoom >= 0) {
      setZoom(0);
    } else {
      setZoom(-0.5);
    }
  }, [zoom, setZoom]);

  const handleZoomChange = useCallback(
    (value: number | number[]) => {
      if (!Array.isArray(value)) {
        setZoom(value);
      }
    },
    [setZoom],
  );

  const handleRotate = useCallback(() => {
    if (rotateAngle + ROTATE_ANGEL_STEP < MAX_ROTATE_ANGEL) {
      setRotateAngle(rotateAngle + ROTATE_ANGEL_STEP);
    } else {
      setRotateAngle(INITIAL_ROTATE_ANGEL);
    }
  }, [rotateAngle, setRotateAngle]);

  const handleChangeCurrentAttachment = useCallback(
    (id: number) => {
      setCurrentAttachmentId(id);
      setZoom(INITIAL_ZOOM);
      setRotateAngle(INITIAL_ROTATE_ANGEL);
    },
    [setCurrentAttachmentId],
  );

  const currentAttachment = useMemo(() => {
    if (!attachments.length) {
      return {
        url: '',
        type: '',
        uuid: '',
        filename: '',
        mimeType: '',
      };
    }

    return attachments[currentAttachmentId];
  }, [attachments, currentAttachmentId]);

  const imageStyles = useMemo(() => {
    let transform = 'center';

    if (zoom > INITIAL_ZOOM && !rotateAngle) {
      transform = 'top';
    }

    return {
      transform: `scale(${zoom + 1}) rotate(${rotateAngle}deg)`,
      transformOrigin: transform,
    };
  }, [zoom, rotateAngle]);

  const imagesCounterText = useMemo(() => {
    if (!attachments.length) {
      return 'No items';
    }

    if (attachments.length === 0) {
      return '1 item';
    }

    return `${currentAttachmentId + 1} of ${attachments.length} items`;
  }, [attachments, currentAttachmentId]);

  const isNavigationVisible = useMemo(() => {
    if (attachments.length <= 1) {
      return false;
    }

    return isContentOnHover;
  }, [attachments, isContentOnHover]);

  const sizeCloseIcon = useMemo(() => {
    if (isDesktopPlus) {
      return IconFontSize.ExtraBig;
    }

    return IconFontSize.Small;
  }, [isDesktopPlus]);

  const fileName = useMemo(() => {
    if (!currentAttachment?.filename) {
      return 'default';
    }

    return splitByTheLastSymbol(currentAttachment?.filename)[0];
  }, [currentAttachment?.filename]);

  const fileExtension = useMemo(() => {
    if (currentAttachment?.type === 'gif') {
      return 'gif';
    }

    if (currentAttachment?.type === 'video') {
      return splitByTheLastSymbol(currentAttachment?.filename, '.')[1];
    }

    if (!currentAttachment?.mimeType) {
      return 'png';
    }

    const extension = splitByTheLastSymbol(currentAttachment?.mimeType, '/')[1];

    if (extension === 'webp') {
      return 'png';
    }

    return extension;
  }, [currentAttachment]);

  const fileNameLabel = useMemo<ReactNode>(() => {
    if (theme === GalleryTheme.Preview) {
      return null;
    }

    if (!fileName || currentAttachment?.type === 'gif') {
      return date;
    }

    return (
      <>
        {date} - <span>{fileName}</span>.{fileExtension}
      </>
    );
  }, [theme, date, fileName, fileExtension, currentAttachment?.type]);

  const downloadByClick = useCallback(
    (url: string) => {
      const anchorElement = document.createElement('a');
      anchorElement.href = url;
      anchorElement.download = `${fileName}.${fileExtension}`;
      anchorElement.click();
      setIsDownloading(false);
    },
    [fileName, fileExtension],
  );

  const handleDownloadMediaFileToGallery = useCallback(
    async (path: string, mediaType: MediaType) => {
      await onDownloadMediaFileToGallery(path, mediaType);
      setIsDownloading(false);
    },
    [onDownloadMediaFileToGallery],
  );

  const handleDownloadClick = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      const permission = await Camera.checkPermissions();

      if (permission.photos === 'denied' && !isNativeAndroidApp) {
        await handleIosPhotosDenied();
      } else {
        setIsDownloading(true);

        if (currentAttachment.type === 'image') {
          const isGif = isGifFormat(currentAttachment.url);

          if (isGif) {
            if (isNativeApp) {
              await handleDownloadMediaFileToGallery(currentAttachment.url, 'gif');
              setIsDownloading(false);

              return;
            }

            const href = await onFetchMediaFile(currentAttachment.url);

            downloadByClick(href);
            window.URL.revokeObjectURL(href);
            setIsDownloading(false);

            return;
          }

          const originalImage = await createImage(currentAttachment.url);

          const imageWithWatermarkUrl = await watermarkImageWithText(
            originalImage,
            watermarkEntity?.name || 'Home - Feed',
            author?.username || '',
            watermarkEntity?.logoUrl || logo,
            theme,
          );

          if (isNativeApp) {
            await handleDownloadMediaFileToGallery(imageWithWatermarkUrl, 'image');

            return;
          }

          downloadByClick(imageWithWatermarkUrl);
        }

        if (currentAttachment.type === 'video') {
          if (isNativeApp) {
            await handleDownloadMediaFileToGallery(currentAttachment.url, 'video');

            return;
          }

          const href = await onFetchMediaFile(currentAttachment.url);

          downloadByClick(href);
          window.URL.revokeObjectURL(href);
          setIsDownloading(false);
        }
      }
    },
    [
      theme,
      watermarkEntity,
      currentAttachment,
      author,
      isNativeApp,
      isNativeAndroidApp,
      handleDownloadMediaFileToGallery,
      downloadByClick,
      onFetchMediaFile,
    ],
  );

  const handleContentOnHover = useCallback(() => {
    setIsContentOnHover(true);
  }, [setIsContentOnHover]);

  const handleContentOffHover = useCallback(() => {
    setIsContentOnHover(false);
  }, [setIsContentOnHover]);

  const resetImageChanges = useCallback(() => {
    setZoom(INITIAL_ZOOM);
    setRotateAngle(INITIAL_ROTATE_ANGEL);
  }, [setZoom, setRotateAngle]);

  const handleShowPrevImage = useCallback(() => {
    resetImageChanges();
    if (currentAttachmentId - 1 < 0) {
      setCurrentAttachmentId(attachments.length - 1);
    } else {
      setCurrentAttachmentId(currentAttachmentId - 1);
    }
  }, [setCurrentAttachmentId, currentAttachmentId, attachments, resetImageChanges]);

  const handleShowNextImage = useCallback(() => {
    resetImageChanges();
    if (currentAttachmentId + 1 >= attachments.length) {
      setCurrentAttachmentId(0);
    } else {
      setCurrentAttachmentId(currentAttachmentId + 1);
    }
  }, [setCurrentAttachmentId, currentAttachmentId, attachments, resetImageChanges]);

  const percentFormatter = useCallback((value: number) => {
    return `${Math.round(value * 100)} %`;
  }, []);

  const classModalWrapperNames = useMemo(() => cn(styles.ModalWrapper, TOUCH_IGNORE_CLASS), []);

  return visible ? (
    <Portal type={PortalType.Modal}>
      <div className={classModalWrapperNames}>
        <div
          className={styles.ModalWrapper__Header}
          role="button"
          aria-label="Close"
          tabIndex={0}
          onClick={handleClose}
          onKeyDown={handleClose}
        >
          <div className={styles.FileName}>{fileNameLabel}</div>
          <IconButton
            iconSize={sizeCloseIcon}
            iconName={IconFontName.Close}
            theme={IconButtonTheme.Secondary}
          />
        </div>
        <div
          className={styles.ModalWrapper__Content}
          onMouseEnter={handleContentOnHover}
          onMouseLeave={handleContentOffHover}
        >
          <SlideArrow
            isNeedZIndex
            direction="prev"
            onClick={handleShowPrevImage}
            isEnabled
            isVisible={isNavigationVisible}
          />
          {currentAttachment.type === 'image' && (
            <ImagePreview
              url={currentAttachment.url}
              filename={currentAttachment.filename}
              isDesktopPlus={isDesktopPlus}
              desktopStyles={imageStyles}
            />
          )}
          {currentAttachment.type === 'video' && (
            <Video
              isMuted={false}
              isAutoplay
              size={VideoSize.FULL}
              type={currentAttachment.mimeType}
              url={currentAttachment.url}
            />
          )}
          {currentAttachment.type === 'gif' && (
            <SingleGif id={currentAttachment.url} size={GifSize.L} />
          )}
          {currentAttachment.type === 'iframe' && (
            <IframePreview url={currentAttachment.url} size={IframePreviewSize.FULL} />
          )}
          <SlideArrow
            direction="next"
            isNeedZIndex
            onClick={handleShowNextImage}
            isEnabled
            isVisible={isNavigationVisible}
          />
          <div
            className={styles.Overlay}
            role="button"
            aria-label="Close"
            tabIndex={0}
            onClick={handleClose}
            onKeyDown={handleClose}
          />
        </div>
        <div className={styles.ModalWrapper__Footer}>
          {theme === GalleryTheme.Default && (
            <div className={styles.Counter}>{imagesCounterText}</div>
          )}
          <div className={styles.ActionBar}>
            {author && (
              <div className={styles.ActionBar__User}>
                <div className={styles.Avatar}>
                  <Avatar
                    size={AvatarSize.M}
                    src={author.smallAvatarUrl}
                    username={author.username}
                  />
                </div>
                {isDesktopPlus && <span className={styles.RealName}>@{author.username}</span>}
                {!isDesktopPlus && <div className={styles.Divider} />}
              </div>
            )}
            {theme === GalleryTheme.Default && (
              <div className={styles.ActionBar__SmallImages}>
                <SmallPreview
                  attachments={attachments}
                  onClick={handleChangeCurrentAttachment}
                  selectedItem={currentAttachmentId}
                />
              </div>
            )}
            <div className={styles.ActionBar__Actions}>
              {isDesktopPlus && currentAttachment.type === 'image' && (
                <>
                  <IconButton
                    dataId="zoom"
                    iconName={IconFontName.Minus}
                    theme={IconButtonTheme.Secondary}
                    onClick={handleZoomOut}
                    disabled={currentAttachment.type !== 'image'}
                  />
                  <SliderWithTooltip
                    disabled={currentAttachment.type !== 'image'}
                    tipFormatter={percentFormatter}
                    className={styles.RootSlider}
                    trackStyle={{
                      backgroundColor: SLIDER_TRACK_COLOUR,
                      height: SLIDER_TRACK_HEIGHT,
                    }}
                    handleStyle={{
                      height: SLIDER_POINT_ACTIVE_HEIGHT,
                      width: SLIDER_POINT_ACTIVE_WIDTH,
                      marginTop: SLIDER_POINT_ACTIVE_MARGIN,
                      backgroundColor: SLIDER_POINT_ACTIVE_COLOUR,
                      border: 'none',
                    }}
                    value={zoom}
                    min={MIN_ZOOM}
                    max={MAX_ZOOM}
                    step={ZOOM_STEP_FOR_SLIDER}
                    onChange={handleZoomChange}
                  />
                  <IconButton
                    dataId="zoom"
                    iconName={IconFontName.Add}
                    theme={IconButtonTheme.Secondary}
                    onClick={handleZoomIn}
                    disabled={currentAttachment.type !== 'image'}
                  />
                </>
              )}
              {isDesktopPlus && (
                <IconButton
                  iconName={IconFontName.Rotate}
                  theme={IconButtonTheme.Secondary}
                  onClick={handleRotate}
                  disabled={currentAttachment.type !== 'image'}
                />
              )}
              <div className={styles.Divider} />
              {isDownloading ? (
                <div className={styles.LoaderWrapper}>
                  <Loader isShow size={LoaderSizeEnum.S} />
                </div>
              ) : (
                <IconButton
                  iconName={IconFontName.Download}
                  theme={IconButtonTheme.Secondary}
                  onClick={handleDownloadClick}
                  disabled={currentAttachment.type === 'gif' || !fileExtension}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  ) : null;
};
