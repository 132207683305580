import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { LocalDatabaseService } from 'services/local-database/local-database.service';
import { IPlayerDetailsResponse } from 'services/player/interfaces/player.interface';
import { IPlayerCareerResponse } from 'services/player/interfaces/player-career.interface';
import {
  IPlayerSplitsOpponentResponse,
  IPlayerSplitsStatsResponse,
} from 'services/player/interfaces/player-splits.interface';
import { IPlayerRankedStatsResponse } from 'services/player/interfaces/player-stats.interface';
import { PlayerService } from 'services/player/player.service';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { playerAthleteAdapter } from 'stores/auth/adapters/player-athlete-adapter.util';
import { IPlayerAthlete, IPlayerAthleteResponse } from 'stores/auth/interfaces/athlete.interface';
import { playerSplitsAdapter } from 'stores/player/adapters/player-splits-adapter.util';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { isSeasonTypeValue } from 'stores/seasons/utils/is-season-type-value.util';

import {
  DEFAULT_CAREER_PER_MODE_OPTION,
  DEFAULT_CAREER_SEASON_TYPE_OPTION,
} from 'containers/player-profile/player-profile.config';

import { TYPES } from 'configs/di-types.config';

import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import { playerAboutAdapter } from './adapters/player-about.adapter.util';
import { playerCareerAdapter } from './adapters/player-career-adapter.util';
import { playerDetailsAdapter } from './adapters/player-details-adapter.util';
import { playerStatsCareerAverageRowDataAdapter } from './adapters/player-stats-career-average-row-data-adapter.util';
import { playerStatsRowDataAdapter } from './adapters/player-stats-row-data-adapter.util';
import { topPlayersAdapter } from './adapters/top-players.adapter';
import { GuideStorageKeys } from './enums/guide-storage-keys.enum';
import {
  IPlayerCareer,
  IPlayerDetails,
  IPlayerSplits,
} from './interfaces/player-profile.interfaces';
import { ITopPlayerItem } from './interfaces/top-player-item.interface';
import { isPerModeValue } from './utils/is-per-mode.util';

@injectable()
export class PlayerStore extends ApiConnectedStore {
  private readonly localDatabaseService: LocalDatabaseService;

  private readonly playerService: PlayerService;

  private readonly seasonsStore: SeasonsStore;

  public isGuideTooltipGamesShown: boolean;

  public seasonOption: Maybe<ISelectOption>;

  public playerSlug: Maybe<string>;

  public playerDetails: Maybe<IPlayerDetails>;

  public playerCareer: Maybe<IPlayerCareer>;

  public currentTeamId: Maybe<number>;

  public playerSplits: Maybe<IPlayerSplits>;

  public careerSeasonTypeOption: ISelectOption;

  public careerPerModeOption: ISelectOption;

  public topPlayers: ITopPlayerItem[];

  public athlete: Maybe<IPlayerAthlete>;

  constructor(
    @inject<PlayerService>(TYPES.PlayerService) playerService: PlayerService,
    @inject<SeasonsStore>(TYPES.SeasonsStore) seasonsStore: SeasonsStore,
    @inject<SeasonsStore>(TYPES.LocalDatabaseService) localDatabaseService: LocalDatabaseService,
  ) {
    super();

    this.localDatabaseService = localDatabaseService;

    this.seasonsStore = seasonsStore;

    this.playerService = playerService;

    this.isGuideTooltipGamesShown = false;

    this.seasonOption = null;

    this.playerSlug = null;

    this.playerDetails = null;

    this.currentTeamId = null;

    this.playerCareer = null;

    this.playerSplits = null;

    this.careerSeasonTypeOption = DEFAULT_CAREER_SEASON_TYPE_OPTION;

    this.careerPerModeOption = DEFAULT_CAREER_PER_MODE_OPTION;

    this.topPlayers = [];

    this.athlete = null;

    makeObservable(this, {
      athlete: observable,
      isGuideTooltipGamesShown: observable,
      playerSlug: observable,
      seasonOption: observable,
      playerDetails: observable,
      playerCareer: observable,
      currentTeamId: observable,
      playerSplits: observable,
      careerSeasonTypeOption: observable,
      careerPerModeOption: observable,
      topPlayers: observable,

      playerStatsRowData: computed,
      playerStatsCareerAverageRowData: computed,

      setGuideTooltipGamesShown: action.bound,
      setPlayerSlug: action.bound,
      setPlayerDetails: action.bound,
      setPlayerCareer: action.bound,
      setSeasonOption: action.bound,
      setCurrentTeamId: action.bound,
      setPlayerSplits: action.bound,
      setCareerSeasonTypeOption: action.bound,
      setCareerPerModeOption: action.bound,
      setTopPlayers: action.bound,
      reset: action.bound,
      setAthlete: action.bound,
      retrieveAthlete: action.bound,
    });

    reaction(
      () => [this.careerSeasonTypeOption.value, this.careerPerModeOption.value],
      this.handleCareerFilterChange,
    );

    reaction(() => this.playerSlug, this.handlePlayerChange);

    autorun(() => this.setSeasonOption(this.seasonsStore.currentSeasonOption));

    autorun(() => this.handleGuideTooltipsStateSync());

    reaction(() => this.playerSlug, this.retrieveAthlete);
  }

  public setAthlete(athlete: Maybe<IPlayerAthlete>) {
    this.athlete = athlete;
  }

  public async retrieveAthlete(): Promise<void> {
    if (!this.playerSlug) {
      this.setAthlete(null);
    } else {
      const athleteResponse: IResponse<IPlayerAthleteResponse> =
        await this.playerService.fetchAthleteBySlug(this.playerSlug);

      if (athleteResponse.success) {
        const athlete = playerAthleteAdapter(athleteResponse.data);
        this.setAthlete(athlete);
      } else {
        this.setErrors(athleteResponse.errors);
      }
    }
  }

  private handleCareerFilterChange = async () => {
    await this.retrievePlayerCareer();
  };

  private handlePlayerChange = async () => {
    this.resetSomePlayerDetails();
    await this.retrievePlayerDetails();

    if (this.playerDetails?.team) {
      const teamId = this.playerDetails.team.id;

      this.setCurrentTeamId(teamId);
    }
  };

  private handleGuideTooltipsStateSync = async () => {
    const state = await this.localDatabaseService.getPopupsByKey(
      GuideStorageKeys.PlayerProfileGames,
    );

    this.setGuideTooltipGamesShown(!state);
  };

  public setGuideTooltipGamesShown(isGuideTooltipGamesShown: boolean) {
    this.isGuideTooltipGamesShown = isGuideTooltipGamesShown;
  }

  public setPlayerDetails(playerDetails: Maybe<IPlayerDetails>) {
    this.playerDetails = playerDetails;
  }

  public setPlayerCareer(playerCareer: Maybe<IPlayerCareer>) {
    this.playerCareer = playerCareer;
  }

  public setPlayerSlug(playerSlug: Maybe<string>) {
    this.playerSlug = playerSlug;
  }

  public setSeasonOption(seasonOption: Maybe<ISelectOption>) {
    this.seasonOption = seasonOption;
  }

  public setCurrentTeamId(currentTeamId: Maybe<number>) {
    this.currentTeamId = currentTeamId;
  }

  public setPlayerSplits(splits: Maybe<IPlayerSplits>) {
    this.playerSplits = splits;
  }

  public setCareerSeasonTypeOption(option: ISelectOption) {
    this.careerSeasonTypeOption = option;
  }

  public setCareerPerModeOption(option: ISelectOption) {
    this.careerPerModeOption = option;
  }

  public setTopPlayers(topPlayers: ITopPlayerItem[]) {
    this.topPlayers = topPlayers;
  }

  public getAboutThePlayer(isDesktopPlus: boolean) {
    if (!this.playerDetails) {
      return [];
    }

    return playerAboutAdapter(this.playerDetails, isDesktopPlus);
  }

  public get playerStatsRowData() {
    if (this.playerDetails) {
      return playerStatsRowDataAdapter(this.playerDetails);
    }

    return [];
  }

  public get playerStatsCareerAverageRowData() {
    if (this.playerCareer) {
      return playerStatsCareerAverageRowDataAdapter(this.playerCareer);
    }

    return [];
  }

  public async fetchTopPlayers() {
    const response = await this.playerService.fetchTopPlayers(PostsFeedType.Player);

    if (response.success) {
      this.setTopPlayers(topPlayersAdapter(response.data.items));
    }
  }

  public changeGuideTooltipState(key: GuideStorageKeys) {
    this.localDatabaseService.updatePopupsTableByKey(key, true);

    if (key === GuideStorageKeys.PlayerProfileGames) {
      this.setGuideTooltipGamesShown(false);
    }
  }

  public async retrievePlayerDetails(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    if (this.playerSlug) {
      const detailsPromise: Promise<IResponse<IPlayerDetailsResponse>> =
        this.playerService.fetchPlayerDetails(this.playerSlug);

      const rankedStatsPromise: Promise<IResponse<Maybe<IPlayerRankedStatsResponse>>> =
        this.playerService.fetchPlayerRankedStats(
          this.playerSlug,
          this.seasonOption?.value || null,
        );

      const [detailsResponse, rankedStatsResponse] = await Promise.all([
        detailsPromise,
        rankedStatsPromise,
      ]);

      if (detailsResponse.success && rankedStatsResponse.success) {
        this.setPlayerDetails(playerDetailsAdapter(detailsResponse.data, rankedStatsResponse.data));
      } else {
        const errors = [
          ...(detailsResponse.success ? [] : detailsResponse.errors),
          ...(rankedStatsResponse.success ? [] : rankedStatsResponse.errors),
        ];

        this.setErrors(errors);
      }
    } else {
      this.setPlayerDetails(null);
    }

    this.setFetching(false);
  }

  public async retrievePlayerCareer(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    if (
      this.playerSlug &&
      isSeasonTypeValue(this.careerSeasonTypeOption.value) &&
      isPerModeValue(this.careerPerModeOption.value)
    ) {
      const response: IResponse<IPlayerCareerResponse> = await this.playerService.fetchPlayerCareer(
        this.playerSlug,
        this.careerSeasonTypeOption.value,
        this.careerPerModeOption.value,
      );

      if (response.success) {
        this.setPlayerCareer(playerCareerAdapter(response.data));
      } else {
        this.setErrors(response.errors);
        this.setPlayerCareer(null);
      }
    } else {
      this.setPlayerCareer(null);
    }

    this.setFetching(false);
  }

  public async retrievePlayerSplits(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    if (this.playerSlug) {
      const rankedStatsPromise: Promise<IResponse<Maybe<IPlayerRankedStatsResponse>>> =
        this.playerService.fetchPlayerRankedStats(
          this.playerSlug,
          this.seasonOption?.value || null,
        );

      const splitsPromise: Promise<IResponse<IPlayerSplitsStatsResponse[]>> =
        this.playerService.fetchPlayerSplits(this.playerSlug, this.seasonOption?.value || null);

      const opponentPromise: Promise<IResponse<IPlayerSplitsOpponentResponse[]>> =
        this.playerService.fetchPlayerSplitsOpponent(
          this.playerSlug,
          this.seasonOption?.value || null,
        );

      const [rankedStatsResponse, splitsResponse, opponentResponse] = await Promise.all([
        rankedStatsPromise,
        splitsPromise,
        opponentPromise,
      ]);

      if (rankedStatsResponse.success && splitsResponse.success && opponentResponse.success) {
        this.setPlayerSplits(
          playerSplitsAdapter(rankedStatsResponse.data, splitsResponse.data, opponentResponse.data),
        );
      } else {
        const errors = [
          ...(rankedStatsResponse.success ? [] : rankedStatsResponse.errors),
          ...(splitsResponse.success ? [] : splitsResponse.errors),
          ...(opponentResponse.success ? [] : opponentResponse.errors),
        ];

        this.setErrors(errors);
      }
    } else {
      this.setPlayerSplits(null);
    }

    this.setFetching(false);
  }

  public reset() {
    this.setSeasonOption(this.seasonsStore.currentSeasonOption);
    this.setPlayerSlug(null);
    this.setPlayerDetails(null);
    this.setPlayerCareer(null);
    this.setCurrentTeamId(null);
    this.setPlayerSplits(null);
    this.setCareerSeasonTypeOption(DEFAULT_CAREER_SEASON_TYPE_OPTION);
    this.setCareerPerModeOption(DEFAULT_CAREER_PER_MODE_OPTION);
  }

  public resetSomePlayerDetails() {
    this.setSeasonOption(this.seasonsStore.currentSeasonOption);
    this.setPlayerDetails(null);
    this.setPlayerCareer(null);
    this.setCurrentTeamId(null);
    this.setPlayerSplits(null);
    this.setCareerSeasonTypeOption(DEFAULT_CAREER_SEASON_TYPE_OPTION);
    this.setCareerPerModeOption(DEFAULT_CAREER_PER_MODE_OPTION);
  }
}
