import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { NULL_TEAM_OPTION_COLOR, NULL_TEAM_OPTION_TEXT_COLOR } from 'configs/controls.config';

export const prepareTeamsSelectOptions = (teams: ITeamsStats[], label: string, code: string) => {
  const nullTeamOption = {
    color: NULL_TEAM_OPTION_COLOR,
    secondaryTextColor: NULL_TEAM_OPTION_TEXT_COLOR,
    logo: null,
    value: null,
    code,
    label,
    mediumLogoUrl: '',
    smallLogoUrl: '',
    nickname: '',
    name: code,
  };

  const mappedTeamOptions = teams.map((team) => ({
    label: `[${team.code}] ${team.name}`,
    code: team.code,
    name: team.name,
    color: team.color,
    secondaryTextColor: team.secondaryTextColor,
    logo: team.smallLogoUrl,
    nickname: team.nickname,
    mediumLogoUrl: team.mediumLogoUrl,
    smallLogoUrl: team.smallLogoUrl,
    value: team.id,
  }));

  return [nullTeamOption, ...mappedTeamOptions];
};
