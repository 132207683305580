import { useCallback, useMemo } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useUnFollowPlayerMutation } from 'query-hooks/followings/mutations/use-unfollow-player-mutation';
import { useUnFollowTeamMutation } from 'query-hooks/followings/mutations/use-unfollow-team-query';
import { useFollowData } from 'query-hooks/followings/use-follow-data';
import { useUserBalanceQuery } from 'query-hooks/reputation/use-user-balance.query';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IEventParams } from 'services/google-analytic/interfaces/event-payload.interface';

import { AuthStore } from 'stores/auth/auth.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { PlayerStore } from 'stores/player/player.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { ShareStore } from 'stores/share/share.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { stringIncludesOneOf } from 'containers/layout/layout.utils';
import { RANKING_CATEGORIES } from 'containers/player-rankings/player-rankings.config';

import {
  PLAYER_PROFILE_HEADER_INFO_GROUPS,
  TEAM_HEADER_INFO_GROUPS,
} from 'configs/context-menu-groups.config';
import { DEFAULT_GREY_ICON_COLOR } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';
import { STATS_PART } from 'routes/paths.constants';

import { useGaButtonCustomTap } from 'hooks/use-ga-button-custom-tap';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { GameDetailsTabs } from 'components/game/game.config';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './header-struture.module.less';

export const HeaderStructureContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);

  const { data: currentUserBalance } = useUserBalanceQuery(authStore.userMe?.username);

  const location = useLocation();
  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { googleAnalyticsButtonTap } = useGaButtonCustomTap();

  const params = useParams<{
    [path.PLAYER_SLUG_PARAM]: string;
    [path.GAME_ID_PARAM]: string;
    [path.CATEGORY_PARAM]: string;
    [path.POST_ID_PARAM]: string;
  }>();

  const { playerSlug, gameId, category, postId } = params;

  const { getIsFollowPlayer, getIsFollowTeam, getTeamById } = useFollowData();

  const currentTeam = getTeamById(teamsStore.teamId);

  const handleSearchClick = useCallback(() => {
    navigate(path.SEARCH);

    if (location.pathname.includes(STATS_PART)) {
      googleAnalyticsButtonTap(ButtonTapsEnum.StatsSearch);

      return;
    }

    const eventParams: IEventParams = {};

    const player = playerStore.playerDetails;

    if (FeedTypes.Player === layoutStore.activeFeed && player) {
      eventParams.button_tap_type = ButtonTapsEnum.PlayerSearch;
      eventParams.player = `${player.firstname} ${player.lastname}`;
    }

    if (FeedTypes.Team === layoutStore.activeFeed && teamsStatsStore.team) {
      eventParams.button_tap_type = ButtonTapsEnum.TeamSearch;
      eventParams.team = teamsStatsStore.team.name;
    }

    if (
      layoutStore.activeFeed &&
      FeedTypes.Player !== layoutStore.activeFeed &&
      FeedTypes.Team !== layoutStore.activeFeed
    ) {
      eventParams.button_tap_type = ButtonTapsEnum.HomeSearch;
      eventParams.feed = layoutStore.activeFeed;
    }

    if (eventParams.button_tap_type) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams,
      });
    }
  }, [
    playerStore.playerDetails,
    layoutStore.activeFeed,
    teamsStatsStore.team,
    location.pathname,
    navigate,
    googleAnalyticsButtonTap,
  ]);

  const isShowSearchButton = useMemo(() => {
    if (isDesktopPlus) {
      return false;
    }

    return stringIncludesOneOf(location.pathname, [
      path.FEED_PART,
      path.STANDINGS_PART,
      path.STATS_PART,
      path.SCORES_PART,
      path.TEAMS_PART,
      path.HOME_HEADLINES,
      path.PLAYERS_PART,
      path.RANKINGS_PART,
      path.LEADERBOARD,
    ]);
  }, [location.pathname, isDesktopPlus]);

  const isShowProfile = useMemo(() => {
    if (isDesktopPlus || postId) {
      return false;
    }

    return stringIncludesOneOf(location.pathname, [
      path.FEED_PART,
      path.STANDINGS_PART,
      path.PLAYERS_PART,
      path.STATS_PART,
      path.SCORES_PART,
      path.TEAMS_PART,
      path.HOME_HEADLINES,
      path.RANKINGS_PART,
      path.USERS_PART,
    ]);
  }, [location.pathname, isDesktopPlus, postId]);

  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
  const { isNativeApp } = useMainProvider();
  const { shareByNativeModal } = useNativeShare();

  const handleOpenArena = useCallback(() => {
    if (gameId) {
      const arenaGamePath = getPath(path.ARENA_GAME, {
        [path.GAME_ID_PARAM]: gameId,
      });
      navigate({
        pathname: arenaGamePath,
        search: `${createSearchParams({ tab: GameDetailsTabs.PLAYS })}`,
      });
    }
  }, [gameId, navigate]);

  const handleSharePlayerPerformance = useCallback(async () => {
    if (gameId && playerSlug) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerPerformanceShare,
          player: playerSlug,
        },
      });

      await shareStore.fetchSharePlayerPerformance(gameId, playerSlug);

      if (isNativeApp && shareStore.shareData) {
        shareByNativeModal(
          shareStore.shareData.link,
          metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [isNativeApp, playerSlug, gameId, metaTagsStore.metaTags, shareStore, shareByNativeModal]);

  const handleSharePlayersRankings = useCallback(async () => {
    await shareStore.fetchSharePlayersRankings();

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.RankingsShare,
      },
    });

    if (isNativeApp && shareStore.shareData) {
      shareByNativeModal(shareStore.shareData.link, 'Rankings');
    } else {
      shareStore.setIsShareModalVisible(true);
    }
  }, [isNativeApp, shareStore, shareByNativeModal]);

  const handleSharePlayersRankingsDetails = useCallback(async () => {
    if (category) {
      await shareStore.fetchSharePlayersRankingsDetails(category);
      const categoryTitle = RANKING_CATEGORIES.find((item) => item.key === category)?.label || '';

      if (isNativeApp && shareStore.shareData) {
        shareByNativeModal(shareStore.shareData.link, categoryTitle);
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [category, isNativeApp, shareStore, shareByNativeModal]);

  const { unFollowPlayerMutation } = useUnFollowPlayerMutation();
  const { unFollowTeamMutation } = useUnFollowTeamMutation();

  const handleContextItemClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'leave') {
        if (teamsStore.teamId) {
          unFollowTeamMutation(teamsStore.teamId);
        }
      }
    },
    [teamsStore.teamId, unFollowTeamMutation],
  );

  const handleContextPlayerProfileClick = useCallback(
    async (barAction: BarActionType) => {
      if (
        barAction.type === BarAction.Click &&
        barAction.payload === 'leave' &&
        playerStore.playerDetails?.id
      ) {
        unFollowPlayerMutation(playerStore.playerDetails.id);
      }
    },
    [playerStore.playerDetails, unFollowPlayerMutation],
  );

  const isPerformancePage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [path.PERFORMANCE_PART]);
  }, [location.pathname]);

  const isPlayersRankingPage = useMemo(() => {
    if (stringIncludesOneOf(location.pathname, [path.DETAILS_PART])) {
      return false;
    }

    return stringIncludesOneOf(location.pathname, [path.RANKINGS_PART]);
  }, [location.pathname]);

  const isPlayersRankingDetailsPage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [`/${path.DETAILS_PART}/`]);
  }, [location.pathname]);

  const isTeamPage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [`/${path.TEAMS_PART}/`]);
  }, [location.pathname]);

  const isPlayerProfilePage = useMemo(() => {
    return stringIncludesOneOf(location.pathname, [`/${path.PLAYERS_PART}/`]);
  }, [location.pathname]);

  const isPlayerContextMenuVisible = useMemo(() => {
    return authStore.userMe?.athlete?.playerSlug !== playerSlug;
  }, [authStore.userMe, playerSlug]);

  const handleProfileCLick = useCallback(() => {
    layoutStore.setMobileSidebarOpen(!layoutStore.isMobileSidebarOpen);
    layoutStore.setSidePanelActiveTab(null);
  }, [layoutStore]);

  const pointsColorStyles = useMemo(() => {
    if (currentTeam) {
      return {
        background: currentTeam.secondaryColor,
        color: currentTeam.secondaryTextColor,
      };
    }

    if (playerStore.playerDetails?.team) {
      return {
        background: playerStore.playerDetails.team.secondaryColor,
        color: playerStore.playerDetails.team.secondaryTextColor,
      };
    }

    return {};
  }, [currentTeam, playerStore.playerDetails]);

  const isFollowedTeam = useMemo(() => {
    return teamsStore.teamId ? getIsFollowTeam(teamsStore.teamId) : false;
  }, [teamsStore.teamId, getIsFollowTeam]);

  const renderProfile = useCallback(() => {
    if (!isShowProfile) {
      return null;
    }

    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={handleProfileCLick}
        onClick={handleProfileCLick}
        className={styles.AvatarWrapper}
      >
        {authStore.isAuthorised ? (
          <Avatar
            size={AvatarSize.S}
            src={authStore.userMe?.profile?.avatarUrl}
            username={authStore?.userMe?.username || ''}
          />
        ) : (
          <IconFont name={IconFontName.Profile} size={IconFontSize.Big} />
        )}
        {authStore.isAuthorised && !authStore.userMe?.athlete && (
          <div style={pointsColorStyles} className={styles.AvatarWrapper__Points}>
            <IconButton
              customColor={pointsColorStyles?.color}
              iconSize={IconFontSize.ExtraSmall}
              iconName={IconFontName.StarFilled}
              theme={IconButtonTheme.Secondary}
            />
            {currentUserBalance?.totalPoints ? (
              <span className={styles.Rating}>{currentUserBalance.totalPoints}</span>
            ) : null}
          </div>
        )}
      </div>
    );
  }, [
    isShowProfile,
    handleProfileCLick,
    authStore.isAuthorised,
    authStore.userMe,
    pointsColorStyles,
    currentUserBalance,
  ]);

  useLayoutEntity({
    type: LayoutEntity.HeaderProfile,
    value: renderProfile,
  });

  useLayoutEntity({
    type: LayoutEntity.HeaderRight,
    value: () => (
      <div className={styles.HeaderRight}>
        {isShowSearchButton && (
          <IconButton
            iconName={IconFontName.Search2}
            onClick={handleSearchClick}
            theme={IconButtonTheme.Transparent}
            customColor={currentTeam ? currentTeam?.secondaryTextColor : DEFAULT_GREY_ICON_COLOR}
          />
        )}
        {isPerformancePage && (
          <>
            <IconButton
              iconName={IconFontName.Arena}
              onClick={handleOpenArena}
              theme={IconButtonTheme.Transparent}
            />
            <IconButton
              iconName={IconFontName.Share}
              onClick={handleSharePlayerPerformance}
              theme={IconButtonTheme.Transparent}
            />
          </>
        )}
        {isPlayersRankingPage && (
          <IconButton
            iconName={IconFontName.Share}
            onClick={handleSharePlayersRankings}
            theme={IconButtonTheme.Transparent}
          />
        )}
        {isPlayersRankingDetailsPage && (
          <IconButton
            iconName={IconFontName.Share}
            onClick={handleSharePlayersRankingsDetails}
            theme={IconButtonTheme.Transparent}
          />
        )}
        {isTeamPage && isFollowedTeam && (
          <ContextMenuTooltip
            groups={TEAM_HEADER_INFO_GROUPS}
            onItemClick={handleContextItemClick}
            toggleOnClick
            tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
          >
            <IconButton
              iconName={IconFontName.More}
              theme={IconButtonTheme.Secondary}
              customColor={currentTeam?.secondaryTextColor}
            />
          </ContextMenuTooltip>
        )}
        {isPlayerProfilePage &&
          playerStore.playerDetails?.id &&
          getIsFollowPlayer(playerStore.playerDetails.id) &&
          isPlayerContextMenuVisible && (
            <ContextMenuTooltip
              groups={PLAYER_PROFILE_HEADER_INFO_GROUPS}
              onItemClick={handleContextPlayerProfileClick}
              toggleOnClick
              tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
            >
              <IconButton
                iconName={IconFontName.More}
                theme={IconButtonTheme.Secondary}
                customColor={DEFAULT_GREY_ICON_COLOR}
              />
            </ContextMenuTooltip>
          )}
      </div>
    ),
  });

  return null;
});
