import { getTime, parseISO } from 'date-fns';

import { IFollow } from 'services/follow/interfaces/follow.interface';
import { IFollowItemResponse } from 'services/follow/interfaces/followings-response.interface';

import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { formatShortName } from 'helpers/format/format-short-name.util';

export function followBuilder(
  followings: IFollowItemResponse[],
  teams: ITeamsStats[],
  players: IPlayerStats[],
): IFollow[] {
  const initialTeamIds: string[] = [];
  const initialPlayerIds: string[] = [];
  const preparedPlayers: IFollow[] = [];
  const allFollows: IFollow[] = [];

  followings.forEach((item) => {
    if (item.type === 'team') {
      initialTeamIds.push(`${item.team_id}team`);
    }

    if (item.type === 'player') {
      initialPlayerIds.push(`${item.player_id}player`);
    }
  });

  teams.forEach((team) => {
    allFollows.push(followTeamAdapter(team, followings, initialTeamIds));
  });

  players.forEach((player) => {
    preparedPlayers.push(followPlayerAdapter(player, followings, initialPlayerIds));
  });

  preparedPlayers.sort((a, b) => {
    const lastNameA = a.lastName.toLowerCase();
    const lastNameB = b.lastName.toLowerCase();

    if (lastNameA < lastNameB) {
      return -1;
    }
    if (lastNameA > lastNameB) {
      return 1;
    }

    const firstNameA = a.firstName.toLowerCase();
    const firstNameB = b.firstName.toLowerCase();

    if (firstNameA < firstNameB) {
      return -1;
    }
    if (firstNameA > firstNameB) {
      return 1;
    }

    return 0;
  });

  allFollows.push(...preparedPlayers);

  return allFollows;
}

function getFollowDateInMs(followings: IFollowItemResponse[], entityId: Maybe<number>) {
  const followItem = followings.find(
    (follow) => follow.player_id === entityId || follow.team_id === entityId,
  );

  if (followItem) {
    return getTime(parseISO(followItem.date_created));
  }

  return 0;
}

function followTeamAdapter(
  team: ITeamsStats,
  followings: IFollowItemResponse[],
  followId: string[],
): IFollow {
  return {
    id: `${team.id}team`,
    thumbnailUrl: team.smallLogoUrl,
    isFollow: followId.includes(`${team.id}team`),
    type: 'team',
    label: team.nickname,
    city: team.city || '',
    firstName: '',
    lastName: '',
    slug: '',
    followDate: getFollowDateInMs(followings, team.id),
  };
}

function followPlayerAdapter(
  player: IPlayerStats,
  followings: IFollowItemResponse[],
  followId: string[],
): IFollow {
  return {
    id: `${player.id}player`,
    thumbnailUrl: player.smallLogoUrl,
    isFollow: followId.includes(`${player.id}player`),
    type: 'player',
    label: formatShortName(player.firstname, player.lastname),
    city: '',
    firstName: player.firstname,
    lastName: player.lastname,
    slug: player.slug,
    followDate: getFollowDateInMs(followings, player.id),
  };
}
