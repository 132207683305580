import { z } from 'zod';

import {
  MAX_POLL_ANSWERS_LENGTH,
  MAX_POLL_TITLE_LENGTH,
  MIN_ATTACHMENTS_LENGTH,
  MIN_POLL_ANSWERS_LENGTH,
  MIN_POLL_TITLE_LENGTH,
} from 'validation/validation.constants';

const attachedImagesSchema = z.array(
  z.object({
    url: z.string(),
    uuid: z.string(),
    hash: z.string(),
    mimeType: z.string(),
    filename: z.string(),
  }),
);

const pollOptionSchema = z.object({
  title: z
    .string()
    .min(MIN_POLL_TITLE_LENGTH, `Min answer length is ${MIN_POLL_TITLE_LENGTH}`)
    .max(MAX_POLL_TITLE_LENGTH, `Max answer length is ${MAX_POLL_TITLE_LENGTH}`),
  order: z.number(),
  id: z.string(),
});

const attachedPollSchema = z.object({
  type: z.string(),
  options: pollOptionSchema
    .array()
    .min(MIN_POLL_ANSWERS_LENGTH, `Min answers amount is ${MIN_POLL_ANSWERS_LENGTH}`)
    .max(MAX_POLL_ANSWERS_LENGTH, `Max answers amount is ${MAX_POLL_ANSWERS_LENGTH}`),
});

const attachedVideoSchema = z.string().array().length(1, { message: 'Cannot be empty' });

const attachedGifSchema = z.string().array().min(1, `Cannot be empty`);

const editorAttachmentsWithoutPollsSchema = z.union([
  // no need for attachments
  z.object({
    images: attachedImagesSchema.optional(),
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema.optional(),
  }),
  // need one video
  z.object({
    images: attachedImagesSchema.optional(),
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema,
  }),
  // need min 1 image
  z.object({
    images: attachedImagesSchema.nonempty(`Min attachments length is ${MIN_ATTACHMENTS_LENGTH}`),
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema.optional(),
  }),
  // need min 1 GIF
  z.object({
    images: attachedImagesSchema.optional(),
    gifs: attachedGifSchema,
    videos: attachedVideoSchema.optional(),
  }),
]);

const editorAttachmentsSchema = z.union([
  // no need for attachments
  z.object({
    images: attachedImagesSchema.optional(),
    poll: attachedPollSchema.optional(),
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema.optional(),
  }),
  // need one video
  z.object({
    images: attachedImagesSchema.optional(),
    poll: attachedPollSchema.optional(),
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema,
  }),
  // need one poll
  z.object({
    images: attachedImagesSchema.optional(),
    poll: attachedPollSchema,
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema.optional(),
  }),
  // need min 1 image
  z.object({
    images: attachedImagesSchema.nonempty(`Min attachments length is ${MIN_ATTACHMENTS_LENGTH}`),
    poll: attachedPollSchema.optional(),
    gifs: attachedGifSchema.optional(),
    videos: attachedVideoSchema.optional(),
  }),
  // need min 1 GIF
  z.object({
    images: attachedImagesSchema.optional(),
    poll: attachedPollSchema.optional(),
    gifs: attachedGifSchema,
    videos: attachedVideoSchema.optional(),
  }),
]);

const groupPostContentSchema = z.object({
  value: z.string(),
  length: z.number().gt(0, 'Message is required'),
});

export const groupPostEditorSchema = z.object({
  content: groupPostContentSchema,
  attachments: editorAttachmentsSchema.nullable().optional(),
});

const userGeneratedPostContentSchema = z.object({
  value: z.string(),
  length: z.number(),
});

export const userGeneratedPostEditorSchema = z
  .object({
    content: userGeneratedPostContentSchema.optional(),
    attachments: editorAttachmentsSchema.nullable().optional(),
  })
  .superRefine((data, ctx) => {
    // Validate that either `content` exists or one valid `attachments` an object exists
    if (
      !data.content?.length &&
      !data.attachments?.poll &&
      !data.attachments?.gifs &&
      !data.attachments?.videos &&
      !data.attachments?.images
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either content or attachments must be provided.',
        path: ['content'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either content or attachments must be provided.',
        path: ['attachments'],
      });
    }
  });

const contentSchema = z.object({
  value: z.string(),
  length: z.number(),
});

export const videoPostEditorSchema = z.object({
  content: contentSchema.optional(),
  attachments:
    // need one video
    z.object({
      videos: attachedVideoSchema,
    }),
});

export const editorCommentSchema = z
  .object({
    content: contentSchema.optional(),
    attachments: editorAttachmentsWithoutPollsSchema.nullable().optional(),
  })
  .superRefine((data, ctx) => {
    // Validate that either `content` exists or one valid `attachments` an object exists
    if (
      !data.content?.length &&
      !data.attachments?.gifs &&
      !data.attachments?.videos &&
      !data.attachments?.images
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either content or attachments must be provided.',
        path: ['content'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Either content or attachments must be provided.',
        path: ['attachments'],
      });
    }
  });

export const collaborationItemSchema = z.object({
  content: groupPostContentSchema,
});
