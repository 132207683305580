import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { IPostCommentBookmarkPayload } from 'services/comments/interfaces/comments.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';
import { IReportReason } from 'services/reports/interfaces/report-reason.interface';

import { COMMENTS_PAGINATION_LIMIT } from 'stores/comments/comments.store';
import { IComment } from 'stores/comments/interfaces/comments.interface';
import { PlayerFontColor } from 'stores/teams-stats/enums/player-font-color.enum';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';
import { userReportHandler } from 'helpers/user-report-handler.util';

import { useResponsive } from 'hooks/use-responsive';
import { useScrollTo } from 'hooks/use-scroll-to';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { TextCommentEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/text-comment-editor-bottom-sheet.component';
import { CommentContextTooltip } from 'components/comment/comment-context-tooltip/comment-context-tooltip.component';
import { CommentToolTip } from 'components/comment/comment-tooltip/comment-tooltip.component';
import {
  CommentCreateDataType,
  TextCommentCreateForm,
} from 'components/forms/text-comment-create/text-comment-create-form.component';
import {
  IFormattedDates,
  IPostCardUserInfo,
} from 'components/posts/post-card/interfaces/post-info.interface';
import { ReportsPopup } from 'components/reports/reports-popup/reports-popup.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTheme,
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';
import { Tooltip, TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import { AuthTooltip } from '../auth/auth-tooltip/auth-tooltip.component';
import { ISharePublicationData } from '../posts/post-detailed/post-detailed.component';

import styles from './comment.module.less';

const DISABLED_COMMENT_LEVEL = 3;
const HIGHLIGHT_TIMEOUT = 2000;

export interface ICommentData {
  comment: string;
  createAt: string;
}

let timer: NodeJS.Timeout;

interface ICommentProps {
  id: string;
  userId: Maybe<string>;
  postId: string;
  playerId: Maybe<number>;
  page: number;
  commentAuthor: IPostCardUserInfo;
  commentData: ICommentData;
  reportReasons?: Maybe<IReportReason[]>;
  likes: number;
  commentsAmount: number;
  isSubComment?: boolean;
  isReplyDisabled?: boolean;
  isParentHovered?: boolean;
  scrollCommentId: Maybe<string>;
  comments: Maybe<IComment[]>;
  activeCommentEditorId?: string;
  commentNestedLevel?: number;
  isBookmarked: boolean;
  isLiked: boolean;
  isVideoLoading: boolean;
  isCurrentUserStaff: boolean;
  processing: boolean;
  postVideo: Maybe<IVideoResponse>;
  replyVideo: Maybe<IVideoResponse>;
  formattedDates: IFormattedDates;
  team: Maybe<ITeamsStats>;
  onReplySubmit?: (commentId?: string) => ({ editor }: CommentCreateDataType) => void;
  onReplyClick?: (commentId: string) => void;
  onGetReplies: (commentId: string, page: number) => void;
  onBookmarkClick: (payload: IPostCommentBookmarkPayload) => void;
  onDeleteClick: (postId: string, comment: string) => void;
  onShareClick: () => void;
  onShareSubComment: (comment?: ISharePublicationData) => void;
  onResetScrollCommentId: () => void;
  favoriteTeamId: Maybe<number>;
  favoritePlayerId: Maybe<number>;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  triggerAuthorisationCheck: () => boolean;
  onCloseReportsPopup: (commentId: string) => void;
  requestReasons: () => void;
  onSendReport?: (
    postId: string,
    commentId: string,
    reasonId: number,
    teamId?: Maybe<number>,
    playerId?: Maybe<number>,
  ) => Promise<boolean>;
  onPostCommentLike: (postId: string, comment: string) => void;
  getPlayerById: (playerId: Maybe<number>) => Maybe<IPlayerStats>;
  getTeamById: (teamId: Maybe<number>) => Maybe<ITeamsStats>;
}

export const Comment = (props: ICommentProps) => {
  const {
    likes,
    isLiked,
    isCurrentUserStaff,
    isBookmarked,
    isSubComment = false,
    isReplyDisabled = false,
    isParentHovered = false,
    isVideoLoading,
    id,
    userId,
    postId,
    playerId,
    scrollCommentId,
    commentsAmount,
    page,
    processing,
    commentAuthor,
    commentData,
    reportReasons,
    comments,
    activeCommentEditorId,
    commentNestedLevel = 1,
    postVideo,
    replyVideo,
    formattedDates,
    team,
    onReplySubmit,
    onGetReplies,
    onResetScrollCommentId,
    onBookmarkClick,
    onDeleteClick,
    onShareClick,
    onShareSubComment,
    onReplyClick,
    favoritePlayerId,
    favoriteTeamId,
    onSendReport,
    onCloseReportsPopup,
    onPostCommentLike,
    getPlayerById,
    getTeamById,
  } = props;

  const commentIdWithSlug = `#comment-${id}`;

  const favoriteTeam = getTeamById(favoriteTeamId);
  const favoritePlayer = getPlayerById(favoritePlayerId);

  const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isNeedActivateEditor, setIsNeedActivateEditor] = useState(false);
  const [isReplyEditorShown, setIsReplyEditorShown] = useState(false);

  const [scrollToRef, setShouldScrollTo] = useScrollTo<HTMLDivElement>();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const [isLikeEnabled, setIsLikeEnabled] = useState(true);

  const { hash } = useLocation();

  const commentClasses = useMemo(
    () =>
      cn(styles.Comment, TOUCH_IGNORE_CLASS, {
        [styles['Comment--sub-comment']]: isSubComment,
        [styles['Comment--hover']]: isHovered && isDesktopPlus && !isHighlighted,
        [styles['Comment--sub-comment-with-highlighted']]: isSubComment && isHighlighted,
      }),
    [isSubComment, isHovered, isHighlighted, isDesktopPlus],
  );

  const commentBodyClassNames = useMemo(
    () =>
      cn(styles.Comment__Body, {
        [styles['Comment__Body--highlighted']]: isHighlighted,
      }),
    [isHighlighted],
  );

  const richTextContentTheme = useMemo(() => {
    if (isHighlighted) {
      return RichTextContentTheme.Highlighted;
    }

    if ((isHovered || isParentHovered) && isDesktopPlus && isSubComment) {
      return RichTextContentTheme.Grey;
    }

    if (isSubComment && !isDesktopPlus) {
      return RichTextContentTheme.Black;
    }

    if (isSubComment && isDesktopPlus) {
      return RichTextContentTheme.DarkGrey;
    }

    return RichTextContentTheme.LightGrey;
  }, [isHighlighted, isSubComment, isHovered, isDesktopPlus, isParentHovered]);

  const overridePlayer: Maybe<IPlayerStats> = useMemo(() => {
    if (favoritePlayer) {
      return {
        ...favoritePlayer,
        fontColor: PlayerFontColor.Light,
      };
    }

    return null;
  }, [favoritePlayer]);

  const isHasMoreReplies = useMemo(() => {
    return commentsAmount / COMMENTS_PAGINATION_LIMIT > page - 1;
  }, [commentsAmount, page]);

  const isAnyRepliesAvailable = useMemo(() => {
    if (!commentsAmount || !comments) {
      return false;
    }

    return commentsAmount > Number(comments.length);
  }, [commentsAmount, comments]);

  const isCurrentUserComment = useMemo(() => {
    return commentAuthor.uuid === userId;
  }, [commentAuthor, userId]);

  const handleMouseOver = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setIsHovered(true);
  }, []);

  const handleMouseEnter = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    setIsHovered(false);
  }, []);

  const handlePostCommentLike = useCallback(async () => {
    setIsLikeEnabled(false);
    await onPostCommentLike?.(postId, id);
    setIsLikeEnabled(true);
  }, [onPostCommentLike, postId, id]);

  const handleReplyClick = useCallback(() => {
    if (isDesktopPlus && onReplyClick) {
      onReplyClick(id);
      setIsNeedActivateEditor(true);

      return;
    }

    if (!isDesktopPlus && userId) {
      setIsReplyEditorShown(true);
    }
  }, [id, isDesktopPlus, onReplyClick, setIsNeedActivateEditor, userId]);

  const handleBookmarkClick = useCallback(() => {
    onBookmarkClick({
      postId,
      commentId: id,
    });
  }, [id, postId, onBookmarkClick]);

  const handleSubCommentShareClick = useCallback(
    (comment: ISharePublicationData) => () => {
      onShareSubComment(comment);
    },
    [onShareSubComment],
  );

  const handleGetRepliesClick = useCallback(() => {
    onGetReplies(id, page);
  }, [onGetReplies, id, page]);

  const handleDeleteClick = useCallback(() => {
    onDeleteClick(postId, id);
  }, [onDeleteClick, id, postId]);

  const handleContextMenuClick = useCallback(
    async (barAction: BarActionType) => {
      if (barAction.type === BarAction.Click && barAction.payload === 'delete') {
        handleDeleteClick();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'bookmark') {
        handleBookmarkClick();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'report') {
        setIsReportPopupOpen(true);
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'user-report') {
        userReportHandler();
      }

      if (barAction.type === BarAction.Click && barAction.payload === 'share') {
        if (isSubComment) {
          onShareSubComment({
            uuid: id,
            formattedDates,
            user: commentAuthor,
          });
        } else {
          onShareClick();
        }
      }
    },
    [
      id,
      isSubComment,
      formattedDates,
      commentAuthor,
      onShareClick,
      onShareSubComment,
      handleDeleteClick,
      handleBookmarkClick,
    ],
  );

  const handleReportsPopupOpen = useCallback(() => {
    setIsReportPopupOpen(true);
  }, []);

  const handleCloseEditorBottomSheet = useCallback(() => {
    setIsReplyEditorShown(false);
  }, []);

  const handleReportsPopupClose = useCallback(() => {
    setIsReportPopupOpen(false);
    onCloseReportsPopup(id);
  }, [id, onCloseReportsPopup]);

  const handleReportSubmit = useCallback(
    async (reasonId: number) => {
      const isSuccessResponse = await onSendReport?.(postId, id, reasonId, team?.id, playerId);

      return !!isSuccessResponse;
    },
    [postId, id, playerId, team, onSendReport],
  );

  const handleReplySubmit = useCallback(
    (commentId?: string) =>
      async ({ editor }: CommentCreateDataType) => {
        await onReplySubmit?.(commentId)({ editor });
        setIsReplyEditorShown(false);
      },
    [onReplySubmit],
  );

  useEffect(() => {
    if (scrollCommentId !== id && hash !== commentIdWithSlug) {
      return;
    }

    setShouldScrollTo(true);
    setIsHighlighted(true);

    if (scrollCommentId === id) {
      onResetScrollCommentId();
    }

    timer = setTimeout(() => {
      setIsHighlighted(false);
    }, HIGHLIGHT_TIMEOUT);
  }, [scrollCommentId, id, hash, commentIdWithSlug, setShouldScrollTo, onResetScrollCommentId]);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      ref={scrollToRef}
      className={commentClasses}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseEnter}
    >
      <div className={styles.Comment__UserAvatar}>
        <Avatar
          size={isSubComment ? AvatarSize.XS : AvatarSize.M}
          username={commentAuthor.username}
          src={commentAuthor.smallAvatarUrl}
        />
      </div>
      <div className={styles.Comment__Main}>
        <div className={commentBodyClassNames}>
          <div className={styles.Comment__UserInfo}>
            <div className={styles.LeftSide}>
              <div className={styles.TopSection}>
                <div className={styles.TopSection__UserNick}>@{commentAuthor.username}</div>
                {!!commentAuthor.name && isDesktopPlus && (
                  <div className={styles.TopSection__UserName}>{commentAuthor.name}</div>
                )}
              </div>
              <TeamPlayerLabel
                isNeedLowOpacity
                team={favoriteTeam}
                player={overridePlayer}
                onTeamClick={props.onTeamClick}
                onPlayerClick={props.onPlayerClick}
              />
            </div>
            {isHovered && isDesktopPlus && (
              <CommentToolTip
                isAllowedCommentDelete={isCurrentUserStaff || isCurrentUserComment}
                isBookmarked={isBookmarked}
                onBookmarkClick={handleBookmarkClick}
                onShareClick={props.onShareClick}
                onDeleteClick={handleDeleteClick}
                onReportsPopupOpen={handleReportsPopupOpen}
                isCurrentUserComment={isCurrentUserComment}
              />
            )}
            {!isDesktopPlus && (
              <Tooltip
                closeOnContentClick
                toggleOnClick
                tooltipContent={
                  <CommentContextTooltip
                    onItemClick={handleContextMenuClick}
                    isBookmarked={isBookmarked}
                    isCurrentUserComment={isCurrentUserComment}
                  />
                }
                eventType={TooltipEventType.click}
                placement="bottom-end"
              >
                <IconButton
                  iconSize={IconFontSize.Big}
                  iconName={IconFontName.More}
                  theme={IconButtonTheme.Transparent}
                />
              </Tooltip>
            )}
          </div>
          <div className={styles.Comment__Content}>
            {commentData.comment && (
              <RichTextPreview
                id={id}
                theme={richTextContentTheme}
                editorState={commentData.comment}
                editorType={RichTextContentTypes.WithReadMore}
              />
            )}
          </div>
        </div>
        <div className={styles.Comment__Footer}>
          <AuthTooltip>
            <IconButton
              iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
              theme={IconButtonTheme.Transparent}
              iconSize={IconFontSize.Small}
              onClick={handlePostCommentLike}
              active={isLiked}
              disabled={!isLikeEnabled}
            />
          </AuthTooltip>
          {!!likes && (
            <span className={styles.PostCardActionsBar__Number}>{formatBigNumbers(likes)}</span>
          )}
          {!isReplyDisabled && commentNestedLevel !== DISABLED_COMMENT_LEVEL && (
            <AuthTooltip>
              <div className={styles.Comment__ReplyBtn}>
                <Button
                  onClick={handleReplyClick}
                  size={ButtonSize.Small}
                  theme={ButtonTheme.Text}
                  name="commentReply"
                >
                  Reply
                </Button>
              </div>
            </AuthTooltip>
          )}
          <div className={styles.Comment__Time}>{commentData.createAt}</div>
        </div>
        {activeCommentEditorId === id && isDesktopPlus && (
          <TextCommentCreateForm
            onSubmit={handleReplySubmit(id)}
            isFixedPosition={false}
            isNeedActivateEditor={isNeedActivateEditor}
            processing={processing}
            withLoader={false}
            placeholder="Add comment"
          />
        )}
        {comments?.length ? (
          <div className={styles.Comment__SubComments}>
            {comments.map(
              ({
                isLiked: isLikedSubComment,
                isBookmarked: isBookmarkedSubComment,
                uuid: subUuid,
                user: subUser,
                likes: subLikes,
                formattedDates: subCommentFormattedDates,
                content: subContent,
                comments: subComments,
                page: subCommentPage,
                commentsAmount: subCommentsAmount,
              }) => (
                <Comment
                  isCurrentUserStaff={isCurrentUserStaff}
                  requestReasons={props.requestReasons}
                  reportReasons={reportReasons}
                  team={team}
                  userId={userId}
                  page={subCommentPage}
                  commentsAmount={subCommentsAmount}
                  formattedDates={subCommentFormattedDates}
                  processing={processing}
                  isLiked={isLikedSubComment}
                  replyVideo={replyVideo}
                  isVideoLoading={isVideoLoading}
                  postVideo={postVideo}
                  scrollCommentId={scrollCommentId}
                  key={subUuid}
                  id={subUuid}
                  getPlayerById={props.getPlayerById}
                  getTeamById={props.getTeamById}
                  onPostCommentLike={props.onPostCommentLike}
                  postId={postId}
                  playerId={playerId}
                  isBookmarked={isBookmarkedSubComment}
                  isParentHovered={isHovered}
                  isSubComment
                  commentAuthor={{
                    uuid: subUser.uuid,
                    name: subUser.name,
                    username: subUser.username,
                    smallAvatarUrl: subUser.smallAvatarUrl,
                  }}
                  commentData={{
                    comment: subContent,
                    createAt: isDesktopPlus
                      ? subCommentFormattedDates.relativeLong
                      : subCommentFormattedDates.relativeShort,
                  }}
                  comments={subComments}
                  activeCommentEditorId={activeCommentEditorId}
                  commentNestedLevel={commentNestedLevel + 1}
                  onReplyClick={props.onReplyClick}
                  onReplySubmit={handleReplySubmit}
                  onBookmarkClick={props.onBookmarkClick}
                  onShareClick={handleSubCommentShareClick({
                    uuid: subUuid,
                    user: subUser,
                    formattedDates: subCommentFormattedDates,
                  })}
                  onShareSubComment={props.onShareSubComment}
                  onGetReplies={props.onGetReplies}
                  favoritePlayerId={subUser.favoritePlayerId}
                  favoriteTeamId={subUser.favoriteTeamId}
                  onTeamClick={props.onTeamClick}
                  onPlayerClick={props.onPlayerClick}
                  onSendReport={props.onSendReport}
                  onCloseReportsPopup={props.onCloseReportsPopup}
                  triggerAuthorisationCheck={props.triggerAuthorisationCheck}
                  onDeleteClick={props.onDeleteClick}
                  onResetScrollCommentId={props.onResetScrollCommentId}
                  likes={subLikes}
                />
              ),
            )}
            {isHasMoreReplies && (
              <div className={styles.Comment__ShowMore}>
                <Button
                  fluid
                  onClick={handleGetRepliesClick}
                  size={ButtonSize.SmallSecondary}
                  theme={ButtonTheme.Tertiary}
                  name="showMoreComment"
                >
                  Show more replies
                </Button>
              </div>
            )}
          </div>
        ) : (
          isAnyRepliesAvailable && (
            <div className={styles.Comment__ShowMore}>
              <Button
                onClick={handleGetRepliesClick}
                size={ButtonSize.SmallSecondary}
                theme={ButtonTheme.Tertiary}
                name="showReplies"
              >
                Show replies
              </Button>
            </div>
          )
        )}
        {!isCurrentUserComment && isReportPopupOpen && (
          <ReportsPopup
            requestReasons={props.requestReasons}
            reasons={reportReasons}
            visible={isReportPopupOpen}
            onClose={handleReportsPopupClose}
            onSendReport={handleReportSubmit}
          />
        )}
        {!isDesktopPlus && (
          <TextCommentEditorBottomSheet
            visible={isReplyEditorShown}
            processing={processing}
            onSubmit={handleReplySubmit(id)}
            onCloseBottomSheet={handleCloseEditorBottomSheet}
          />
        )}
      </div>
    </div>
  );
};
