import { useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { EditorType } from 'components/editor/components/base-editor/base-editor.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import './emoji-picker-override.css';
import styles from './toolbar.module.less';

interface ISubmitToolbarPluginProps {
  isSendDisabled: boolean;
  isSendHidden: boolean;
  editorType?: EditorType;
}

export const SubmitToolbarPlugin = (props: ISubmitToolbarPluginProps) => {
  const { isSendHidden, isSendDisabled, editorType } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const toolbarClasses = useMemo(
    () =>
      cn(styles.Toolbar, styles['Toolbar--stick-right'], {
        [styles['Toolbar--submit']]: isDesktopPlus,
      }),
    [isDesktopPlus],
  );

  if (isSendHidden) {
    return null;
  }

  return (
    <div className={toolbarClasses}>
      <AuthTooltip>
        {editorType === EditorType.Collaboration ? (
          <Button
            type="submit"
            size={ButtonSize.Big}
            theme={ButtonTheme.Primary}
            disabled={isSendDisabled}
            iconName={IconFontName.SendFilled}
          >
            Publish
          </Button>
        ) : (
          <Button
            disabled={isSendDisabled}
            size={ButtonSize.Small}
            theme={ButtonTheme.Primary}
            type="submit"
            iconName={isSendDisabled ? IconFontName.Send : IconFontName.SendFilled}
          />
        )}
      </AuthTooltip>
    </div>
  );
};
