import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useFollowData } from 'query-hooks/followings/use-follow-data';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';
import { useGetAllTeamsQuery } from 'query-hooks/teams/queries/use-get-all-teams.query';
import { AuthStore } from 'src/stores/auth/auth.store';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { TYPES } from 'configs/di-types.config';
import { preparePlayersSelectOptions } from 'helpers/prepare-players-select-options.utils';
import { prepareTeamsSelectOptions } from 'helpers/prepare-teams-select-options.utils';

import { ProfileTeamAndPlayerSelectForm } from 'components/forms/profile-team-and-player-select-form/profile-team-and-player-select-form.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';
import { IInitialTeamAndPlayer } from 'components/user-details/reputation-box-item/interfaces/initial-team-and-player.interface';

import styles from './select-team-and-player.module.less';

export const SelectTeamAndPlayerContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const publicUserData = userPublicStore.userPublic;

  const { data: players = [] } = useGetAllPlayersQuery();
  const { data: teams = [] } = useGetAllTeamsQuery();

  const teamsThumbnails = useMemo(() => {
    return teams.map((team) => team.mediumThumbnailUrl);
  }, [teams]);

  const myUserData = authStore.userMe;
  const isAthlete = Boolean(publicUserData?.athlete);

  const isMyPage = useMemo(() => {
    return myUserData?.username === publicUserData?.username;
  }, [myUserData?.username, publicUserData?.username]);

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams) => {
      if (authStore.fetching) {
        return;
      }

      if (
        updateUserParams.favoriteTeamId &&
        myUserData?.profile.thumbnailUrl &&
        !teamsThumbnails.includes(myUserData.profile.thumbnailUrl)
      ) {
        authStore.setShouldApplyFavoriteTeamBackground(false);

        if (!layoutStore.isTeamBackgroundUpdateModalOpen) {
          if (updateUserParams.favoriteTeamId === myUserData?.profile?.favoriteTeamId) {
            layoutStore.setIsTeamBackgroundUpdateModalOpen(false);
          } else {
            layoutStore.setIsTeamBackgroundUpdateModalOpen(true);
          }
        }
      }

      await authStore.updateUserMe(updateUserParams);
      await userPublicStore.retrieveUser();
    },
    [authStore, myUserData?.profile, teamsThumbnails, userPublicStore, layoutStore],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.UserProfile,
      },
    });
  }, []);

  const { getPlayerById, getTeamById } = useFollowData();

  const favoriteTeam = getTeamById(publicUserData?.favoriteTeamId || null);

  const favoritePlayer = getPlayerById(publicUserData?.favoritePlayerId || null);

  const handleMockCallback = useCallback(() => {}, []);

  const initialTeamAndPlayer = useMemo(() => {
    const teamAndPlayer: IInitialTeamAndPlayer = {
      teamId: null,
      playerId: null,
    };

    if (favoriteTeam) {
      teamAndPlayer.teamId = favoriteTeam.id;
    }
    if (favoritePlayer) {
      teamAndPlayer.playerId = favoritePlayer.id;
    }

    return teamAndPlayer;
  }, [favoriteTeam, favoritePlayer]);

  const memoizedInitialPlayerColor = useMemo(() => {
    if (favoritePlayer) {
      return favoritePlayer.color;
    }

    return null;
  }, [favoritePlayer]);

  const teamsSelectOptions: ITeamOption[] = useMemo(() => {
    return prepareTeamsSelectOptions(teams, 'Team', 'Team');
  }, [teams]);

  const playersSelectOptions: IPlayerOption[] = useMemo(() => {
    return preparePlayersSelectOptions(players);
  }, [players]);

  const handleSubmitTeamAndPlayer = useCallback(
    (data: IInitialTeamAndPlayer) => {
      const payload = {
        favoriteTeamId: data.teamId,
        favoritePlayerId: data.playerId,
      };
      handleUpdateUser(payload);
    },
    [handleUpdateUser],
  );

  if (isAthlete) {
    return null;
  }

  return isMyPage && !!teamsSelectOptions.length && !!playersSelectOptions.length ? (
    <ProfileTeamAndPlayerSelectForm
      teamsOptions={teamsSelectOptions}
      playersOptions={playersSelectOptions}
      initialValues={initialTeamAndPlayer}
      initialFavPlayerColor={memoizedInitialPlayerColor}
      setDisabledScroll={layoutStore.setDisabledScroll}
      onSubmit={handleSubmitTeamAndPlayer}
    />
  ) : (
    <div className={styles.TeamWrapper}>
      <TeamPlayerLabel
        team={favoriteTeam}
        player={favoritePlayer}
        onTeamClick={handleMockCallback}
        onPlayerClick={handleMockCallback}
      />
    </div>
  );
});
