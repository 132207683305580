import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { ConvertVideoItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
  ICollaborationItem,
  ICollaborationItemPayload,
} from 'services/collaboration/interfaces/collaboration.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import { trimEditorContent } from 'helpers/trim-editor-content.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { TextCommentEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/text-comment-editor-bottom-sheet.component';
import { CollaborationAction } from 'components/collaboration-section/collaboration-action/collaboration-action';
import { CommentCreateDataType } from 'components/forms/text-comment-create/text-comment-create-form.component';
import { CreateTextCommentModal } from 'components/posts/create-text-comment-modal/create-text-comment-modal.component';
import { Avatar, AvatarSize, AvatarTheme } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { ConvertPostImageItemToAttachmentType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './collaboration-section.module.less';

interface ICollaborationSectionProps {
  postId: string;
  isPostDetails: boolean;
  selectedItemId: Maybe<number>;
  media: Maybe<CollaborationMediaEnum>;
  textAllowed: boolean;
  itemsTotal: number;
  items: ICollaborationItem[];
  isLoading: boolean;
  isAuthorised: boolean;
  collaborationId: number;
  convertImageItemToAttachment?: ConvertPostImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  onCreateCollaborationItem?: (payload: ICollaborationItemPayload) => void;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
}

export const CollaborationSection: FC<ICollaborationSectionProps> = (props) => {
  const {
    postId,
    collaborationId,
    isPostDetails,
    items,
    media,
    textAllowed,
    selectedItemId,
    itemsTotal,
    onCreateCollaborationItem,
    convertImageItemToAttachment,
    isLoading,
    isAuthorised,
  } = props;

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const [isCommentEditorShown, setIsCommentEditorShown] = useState(false);
  const [isCreateCommentSuccess, setIsCreateCommentSuccess] = useState(false);
  const [isNeedActivateEditor, setIsNeedActivateEditor] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleCloseEditorBottomSheet = useCallback(() => {
    setIsCommentEditorShown(false);
  }, []);

  const resetEditor = useCallback(() => {
    setIsCreateCommentSuccess(true);
    setIsCommentEditorShown(false);
  }, []);

  const handleSubmitComment = useCallback(
    () =>
      async ({ editor }: CommentCreateDataType) => {
        if (onCreateCollaborationItem && editor.content?.length && editor.content.length > 0) {
          const editorState = JSON.parse(editor?.content?.value);
          let content = editorState?.root?.children;

          const newEditorState = {
            ...editorState,
            root: {
              ...editorState.root,
              children: trimEditorContent(content),
            },
          };

          content = JSON.stringify(newEditorState);
          const payload: ICollaborationItemPayload = {
            text_content: content,
            id: collaborationId,
          };

          onCreateCollaborationItem(payload);

          resetEditor();
        }
      },
    [collaborationId, onCreateCollaborationItem, resetEditor],
  );

  const handleConvertImageItemToAttachment = useCallback(
    async (file: File) => {
      if (convertImageItemToAttachment) {
        setIsImageLoading(true);
        const result = await convertImageItemToAttachment(file, postId);

        setIsImageLoading(false);

        return result;
      }

      return null;
    },
    [convertImageItemToAttachment, postId],
  );

  const handleNavigateToPostDetails = useCallback(() => {
    if (postId) {
      navigate(
        getPath(paths.HOME_FEED_POST, {
          [paths.POST_ID_PARAM]: postId,
        }),
      );
    }
  }, [navigate, postId]);

  const handleNavigateToCollaborationItemPage = useCallback(
    (collaborationItemId: number) => {
      if (postId) {
        navigate(
          getPath(paths.HOME_FEED_POST_COLLABORATION, {
            [paths.POST_ID_PARAM]: postId,
            [paths.COLLABORATION_ITEM_ID_PARAM]: collaborationItemId.toString(),
          }),
        );
      }
    },
    [navigate, postId],
  );

  const handleOpenCommentEditor = useCallback(() => {
    if (!isAuthorised) {
      return;
    }

    setIsCommentEditorShown(true);
  }, [isAuthorised, setIsCommentEditorShown]);

  return (
    <div className={styles.CollaborationSection}>
      {!isPostDetails && !!itemsTotal && (
        <div className={styles.CollaborationSection__Header}>
          <span className={styles.TotalItems}>
            {itemsTotal} {textAllowed ? 'response' : 'medias'} added
          </span>
          <Button
            className={styles.CollaborationSection__SeeAll}
            size={ButtonSize.Small}
            theme={ButtonTheme.Text}
            onClick={handleNavigateToPostDetails}
          >
            See All
          </Button>
        </div>
      )}
      {!isPostDetails && media && !textAllowed && (
        <div className={styles.CollaborationSection__MediaItems}>
          {items.map(({ id, image, video }) => {
            const url = image?.smallLogoUrl || video?.smallPreviewUrl || '';

            if (url) {
              return (
                <button
                  onClick={() => handleNavigateToCollaborationItemPage(id)}
                  key={id}
                  className={cn(styles.CollaborationItem, {
                    [styles['CollaborationItem--selected']]: selectedItemId === id,
                  })}
                >
                  {id === selectedItemId && (
                    <div className={styles.LabelSelected}>
                      <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
                    </div>
                  )}
                  <img src={url} alt={`${id}-preview`} />
                  {video?.smallPreviewUrl && (
                    <div className={styles.VideoLabel}>
                      <IconFont name={IconFontName.Play} size={IconFontSize.ExtraSmall} />
                    </div>
                  )}
                </button>
              );
            }

            return null;
          })}
        </div>
      )}
      {!isPostDetails && media && textAllowed && (
        <div className={styles.CollaborationSection__MediaAndTextItems}>
          {items.map(({ id, image, video, textContent }, index) => {
            const url = image?.smallLogoUrl || video?.smallPreviewUrl || '';

            if (url && index < 3) {
              return (
                <button
                  onClick={() => handleNavigateToCollaborationItemPage(id)}
                  key={id}
                  className={cn(styles.CollaborationMediaAndTextItem, {
                    [styles['CollaborationMediaAndTextItem--selected']]: selectedItemId === id,
                  })}
                >
                  <div
                    className={cn(styles.CollaborationMediaAndTextItem__Image, {
                      [styles['CollaborationMediaAndTextItem__Image--selected']]:
                        selectedItemId === id,
                    })}
                  >
                    <img src={url} alt={`${id}-preview`} />
                    {video?.smallPreviewUrl && (
                      <div className={styles.VideoLabel}>
                        <IconFont name={IconFontName.Play} size={IconFontSize.ExtraSmall} />
                      </div>
                    )}
                  </div>
                  {id === selectedItemId && (
                    <div className={styles.LabelSelected}>
                      <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
                    </div>
                  )}
                  <div className={styles.ResponseText}>
                    {textContent && (
                      <RichTextPreview
                        id={id.toString()}
                        editorType={RichTextContentTypes.SingleLine}
                        editorState={textContent}
                      />
                    )}
                  </div>
                </button>
              );
            }

            return null;
          })}
        </div>
      )}
      {!isPostDetails && !media && textAllowed && (
        <div className={styles.CollaborationSection__TextItems}>
          {items.map(({ id, textContent, user }) => {
            return (
              <button
                onClick={() => handleNavigateToCollaborationItemPage(id)}
                key={id}
                className={cn(styles.CollaborationTextItem, {
                  [styles['CollaborationTextItem--selected']]: selectedItemId === id,
                })}
              >
                {id === selectedItemId && (
                  <div className={styles.LabelSelected}>
                    <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
                  </div>
                )}
                <div className={styles.CollaborationTextItem__Image}>
                  <Avatar
                    theme={AvatarTheme.User}
                    size={AvatarSize.XS}
                    username={user.username}
                    src={user.smallAvatarUrl}
                  />
                </div>
                <div className={cn(styles.ResponseText)}>
                  {textContent && (
                    <RichTextPreview
                      id={id.toString()}
                      editorType={RichTextContentTypes.SingleLine}
                      editorState={textContent}
                    />
                  )}
                </div>
              </button>
            );
          })}
        </div>
      )}
      <CollaborationAction
        textAllowed={textAllowed}
        selectedItemId={selectedItemId}
        media={media}
        isLoading={isLoading}
        isAuthorised={isAuthorised}
        collaborationMediaItem={props.collaborationMediaItem}
        onConvertImageItemToAttachment={handleConvertImageItemToAttachment}
        onCreateCollaborationItem={props.onCreateCollaborationItem}
        convertVideoItemToAttachment={props.convertVideoItemToAttachment}
        onOpenCollaborationTextResponseModal={handleOpenCommentEditor}
        setCollaborationMediaItem={props.setCollaborationMediaItem}
        collaborationId={collaborationId}
        postId={postId}
        isCollaborationImageLoading={isImageLoading}
        isCollaborationVideoLoading={props.isCollaborationVideoLoading}
      />
      {!isDesktopPlus && isCommentEditorShown && (
        <TextCommentEditorBottomSheet
          title="Add message"
          placeholder="Add Message"
          visible={isCommentEditorShown}
          processing={isLoading}
          onCloseBottomSheet={handleCloseEditorBottomSheet}
          onSubmit={handleSubmitComment()}
        />
      )}
      {isDesktopPlus && isCommentEditorShown && (
        <div className={styles.CommentCreate}>
          <CreateTextCommentModal
            placeholder="Add Message"
            isCreateCommentSuccess={isCreateCommentSuccess}
            isNeedActivateEditor={isNeedActivateEditor}
            processing={isLoading}
            setIsCreateCommentSuccess={setIsCreateCommentSuccess}
            setIsNeedActivateEditor={setIsNeedActivateEditor}
            onSubmit={handleSubmitComment()}
            onClose={handleCloseEditorBottomSheet}
            isVisible={isCommentEditorShown}
          />
        </div>
      )}
    </div>
  );
};
