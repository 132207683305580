import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { dayStreakAdapter } from 'stores/reputations-points/adapters/days-streak-adapter.util';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

const retrieveDaysStreak = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof reputationQueryKeys)['daysStreak']>>) => {
  const reputationPointsService = container.get<ReputationPointsService>(
    TYPES.ReputationPointsService,
  );

  const [, , { username, seasonId }] = queryKey;

  if (!username) {
    return null;
  }

  const response = await reputationPointsService.fetchDaysStreak(username, seasonId);

  if (response.success) {
    return response.data ? dayStreakAdapter(response.data) : null;
  }

  return null;
};

export const useDaysStreakQuery = (
  username: Maybe<string> = null,
  seasonId: Maybe<string> = null,
) => {
  return useQuery({
    queryKey: reputationQueryKeys.daysStreak(username, seasonId),
    queryFn: retrieveDaysStreak,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
  });
};
