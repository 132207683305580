import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { useMainProvider } from 'hooks/use-main-provider';

import { groupPostEditorSchema } from 'validation/schemas/editor.schema';
import { groupPostResponseSchema } from 'validation/schemas/group-post-response.schema';

import {
  EditorDataType,
  EditorType,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { IForm } from 'components/forms/form.interface';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { GroupPostSettings } from 'components/ui/form-fields/group-post-settings/group-post-settings.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './create-group-post-form.module.less';

const createGroupPostFormSchema = z.object({
  editor: groupPostEditorSchema,
  responseType: groupPostResponseSchema,
});

export type CreateGroupPostFormDataType = z.infer<typeof createGroupPostFormSchema>;

export type GroupPostResponseTypeDataType = z.infer<typeof groupPostResponseSchema>;

interface ICreateGroupPostFormProps extends IForm<CreateGroupPostFormDataType> {
  isPostVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onFormStateChange: (flag: boolean) => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const CreateGroupPostForm = (props: ICreateGroupPostFormProps) => {
  const {
    processing,
    initialValues,
    submitText,
    postVideo,
    isPostVideoLoading,
    onSubmit,
    onFormStateChange,
  } = props;

  const editorWrapperRef = useRef<HTMLDivElement>(null);

  const [loadingImages, setLoadingImages] = useState<string[]>([]);
  const [isEditorActive, setIsEditorActive] = useState<boolean>(true);
  const { isNativeApp } = useMainProvider();

  const { control, formState, handleSubmit } = useForm<CreateGroupPostFormDataType>({
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: zodResolver(createGroupPostFormSchema),
  });

  const { isValid, errors, isDirty } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleEditorBlur = useCallback(() => {
    setIsEditorActive(false);
  }, []);

  const handleEditorFocus = useCallback(() => {
    let timeoutInstance: NodeJS.Timeout;
    setIsEditorActive(true);

    if (isNativeApp) {
      timeoutInstance = setTimeout(() => {
        editorWrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }

    return () => {
      if (isNativeApp) {
        clearTimeout(timeoutInstance);
      }
    };
  }, [isNativeApp]);

  useEffect(() => {
    onFormStateChange(isDirty);
  }, [isDirty, onFormStateChange]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.CreateGroupPostForm}>
        <div ref={editorWrapperRef} className={styles.PostCreateForm__Editor}>
          <Controller
            name="editor"
            control={control}
            render={({ field }) => (
              <Editor
                error={errors.editor?.content?.length?.message}
                editorType={EditorType.GroupPost}
                convertImageItemToAttachment={props.convertImageItemToAttachment}
                id="editor"
                name="editor"
                isSendHidden
                isEnabledAutoScrolling
                isActive={isEditorActive}
                loadingImages={loadingImages}
                onChange={handleEditorChange(field.onChange)}
                value={field.value}
                postVideo={postVideo}
                onUploadVideo={props.onUploadVideo}
                onEditorFocus={handleEditorFocus}
                onRemoveVideo={props.onRemoveVideo}
                setLoadingImages={setLoadingImages}
                isVideoLoading={isPostVideoLoading}
                onVideoError={props.onVideoError}
                onBlur={handleEditorBlur}
              />
            )}
          />
        </div>
        <div className={styles.CreateGroupPostForm__Settings}>
          <div className={styles.CreateGroupPostForm__EditorLabel}>Response type</div>
          <Controller
            name="responseType"
            control={control}
            render={({ field }) => (
              <GroupPostSettings
                value={field.value}
                /* eslint-disable-next-line react/jsx-handler-names */
                onChange={field.onChange}
                id="responseType"
                name="responseType"
                error={errors.responseType?.textAllowed?.message}
              />
            )}
          />
        </div>
        <div className={styles.CreateGroupPostForm__Footer}>
          <div className={styles.CreateGroupPostForm__CreateBtn}>
            <Button
              type="submit"
              size={ButtonSize.Big}
              theme={ButtonTheme.Primary}
              disabled={!isValid || processing}
              iconName={IconFontName.SendFilled}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
