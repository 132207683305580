import { memo, useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import styles from './placeholder.module.less';

interface IPlaceholderProps {
  text: string;
}

export const Placeholder = memo((props: IPlaceholderProps) => {
  const { text } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const placeholderClasses = useMemo(
    () =>
      cn(styles.Placeholder, {
        [styles['Placeholder--bottom-sheet-theme']]: !isDesktopPlus,
      }),
    [isDesktopPlus],
  );

  return <div className={placeholderClasses}>{text}</div>;
});
