import { inject, injectable } from 'inversify';

import { HttpService } from 'services/http/http.service';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';

import { TYPES } from 'configs/di-types.config';

import { CORE_FEEDS } from '../http/consts/api-endpoints.constants';

import { ITeamFeedResponse } from './interfaces/team-response.interface';

@injectable()
export class TeamsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchTeamFeeds() {
    const params = {
      limit: '100',
      page: '1',
      type: PostsFeedType.Team,
    };

    return this.httpService.request<IResponseItems<ITeamFeedResponse[]>>({
      method: 'GET',
      url: CORE_FEEDS,
      params: new URLSearchParams(params),
    });
  }
}
