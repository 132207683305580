import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

export const useAthleteRedirect = (isAthlete: boolean, playerSlug?: string) => {
  const redirectComplete = useRef<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!redirectComplete.current && isAthlete && playerSlug) {
      navigate(
        getPath(paths.PLAYER_PROFILE_FAN_ZONE, {
          [paths.PLAYER_SLUG_PARAM]: playerSlug,
        }),
      );
    }
  }, [isAthlete, navigate, playerSlug]);

  return null;
};
