import {
  COLLABORATION_ID_API_PARAM,
  COLLABORATION_ITEM_COMMENT_ID_API_PARAM,
  COLLABORATION_ITEM_ID_API_PARAM,
  COMMENT_ID_API_PARAM,
  GAME_CHAT_ITEM_ID_PARAM,
  GAME_ID_API_PARAM,
  GAME_ID_PARAM,
  LINK_ID_API_PARAM,
  PLAYER_ID_API_PARAM,
  PLAYER_SLUG_API_PARAM,
  POST_ID_API_PARAM,
  RANKING_CATEGORY_ID_API_PARAM,
  SEARCH_QUERY_ID_API_PARAM,
  TEAM_ID_API_PARAM,
  USER_SLUG_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';

import {
  API_GATEWAY_URL,
  AUTH_HOST,
  CORE_HOST,
  REPUTATION_HOST,
  STATISTICS_HOST,
} from 'configs/environment.config';
import {
  MESSAGE_ID_PARAM,
  POLL_ID_PARAM,
  POLL_OPTION_ID_PARAM,
  POST_COMMENT_ID_PARAM,
  POST_ID_PARAM,
  USERS_PART,
} from 'routes/paths.constants';

const AUTH_PREFIX = 'auth';

const CORE_PREFIX = 'core';

const STATISTICS_PREFIX = 'statistics';

const REPUTATION_PREFIX = 'reputation';

const AUTH_URL = AUTH_HOST || `${API_GATEWAY_URL}/${AUTH_PREFIX}`;
const CORE_URL = CORE_HOST || `${API_GATEWAY_URL}/${CORE_PREFIX}`;
const STATISTICS_URL = STATISTICS_HOST || `${API_GATEWAY_URL}/${STATISTICS_PREFIX}`;
const REPUTATION_URL = REPUTATION_HOST || `${API_GATEWAY_URL}/${REPUTATION_PREFIX}`;

// <editor-fold desc="Auth endpoints">

export const AUTH_SIGN_IN = `${AUTH_URL}/sign-in`;
export const AUTH_SIGN_IN_GOOGLE = `${AUTH_URL}/sign-in/social/google`;
export const AUTH_SIGN_IN_APPLE = `${AUTH_URL}/sign-in/social/apple`;
export const AUTH_SIGN_UP = `${AUTH_URL}/sign-up`;
export const AUTH_SIGN_UP_ANONYMOUS = `${AUTH_URL}/sign-up/anonymous`;

export const AUTH_SIGN_UP_VERIFICATION_REQUEST = `${AUTH_URL}/sign-up/verification/request`;
export const AUTH_SIGN_UP_VERIFICATION_CONFIRM = `${AUTH_URL}/sign-up/verification/confirm`;

export const AUTH_VERIFICATION_REVOKE = `${AUTH_URL}/verification/revoke`;

export const AUTH_USERS_ME = `${AUTH_URL}/users/me`;
export const AUTH_ANONYMOUS_USERS_ME = `${AUTH_URL}/anonymous-users/me`;

const ATHLETES = `athletes`;

export const AUTH_REFRESH = `${AUTH_URL}/refresh`;

export const AUTH_FORGOT_PASSWORD_VERIFICATION_REQUEST = `${AUTH_URL}/forgot/verification/request`;
export const AUTH_RESET_PASSWORD_VERIFICATION_CONFIRM = `${AUTH_URL}/forgot/verification/confirm`;
export const AUTH_RESET_PASSWORD = `${AUTH_URL}/reset`;

// </editor-fold>

export const FOLLOW_PLAYER = `${CORE_URL}/players/:${PLAYER_ID_API_PARAM}/follow`;
export const FOLLOW_TEAM = `${CORE_URL}/teams/:${TEAM_ID_API_PARAM}/follow`;

export const RECENT_SEARCH = `${CORE_URL}/search-queries`;
export const DELETE_RECENT_SEARCH_ITEM = `${CORE_URL}/search-queries/:${SEARCH_QUERY_ID_API_PARAM}`;
export const GLOBAL_SEARCH = `${CORE_URL}/search`;
export const FOLLOWINGS = `${CORE_URL}/followings`;
export const GAME_MESSAGES = `${CORE_URL}/games/:${GAME_ID_API_PARAM}/chat`;
export const GAME_NEW_MESSAGE = `${CORE_URL}/games/:${GAME_ID_API_PARAM}/chat/comment`;
export const GAME_CHAT_ITEM_LIKES = `${CORE_URL}/games/:${GAME_ID_API_PARAM}/chat/:${MESSAGE_ID_PARAM}/like`;

export const GAME_CHAT_ITEM_DELETE = `${CORE_URL}/games/:${GAME_ID_API_PARAM}/chat/comments/:${MESSAGE_ID_PARAM}`;

// <editor-fold desc="statistics">

export const GAME_STATISTICS = `${STATISTICS_URL}/games/:${GAME_ID_API_PARAM}/statistics`;

export const NEAREST_DATE = `${STATISTICS_URL}/games/nearest-date`;

export const STATISTICS_SEASONS = `${STATISTICS_URL}/seasons`;

export const PLAYERS_STATS = `${STATISTICS_URL}/players`;
export const PLAYERS_STATS_ALL = `${STATISTICS_URL}/players/statistics`;

export const TEAMS_STATS = `${STATISTICS_URL}/teams`;
export const TEAMS_STATS_BY_TEAM_ID = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}`;
export const TEAMS_STATS_ALL_BY_TEAM_ID = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}/statistics`;
export const TEAMS_STATS_ALL = `${STATISTICS_URL}/teams/statistics`;
export const TEAMS_STATS_LEADERS = `${STATISTICS_URL}/teams/leaders`;
export const TEAMS_STATS_GAMES_BY_TEAM_ID = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}/games-detailed/all`;
export const TEAMS_STATS_ROSTER_BY_TEAM_ID = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}/roster`;
export const TEAMS_BOX_SCORES = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}/games/recent/boxscores`;
export const TEAM_GAME_STATISTICS_MINI = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}/games-mini`;
export const TEAM_GAME_STATISTICS_DETAILED = `${STATISTICS_URL}/teams/:${TEAM_ID_API_PARAM}/games-detailed`;

export const PLAYERS_LEADERS = `${STATISTICS_URL}/players/leaders`;
export const PLAYER_PERFORMANCE = `${STATISTICS_URL}/games/:${GAME_ID_API_PARAM}/players/:${PLAYER_SLUG_API_PARAM}/statistics`;
export const PLAYER_DETAILS = `${STATISTICS_URL}/players/:${PLAYER_SLUG_API_PARAM}`;
export const PLAYER_RANKED_STATS = `${STATISTICS_URL}/players/:${PLAYER_SLUG_API_PARAM}/statistics`;
export const PLAYER_CAREER = `${STATISTICS_URL}/players/:${PLAYER_SLUG_API_PARAM}/career`;
export const PLAYER_SPLITS = `${STATISTICS_URL}/players/:${PLAYER_SLUG_API_PARAM}/splits`;
export const PLAYER_SPLITS_OPPONENT = `${STATISTICS_URL}/players/:${PLAYER_SLUG_API_PARAM}/splits/opponent`;
export const PLAYER_SPLITS_IN_GAME = `${STATISTICS_URL}/players/:${PLAYER_SLUG_API_PARAM}/splits/in-game`;
export const PLAYERS_RANKINGS_ALL = `${STATISTICS_URL}/players/rankings`;
export const SHARE_PLAYERS_RANKINGS = `${CORE_URL}/players/rankings/share`;
export const SHARE_PLAYERS_RANKINGS_DETAILS = `${CORE_URL}/players/rankings/:${RANKING_CATEGORY_ID_API_PARAM}/share`;
export const PLAYERS_RANKINGS_BY_CATEGORY = `${STATISTICS_URL}/players/rankings/all`;

export const STATISTICS_STANDINGS = `${STATISTICS_URL}/standings`;

export const GAMES_STATISTICS_MINI = `${STATISTICS_URL}/games-mini`;
export const GAMES_STATISTICS_DETAILED = `${STATISTICS_URL}/games-detailed`;
export const GAMES_FOR_ARENA = `${STATISTICS_URL}/games/:${GAME_ID_API_PARAM}/arena`;

export const PUSH_NOTIFICATION_SETTINGS = `${CORE_URL}/notification-settings`;

// </editor-fold>

export const REPORT_REASONS = `${CORE_URL}/report-reasons`;
export const REPORT_POST = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/report`;
export const REPORT_POST_COMMENT = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/comments/:${COMMENT_ID_API_PARAM}/report`;
export const REPORT_GAME_CHAT_ITEM = `${CORE_URL}/games/:${GAME_ID_PARAM}/chat/:${GAME_CHAT_ITEM_ID_PARAM}/report`;

// <editor-fold desc="teams">

export const CORE_FEEDS = `${CORE_URL}/feeds`;

export const POSTS_TOP = `${CORE_URL}/posts`;
export const GAME_VIDEO_POSTS = `${CORE_URL}/games/:${GAME_ID_PARAM}/posts`;
export const POST_FAVORITES = `${CORE_URL}/posts/favorites`;
export const VIDEOS_UPLOAD = `${CORE_URL}/videos/upload`;
export const IMAGES_UPLOAD = `${CORE_URL}/images/upload`;

export const CONTENT_CARDS = `${CORE_URL}/content-cards`;
export const FAVORITES_CONTENT_CARDS = `${CORE_URL}/content-cards/favorites`;

export const SINGLE_COMMENT = `${CORE_URL}/posts/:${POST_ID_PARAM}/comments/:${POST_COMMENT_ID_PARAM}`;
export const SINGLE_POST = `${CORE_URL}/posts/:${POST_ID_PARAM}`;
export const POST_COMMENTS = `${CORE_URL}/posts/:${POST_ID_PARAM}/comments`;
export const POST_COMMENT_REPLIES = `${CORE_URL}/posts/:${POST_ID_PARAM}/comments/:${POST_COMMENT_ID_PARAM}/replies`;
export const POST_POLL_VOTE = `${CORE_URL}/polls/:${POLL_ID_PARAM}/options/:${POLL_OPTION_ID_PARAM}/vote`;
export const POST_POLL_UPDATE = `${CORE_URL}/polls/:${POLL_ID_PARAM}`;
export const TEAM_POSTS = `${CORE_URL}/teams/:${TEAM_ID_API_PARAM}/posts`;
// </editor-fold>

// headlines
export const HEADLINES_FEED = `${CORE_URL}/headlines`;

// players
export const PLAYER_FEED = `${CORE_URL}/players/:${PLAYER_ID_API_PARAM}/posts`;
export const FEED_LIST = `${CORE_URL}/feeds`;
export const PUBLIC_USER_POSTS = `${CORE_URL}/posts/interactions/:${USER_SLUG_API_PARAM}`;

// bookmarks
export const ADD_POST_TO_BOOKMARK = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/bookmark`;
export const DELETE_POST = `${CORE_URL}/posts/:${POST_ID_API_PARAM}`;
export const GET_ALL_BOOKMARKS = `${CORE_URL}/bookmarks/search`;
export const GET_ALL_BOOKMARKED_USERS = `${CORE_URL}/bookmarks/owners`;
export const ADD_POST_COMMENT_TO_BOOKMARK = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/comments/:${COMMENT_ID_API_PARAM}/bookmark`;

// share
export const SHARE_POST = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/share`;
export const SHARE_POST_COMMENT = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/comments/:${COMMENT_ID_API_PARAM}/share`;
export const SHARE_GAME = `${CORE_URL}/games/:${GAME_ID_API_PARAM}/share`;
export const SHARE_PLAYER_PERFORMANCE = `${CORE_URL}/games/:${GAME_ID_API_PARAM}/players/:${PLAYER_SLUG_API_PARAM}/statistics/share`;

// votes
export const POST_LIKE = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/like`;
export const POST_COMMENT_LIKE = `${CORE_URL}/posts/:${POST_ID_API_PARAM}/comments/:${COMMENT_ID_API_PARAM}/like`;

// news
export const POSTS_NEWS_IMAGE_PREVIEW = `${CORE_URL}/posts/news/:${POST_ID_API_PARAM}/preview`;

// links
export const LINKS_FAVICON = `${CORE_URL}/links/:${LINK_ID_API_PARAM}/favicon`;
export const LINKS_PREVIEW = `${CORE_URL}/links/:${LINK_ID_API_PARAM}/preview`;

// policies
export const TERMS_OF_USE = `${CORE_URL}/terms-of-use-versions/published`;
export const PRIVACY_POLICY = `${CORE_URL}/privacy-policy-versions/published`;
export const USER_AGREEMENT = `${CORE_URL}/user-agreement-versions/published`;

// meta-tags
export const META_TAGS = `${CORE_URL}/meta-tags`;

// contact-us
export const CONTACT_US = `${AUTH_URL}/requests`;

// athlete
export const ALL_ATHLETES = `${AUTH_URL}/${ATHLETES}`;
export const CUSTOM_LINKS = `${AUTH_URL}/${USERS_PART}/me/links`;
export const SOCIAL_LINKS = `${AUTH_URL}/${USERS_PART}/me/links/social`;
export const UPDATE_CUSTOM_LINKS = `${AUTH_URL}/${USERS_PART}/me/links/:${LINK_ID_API_PARAM}`;
export const ATHLETE = `${AUTH_URL}/${ATHLETES}/:${PLAYER_SLUG_API_PARAM}`;

// collaborations
export const COLLABORATIONS = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items`;
export const COLLABORATION_ITEM = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}`;
export const COLLABORATION_LIKE = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}/like`;
export const COLLABORATION_SHARE = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}/share`;
export const COLLABORATION_BOOKMARK = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}/bookmark`;
export const COLLABORATION_COMMENTS = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}/comments`;
export const COLLABORATION_ITEM_COMMENT = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}/comments/:${COLLABORATION_ITEM_COMMENT_ID_API_PARAM}`;
export const COLLABORATION_ITEM_COMMENT_LIKE = `${CORE_URL}/collaborations/:${COLLABORATION_ID_API_PARAM}/items/:${COLLABORATION_ITEM_ID_API_PARAM}/comments/:${COLLABORATION_ITEM_COMMENT_ID_API_PARAM}/like`;
export const COLLABORATION_IMAGE_UPLOAD = `${CORE_URL}/collaborations/items/images`;
export const COLLABORATION_VIDEO_UPLOAD = `${CORE_URL}/collaborations/items/videos`;

// reputation
export const REPUTATION_SEASONS = `${REPUTATION_URL}/seasons`;
export const REPUTATION_BUCKETS = `${REPUTATION_URL}/buckets`;
export const REPUTATION_TRANSACTIONS = `${REPUTATION_URL}/transactions`;
export const REPUTATION_USERS_BALANCES = `${REPUTATION_URL}/users/balances`;
export const REPUTATION_USER_BALANCE = `${REPUTATION_URL}/users/:${USER_SLUG_API_PARAM}/balance`;
export const REPUTATION_USER_BALANCE_DETAILED = `${REPUTATION_URL}/users/:${USER_SLUG_API_PARAM}/balance-detailed`;
export const REPUTATION_DAYS_STREAK = `${REPUTATION_URL}/users/:${USER_SLUG_API_PARAM}/days-streak`;
export const USER_ACHIEVEMENTS = '';

// users public
export const USER_PUBLIC_DETAILS = `${AUTH_URL}/users/:${USER_SLUG_API_PARAM}`;
