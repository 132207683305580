import { FC, useCallback, useMemo } from 'react';
import { ColDef } from 'ag-grid-community';
import cn from 'classnames';

import { IGameTeamInfo } from 'stores/game/interfaces/game.interface';
import {
  IBoxScoresPlayerStatistics,
  IBoxScoresTotalStats,
} from 'stores/teams-stats/interfaces/players-stats.interface';

import {
  BOX_SCORES_TABLE_FIRST_BENCH_COLUMN,
  BOX_SCORES_TABLE_FIRST_STARTERS_COLUMN,
  BOX_SCORES_TABLE_MAX_COL_DEF,
  BOX_SCORES_TOTAL_STATS_TABLE_MAX_COL_DEF,
  PLAYERS_STATS_TABLE_ROW_HEIGHT,
  TOTAL_STATS_TABLE_ROW_HEIGHT,
} from 'components/game/box-score/box-scores.config';
import { TeamRow } from 'components/game/box-score/team-row/team-row.component';
import { TeamSection } from 'components/game/box-score/team-section/team-section.component';

import styles from './box-score.module.less';

export interface IBoxScoreProps {
  homeTeamInfo: IGameTeamInfo;
  visitorsTeamInfo: IGameTeamInfo;
  homeTeamTotalStats: Maybe<IBoxScoresTotalStats[]>;
  visitorsTeamTotalStats: Maybe<IBoxScoresTotalStats[]>;
  homeTeamStartersStats: Maybe<IBoxScoresPlayerStatistics[]>;
  visitorsTeamStartersStats: Maybe<IBoxScoresPlayerStatistics[]>;
  homeTeamBenchStats: Maybe<IBoxScoresPlayerStatistics[]>;
  visitorsTeamBenchStats: Maybe<IBoxScoresPlayerStatistics[]>;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
}

const visitorsAnchor = 'box-scores-visitors-team';
const homeAnchor = 'box-scores-home-team';

export const BoxScore: FC<IBoxScoreProps> = (props) => {
  const {
    homeTeamInfo,
    visitorsTeamInfo,
    homeTeamTotalStats,
    visitorsTeamTotalStats,
    homeTeamStartersStats,
    visitorsTeamStartersStats,
    homeTeamBenchStats,
    visitorsTeamBenchStats,
    onPlayerClick,
  } = props;

  const getColDef = useCallback((colDef: ColDef[], isForBench: boolean): ColDef[] => {
    let newConfig: ColDef[];

    if (isForBench) {
      newConfig = [{ ...BOX_SCORES_TABLE_FIRST_BENCH_COLUMN }, ...colDef];
    } else {
      newConfig = [{ ...BOX_SCORES_TABLE_FIRST_STARTERS_COLUMN }, ...colDef];
    }

    return newConfig;
  }, []);

  const visitorsStartersConfig = useMemo(() => {
    return {
      rowHeight: PLAYERS_STATS_TABLE_ROW_HEIGHT,
      rowData: visitorsTeamStartersStats,
      columnDefs: getColDef(BOX_SCORES_TABLE_MAX_COL_DEF, false),
      gridId: '1',
    };
  }, [visitorsTeamStartersStats, getColDef]);

  const visitorsBenchConfig = useMemo(() => {
    return {
      rowHeight: PLAYERS_STATS_TABLE_ROW_HEIGHT,
      rowData: visitorsTeamBenchStats,
      columnDefs: getColDef(BOX_SCORES_TABLE_MAX_COL_DEF, true),
      gridId: '2',
    };
  }, [visitorsTeamBenchStats, getColDef]);

  const visitorsTotalStatsConfig = useMemo(() => {
    return {
      rowData: visitorsTeamTotalStats,
      rowHeight: TOTAL_STATS_TABLE_ROW_HEIGHT,
      columnDefs: BOX_SCORES_TOTAL_STATS_TABLE_MAX_COL_DEF,
      gridId: '3',
    };
  }, [visitorsTeamTotalStats]);

  const homeStartersConfig = useMemo(() => {
    return {
      rowHeight: PLAYERS_STATS_TABLE_ROW_HEIGHT,
      rowData: homeTeamStartersStats,
      columnDefs: getColDef(BOX_SCORES_TABLE_MAX_COL_DEF, false),
      gridId: '4',
    };
  }, [homeTeamStartersStats, getColDef]);

  const homeBenchConfig = useMemo(() => {
    return {
      rowHeight: PLAYERS_STATS_TABLE_ROW_HEIGHT,
      rowData: homeTeamBenchStats,
      columnDefs: getColDef(BOX_SCORES_TABLE_MAX_COL_DEF, true),
      gridId: '5',
    };
  }, [homeTeamBenchStats, getColDef]);

  const homeTotalStatsConfig = useMemo(() => {
    return {
      rowData: homeTeamTotalStats,
      rowHeight: TOTAL_STATS_TABLE_ROW_HEIGHT,
      columnDefs: BOX_SCORES_TOTAL_STATS_TABLE_MAX_COL_DEF,
      gridId: '6',
    };
  }, [homeTeamTotalStats]);

  const handleRowClicked = useCallback(
    (event: { data: IBoxScoresPlayerStatistics }) => {
      if (event.data.player.slug) {
        onPlayerClick(event.data.player.slug);
      }
    },
    [onPlayerClick],
  );

  return (
    <div className={styles.BoxScore}>
      <div className={cn(styles.BoxScore__Team, visitorsAnchor)}>
        <TeamRow team={visitorsTeamInfo} isHome={false} onTeamClick={props.onTeamClick} />
        <TeamSection
          startersConfig={visitorsStartersConfig}
          benchConfig={visitorsBenchConfig}
          totalStatsConfig={visitorsTotalStatsConfig}
          anchorElement={visitorsAnchor}
          onRowClick={handleRowClicked}
        />
      </div>
      <div className={cn(styles.BoxScore__Team, homeAnchor)}>
        <TeamRow team={homeTeamInfo} isHome onTeamClick={props.onTeamClick} />
        <TeamSection
          startersConfig={homeStartersConfig}
          benchConfig={homeBenchConfig}
          totalStatsConfig={homeTotalStatsConfig}
          anchorElement={homeAnchor}
          onRowClick={handleRowClicked}
        />
      </div>
    </div>
  );
};
