import { FC, memo, PropsWithChildren, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Tooltip, TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './following-item.module.less';

interface IFollowingItemProps {
  isSelected: boolean;
  id: string;
  imageSrc: string;
  name: string;
  isSelfFollow: boolean;
  onClick: (id: string) => void;
}

export const FollowingItem: FC<IFollowingItemProps> = memo(
  (props: PropsWithChildren<IFollowingItemProps>) => {
    const { onClick, isSelected, imageSrc, name, id, isSelfFollow } = props;

    const classNames = useMemo(
      () =>
        cn(styles.Item, {
          [styles['Item--is-selected']]: isSelected,
        }),
      [isSelected],
    );

    const handleJoinButtonClick = useCallback(() => {
      if (isSelfFollow) {
        return;
      }

      onClick(id);
    }, [onClick, id, isSelfFollow]);

    return (
      <div className={classNames}>
        {isSelected && (
          <button onClick={handleJoinButtonClick}>
            {!!imageSrc && <img src={imageSrc} alt={name || ''} />}
          </button>
        )}
        {isSelected && (
          <Tooltip
            tooltipContent={
              <div className={styles.Item__Tooltip}>
                <p>You can’t unfollow yourself</p>
              </div>
            }
            eventType={TooltipEventType.click}
            allowOpeningCheck={() => isSelfFollow}
            closeOnContentClick
            withoutShadowEffect
            placement="top"
            tooltipOffset={{ mainAxis: 90 }}
          >
            <div
              role="button"
              tabIndex={0}
              className={styles.Item__SelectMark}
              onKeyDown={handleJoinButtonClick}
              onClick={handleJoinButtonClick}
            >
              {!isSelfFollow && (
                <div className={styles.Item__Icon}>
                  <IconButton
                    customColor="#F7FAFA"
                    theme={IconButtonTheme.Primary}
                    iconName={IconFontName.Minus}
                    iconSize={IconFontSize.Big}
                  />
                </div>
              )}
            </div>
          </Tooltip>
        )}
        {!isSelected && (
          <button onClick={handleJoinButtonClick}>
            {!!imageSrc && <img src={imageSrc} alt={name || ''} />}
          </button>
        )}
        {!isSelected && (
          <div className={styles.Item__Icon}>
            <IconButton
              customColor="#F7FAFA"
              theme={IconButtonTheme.Secondary}
              onClick={handleJoinButtonClick}
              iconName={IconFontName.Add}
              iconSize={IconFontSize.Big}
            />
          </div>
        )}
        {!!name && <div className={styles.Item__Name}>{name}</div>}
      </div>
    );
  },
);
