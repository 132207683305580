import { useMutation, useQueryClient } from '@tanstack/react-query';
import { followQueryKeys } from 'query-hooks/followings/contastants/query-keys.constants';

import { FollowService } from 'services/follow/follow.service';
import { IFollowPayload } from 'services/follow/interfaces/followings-response.interface';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const followingsMutation = async (payload: IFollowPayload) => {
  const followService = container.get<FollowService>(TYPES.FollowService);

  const response = await followService.updateFollowings({
    players: {
      follow: payload.followPlayers,
      unfollow: payload.unFollowPlayers,
    },
    teams: {
      follow: payload.followTeams,
      unfollow: payload.unFollowTeams,
    },
  });

  return response.success;
};

export const useFollowingsMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: followingsMutation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: followQueryKeys.prefix });

      return true;
    },
  });

  return {
    followingsMutationAsync: mutation.mutateAsync,
  };
};
