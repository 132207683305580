import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { AchievementsStore } from 'stores/achievements/achievements.store';
import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { userBalanceAdapter } from 'stores/leaderboard/adapters/user-balance-adapter.util';
import { IReputationTransaction } from 'stores/reputation-transactions/interfaces/reputation-transaction.interface';
import { IReputationPopupData } from 'stores/reputations-points/interfaces/reputation-points.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';

@injectable()
export class ReputationsPointsStore extends ApiConnectedStore {
  private readonly userPublicStore: UserPublicStore;

  private readonly reputationPointsService: ReputationPointsService;

  private readonly authStore: AuthStore;

  private readonly achievementsStore: AchievementsStore;

  public reputationPopUpData: Maybe<IReputationPopupData>;

  public userPopUpReputationPoints: Maybe<IReputationPoints>;

  public reputationTransactions: Maybe<IReputationTransaction[]>;

  constructor(
    @inject<UserPublicStore>(TYPES.UserPublicStore)
    userPublicStore: UserPublicStore,
    @inject<ReputationPointsService>(TYPES.ReputationPointsService)
    reputationPointsService: ReputationPointsService,
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.AchievementsStore) achievementsStore: AchievementsStore,
  ) {
    super();

    this.authStore = authStore;

    this.userPublicStore = userPublicStore;

    this.achievementsStore = achievementsStore;

    this.reputationPointsService = reputationPointsService;

    this.reputationPopUpData = null;

    this.reputationTransactions = null;

    this.userPopUpReputationPoints = null;

    makeObservable(this, {
      reputationPopUpData: observable,
      reputationTransactions: observable,
      userPopUpReputationPoints: observable,

      setReputationTransactions: action.bound,
      setReputationPopUpData: action.bound,
      setUserPopUpReputationPoints: action.bound,
    });

    reaction(
      () => [this.userPublicStore?.userPopUpDetails?.username],
      this.requestUserPopUpReputationPoints,
    );
  }

  public setReputationPopUpData(data: Maybe<IReputationPopupData>) {
    this.reputationPopUpData = data;
  }

  public setReputationTransactions(data: Maybe<IReputationTransaction[]>) {
    this.reputationTransactions = data;
  }

  public setUserPopUpReputationPoints(data: Maybe<IReputationPoints>) {
    this.userPopUpReputationPoints = data;
  }

  public requestUserPopUpReputationPoints = async () => {
    if (!this.userPublicStore?.userPopUpDetails?.username) {
      this.setUserPopUpReputationPoints(null);

      return;
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.reputationPointsService.fetchUserBalance(
      this.userPublicStore.userPopUpDetails.username,
    );

    if (response.success) {
      const userBalance = response.data ? userBalanceAdapter(response.data) : null;

      this.setUserPopUpReputationPoints(userBalance);
    } else {
      this.setErrors(response.errors);
      this.setUserPopUpReputationPoints(null);
    }

    this.setFetching(false);
    this.setFetched(true);
  };
}
