import { useQuery } from '@tanstack/react-query';
import { followQueryKeys } from 'query-hooks/followings/contastants/query-keys.constants';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';

import { FollowService } from 'services/follow/follow.service';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

import { useGetAllTeamsQuery } from '../../teams/queries/use-get-all-teams.query';

const CACHE_TIME = 60 * 60 * 12 * 1000; // 12h

const retrieveGetAllFollowQuery = async () => {
  const followService = container.get<FollowService>(TYPES.FollowService);

  const response = await followService.loadFollowings();

  if (response.success) {
    return response.data ? response.data : [];
  }

  return [];
};

export const useGetAllFollowQuery = () => {
  const { data: players } = useGetAllPlayersQuery();

  const { data: teams } = useGetAllTeamsQuery();

  return useQuery({
    queryKey: followQueryKeys.prefix,
    queryFn: retrieveGetAllFollowQuery,
    gcTime: CACHE_TIME,
    enabled: !!players && !!teams,
    staleTime: CACHE_TIME,
  });
};
