import { IFollow, ISearchableFollow } from 'services/follow/interfaces/follow.interface';

export const filterFollowings = (items: IFollow[], searchString: string): IFollow[] => {
  if (!searchString) {
    return items;
  }

  const searchWords = searchString
    .split(/\s+/)
    .filter((word) => word.length >= 2)
    // A space is required at the begging of the word to avoid matching the word in the middle of the string
    .map((word) => ` ${word.toLowerCase()}`);

  const result: (IFollow & { score: number })[] = items.map((item) => {
    const localObj: ISearchableFollow = {
      label: item.label,
      firstName: item.firstName,
      city: item.city,
    };

    const searchable = ` ${Object.values(localObj).join(' ').toLowerCase()}`;
    let score = 0;

    searchWords.forEach((word) => {
      if (searchable.includes(word)) {
        score += 1;
      }
    });

    return { ...item, score };
  });

  return result.filter((item) => item.score === searchWords.length);
};
