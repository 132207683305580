import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';
import { UserPublicService } from 'services/user-public/user-public.service';

import { AchievementsStore } from 'stores/achievements/achievements.store';
import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';

import { TYPES } from 'configs/di-types.config';

import { AuthStore } from '../auth/auth.store';

import { userPublicAdapter } from './adapters/user-public.adapter';
import { IUserPublic } from './interfaces/user-public.interface';

@injectable()
export class UserPublicStore extends ApiConnectedStore {
  private readonly authStore: AuthStore;

  private readonly reputationPointsService: ReputationPointsService;

  private readonly achievementsStore: AchievementsStore;

  public userSlug: Maybe<string>;

  public isUserExist: boolean;

  public userPublic: Maybe<IUserPublic>;

  public userPopUpDetails: Maybe<IBasePublicationAuthor | IUserPublic>;

  private readonly userPublicService: UserPublicService;

  constructor(
    @inject<AuthStore>(TYPES.AuthStore) authStore: AuthStore,
    @inject<ReputationPointsService>(TYPES.ReputationPointsService)
    reputationPointsService: ReputationPointsService,
    @inject(TYPES.AchievementsStore) achievementsStore: AchievementsStore,
    @inject<UserPublicService>(TYPES.UserPublicService) userPublicService: UserPublicService,
  ) {
    super();

    this.reputationPointsService = reputationPointsService;

    this.achievementsStore = achievementsStore;

    this.userSlug = null;

    this.userPublic = null;

    this.isUserExist = true;

    this.userPopUpDetails = null;

    this.authStore = authStore;

    this.userPublicService = userPublicService;

    makeObservable(this, {
      userPublic: observable,
      userSlug: observable,
      isUserExist: observable,
      userPopUpDetails: observable,

      setUserSlug: action.bound,
      handleUserChange: action.bound,
      retrieveUser: action.bound,
      setUserPublic: action.bound,
      reset: action.bound,
      setIsUserExist: action.bound,
      setUserPopUpDetails: action.bound,
    });

    reaction(() => this.userSlug, this.handleUserChange);
    reaction(() => this.authStore.userMe, this.handleUserChange);
  }

  public handleUserChange() {
    this.retrieveUser();
  }

  public setUserPublic(userPublic: Maybe<IUserPublic>) {
    this.userPublic = userPublic;
  }

  public setUserSlug(userSlug: Maybe<string>) {
    this.userSlug = userSlug;
  }

  public setIsUserExist(value: boolean) {
    this.isUserExist = value;
  }

  public setUserPopUpDetails(userDetails: Maybe<IBasePublicationAuthor | IUserPublic>) {
    this.userPopUpDetails = userDetails;
  }

  public async retrieveUser(): Promise<void> {
    this.setFetching(true);
    this.resetErrors();

    if (!this.userSlug) {
      this.setUserPublic(null);
    } else {
      const userDetailResponse = await this.userPublicService.fetchUserDetails(this.userSlug);
      if (userDetailResponse.success) {
        this.setUserPublic(userPublicAdapter(userDetailResponse.data));
        this.setIsUserExist(true);
      } else {
        const errors = [...(userDetailResponse.success ? [] : userDetailResponse.errors)];
        this.setErrors(errors);

        if (userDetailResponse.code === 404) {
          this.setIsUserExist(false);
        }
      }
    }
    this.setFetching(false);
  }

  public reset() {
    this.userSlug = null;
    this.userPublic = null;
  }
}
