import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';

import { FILES_IMAGE_TYPES, FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { PostImageInput } from 'components/ui/form-fields/post-image-input/post-image-input.component';
import { InputImageDataType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import {
  isCollaborationImage,
  isCollaborationVideo,
  isICollaborationMediaArray,
} from 'components/ui/form-fields/post-image-input/utils';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { Loader } from 'components/ui/loader/loader.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './toolbar.module.less';

interface ISingleMediaToolbarPluginProps {
  mediaType?: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isLoading: boolean;
  postId?: string;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
}

export const SingleMediaToolbarPlugin = (props: ISingleMediaToolbarPluginProps) => {
  const {
    postId,
    isLoading,
    mediaType,
    collaborationMediaItem,
    setCollaborationMediaItem,
    convertVideoItemToAttachment,
    isCollaborationVideoLoading,
    isCollaborationImageLoading,
  } = props;

  const toolbarRef = useRef<HTMLDivElement>(null);
  const toolbarClasses = useMemo(() => cn(styles.Toolbar, styles['Toolbar--single-media']), []);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);

  const handleUploadVideo = useCallback(
    async (file: File) => {
      if (convertVideoItemToAttachment && setCollaborationMediaItem && postId) {
        const newVideoAttachment = await convertVideoItemToAttachment(file, postId);

        setCollaborationMediaItem(newVideoAttachment);
      }
    },
    [convertVideoItemToAttachment, postId, setCollaborationMediaItem],
  );

  const handleNewImage = useCallback(
    (fileData: InputImageDataType) => {
      if (!isICollaborationMediaArray(fileData) || !fileData.length || !setCollaborationMediaItem) {
        return;
      }

      setCollaborationMediaItem(fileData[0]);
    },
    [setCollaborationMediaItem],
  );

  return (
    <div ref={toolbarRef} className={toolbarClasses}>
      {mediaType === CollaborationMediaEnum.Image && (
        <>
          {collaborationMediaItem && isCollaborationImage(collaborationMediaItem) && (
            <div>
              {isCollaborationImageLoading ? (
                <div className={styles.SingleImage}>
                  <Loader isShow />
                </div>
              ) : (
                <img
                  className={styles.SingleImage}
                  src={collaborationMediaItem.smallLogoUrl}
                  alt="Attach"
                />
              )}
            </div>
          )}
          <PostImageInput
            allowMultiple={false}
            accept={FILES_IMAGE_TYPES.join(', ')}
            convertImageItemToAttachment={props.convertImageItemToAttachment}
            onImageLoad={handleNewImage}
            id="attachImage"
            name="attachImage"
            isDisabled={Boolean(isCollaborationImageLoading)}
          >
            {isLoading ? (
              <Loader isShow />
            ) : (
              <Button
                size={ButtonSize.Big}
                theme={ButtonTheme.Text}
                disabled={isCollaborationImageLoading}
                fluid
              >
                Change Image
              </Button>
            )}
          </PostImageInput>
        </>
      )}

      {mediaType === CollaborationMediaEnum.Video && (
        <>
          {collaborationMediaItem && isCollaborationVideo(collaborationMediaItem) && (
            <div className={styles.SingleVideo}>
              {isCollaborationVideoLoading ? (
                <Loader isShow />
              ) : (
                <VideoPreview size={VideoPreviewSize.SIZE_80} url={collaborationMediaItem.url} />
              )}
            </div>
          )}
          <PostVideoInput
            isVideoInputOpen={isVideoInputOpen}
            setIsVideoInputOpen={setIsVideoInputOpen}
            accept={FILES_VIDEO_TYPES.join(', ')}
            onVideoLoad={handleUploadVideo}
            id="attachVideo"
            name="attachVideo"
            isVideoPermissionsRequested={isVideoPermissionsRequested}
            setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
          >
            {isLoading ? (
              <Loader isShow />
            ) : (
              <Button
                size={ButtonSize.Big}
                theme={ButtonTheme.Text}
                fluid
                disabled={isCollaborationVideoLoading}
                onClick={() => setIsVideoInputOpen(true)}
              >
                Change Video
              </Button>
            )}
          </PostVideoInput>
        </>
      )}
    </div>
  );
};
