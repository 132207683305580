import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';
import { SortType } from 'types/sort-type.type';

import { IUserBalanceResponse } from 'services/reputation-points/interfaces/leaderboard-response.interface';
import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';
import { UserPublicService } from 'services/user-public/user-public.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BucketsStore } from 'stores/buckets/buckets.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { userBalanceAdapter } from 'stores/leaderboard/adapters/user-balance-adapter.util';
import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { PAGINATION_LIMIT } from 'stores/leaderboard/leaderboard.config';
import { ReputationPointsCategories } from 'stores/reputations-points/interfaces/reputation-points.interface';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { userPublicAdapter } from 'stores/user-public/adapters/user-public.adapter';
import { IUserPublic } from 'stores/user-public/interfaces/user-public.interface';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class LeaderboardStore extends ApiConnectedStore {
  private readonly seasonsStore: SeasonsStore;

  private readonly bucketsStore: BucketsStore;

  private readonly layoutStore: LayoutStore;

  private readonly authStore: AuthStore;

  private readonly reputationPointsService: ReputationPointsService;

  private readonly userPublicService: UserPublicService;

  private readonly paginationLimit: number;

  private currentPage: number;

  public order: SortType;

  public sortParam: ReputationPointsCategories;

  public seasonId: Maybe<string>;

  public bucketId: Maybe<string>;

  public currentUserBalance: Maybe<IUserBalance>;

  public userDetails: Maybe<IUserPublic>;

  constructor(
    @inject<SeasonsStore>(TYPES.SeasonsStore)
    seasonsStore: SeasonsStore,
    @inject<BucketsStore>(TYPES.BucketsStore)
    bucketsStore: BucketsStore,
    @inject<ReputationPointsService>(TYPES.ReputationPointsService)
    reputationPointsService: ReputationPointsService,
    @inject<UserPublicService>(TYPES.UserPublicService)
    userPublicService: UserPublicService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.AuthStore) authStore: AuthStore,
  ) {
    super();

    this.seasonsStore = seasonsStore;

    this.bucketsStore = bucketsStore;

    this.layoutStore = layoutStore;

    this.authStore = authStore;

    this.reputationPointsService = reputationPointsService;

    this.userPublicService = userPublicService;

    this.paginationLimit = PAGINATION_LIMIT;
    this.currentPage = 1;
    this.order = 'DESC';
    this.sortParam = ReputationPointsCategories.TOTAL_POINTS;

    this.seasonId = null;
    this.bucketId = null;

    this.currentUserBalance = null;

    this.userDetails = null;

    makeObservable(this, {
      seasonId: observable,
      bucketId: observable,
      sortParam: observable,

      pagination: computed,

      setCurrentPage: action.bound,
      setOrder: action.bound,
      setSortParam: action.bound,
      setCurrentUserBalance: action.bound,
      setSeasonId: action.bound,
      setBucketId: action.bound,
      fetchCurrentUserBalance: action.bound,
    });

    autorun(() => this.setSeasonId(this.seasonsStore.currentReputationSeasonOption?.value ?? null));
    autorun(() => this.setBucketId(this.bucketsStore.defaultBucket.value));
    reaction(() => [this.seasonId, this.bucketId], this.fetchCurrentUserBalance);
  }

  public get pagination(): IPagination {
    return {
      limit: this.paginationLimit,
      page: this.currentPage,
    };
  }

  public setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  public setOrder(order: SortType) {
    this.order = order;
  }

  public setSortParam(sortParam: ReputationPointsCategories) {
    this.sortParam = sortParam;
  }

  public setSeasonId(seasonId: Maybe<string>) {
    this.seasonId = seasonId;
  }

  public setBucketId(bucketId: Maybe<string>) {
    this.bucketId = bucketId;
  }

  public setCurrentUserBalance(userBalance: Maybe<IUserBalance>) {
    this.currentUserBalance = userBalance;
  }

  public async getCurrentUserBalance() {
    if (this.currentUserBalance) {
      return this.currentUserBalance;
    }

    await this.fetchCurrentUserBalance();

    return this.currentUserBalance;
  }

  public async getUserDetails(username: string) {
    if (!this.userDetails || this.userDetails.username !== username) {
      this.userDetails = await this.fetchUserDetails(username);
    }

    return this.userDetails;
  }

  public async retrieveUserBalances(): Promise<{ rows: IUserBalance[]; count: number }> {
    this.setFetched(false);
    this.setFetching(true);

    const response = await this.reputationPointsService.fetchUserBalances(
      this.seasonId,
      this.bucketId,
      this.pagination,
      this.order,
      this.sortParam,
    );
    let result: { rows: IUserBalance[]; count: number } = { rows: [], count: 0 };

    if (response.success) {
      const { items, count } = response.data;
      const rows = items.map((item: IUserBalanceResponse) => userBalanceAdapter(item));

      this.setCurrentPage(this.currentPage + 1);

      result = { rows, count };
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
    this.setFetched(true);

    return result;
  }

  public async fetchCurrentUserBalance() {
    if (!this.seasonId || !this.authStore.isAuthorised || !this.authStore.userMe) {
      this.setCurrentUserBalance(null);

      return;
    }

    const response = await this.reputationPointsService.fetchUserBalance(
      this.authStore.userMe.username,
      this.seasonId,
      this.bucketId,
    );

    if (response.success) {
      const userBalance = response.data ? userBalanceAdapter(response.data) : null;

      this.setCurrentUserBalance(userBalance);
    } else {
      this.setErrors(response.errors);
      this.setCurrentUserBalance(null);
    }
  }

  public async fetchUserDetails(username: string) {
    this.setFetching(true);
    this.resetErrors();

    const response = await this.userPublicService.fetchUserDetails(username);

    if (response.success) {
      return userPublicAdapter(response.data);
    }

    this.setErrors(response.errors);
    this.setFetching(false);

    return null;
  }

  public reset() {
    this.setCurrentUserBalance(null);
    this.setCurrentPage(1);
  }
}
