import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { reputationQueryKeys } from 'query-hooks/reputation/contastants/query-keys.constants';

import { ReputationPointsService } from 'services/reputation-points/reputationPointsService';

import { userBalanceAdapter } from 'stores/leaderboard/adapters/user-balance-adapter.util';

import { TYPES } from 'configs/di-types.config';
import { container } from 'configs/inversify.config';

const CACHE_TIME = 30 * 1000; // 30 seconds
const POLL_INTERVAL = 30 * 1000; // 30 seconds

const retrieveUserBalance = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof reputationQueryKeys)['userBalance']>>) => {
  const reputationPointsService = container.get<ReputationPointsService>(
    TYPES.ReputationPointsService,
  );

  const [, , { username }] = queryKey;

  if (!username) {
    return null;
  }

  const response = await reputationPointsService.fetchUserBalance(username);

  if (response.success) {
    return response.data ? userBalanceAdapter(response.data) : null;
  }

  return null;
};

export const useUserBalanceQuery = (username?: string) => {
  return useQuery({
    queryKey: reputationQueryKeys.userBalance(username),
    queryFn: retrieveUserBalance,
    gcTime: CACHE_TIME,
    staleTime: CACHE_TIME,
    refetchInterval: POLL_INTERVAL,
    refetchIntervalInBackground: true,
  });
};
