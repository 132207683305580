import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetAllFollowQuery } from 'query-hooks/followings/queries/use-get-all-follow.query';
import { useGetAllPlayersQuery } from 'query-hooks/players/queries/use-get-all-players.query';
import { useGetAllTeamsQuery } from 'query-hooks/teams/queries/use-get-all-teams.query';

import { followBuilder } from 'services/follow/adapters/follow-builder.util';
import { IFollow } from 'services/follow/interfaces/follow.interface';

import { findElementById } from 'helpers/find-element-by-id.util';

export const useFollowData = () => {
  const [followData, setFollowData] = useState<IFollow[]>([]);

  const { data: follow = [], isFetching: isFetchingFollow } = useGetAllFollowQuery();

  const { data: teams = [] } = useGetAllTeamsQuery();

  const { data: players = [] } = useGetAllPlayersQuery();

  useEffect(() => {
    setFollowData(followBuilder(follow, teams, players));
  }, [teams, players, follow]);

  const followingPlayers = useMemo(() => {
    return followData.filter((item) => item.isFollow && item.type === 'player');
  }, [followData]);

  const followingTeams = useMemo(() => {
    return followData.filter((item) => item.isFollow && item.type === 'team');
  }, [followData]);

  const playersFollowAbbrevs = useMemo(() => {
    return followData.map((player) => {
      const firstNameCharacters = player.firstName.slice(0, 2);

      return `${firstNameCharacters}${player.lastName}`;
    });
  }, [followData]);

  const teamsFollowAbbrevs = useMemo(() => {
    return followData.map((team) => {
      const splittedTeam = team.city.concat(' ', team.label).split(' ');
      const firstCharacters = splittedTeam.map((teamItem) =>
        teamItem.charAt(0).toLocaleUpperCase(),
      );

      return firstCharacters.join('');
    });
  }, [followData]);

  const sortedFollowings = useMemo(() => {
    return [
      ...followingPlayers.sort((a, b) => b.followDate - a.followDate),
      ...followingTeams.sort((a, b) => b.followDate - a.followDate),
    ];
  }, [followingPlayers, followingTeams]);

  const getIsFollowPlayer = useCallback(
    (id: number) => {
      if (!id) return false;

      const playerId = `${id}player`;
      const player = followingPlayers.find((followItem) => followItem.id === playerId);

      return !!player?.isFollow;
    },
    [followingPlayers],
  );

  const getIsFollowTeam = useCallback(
    (id: number) => {
      if (!id) return false;

      const teamId = `${id}team`;
      const team = followingTeams.find((followItem) => followItem.id === teamId);

      return !!team?.isFollow;
    },
    [followingTeams],
  );

  const getPlayerById = useCallback(
    (playerId: Maybe<number>) => {
      return findElementById(players, playerId);
    },
    [players],
  );

  const getTeamById = useCallback(
    (teamId: Maybe<number>) => {
      return findElementById(teams, teamId);
    },
    [teams],
  );

  return {
    followData,
    followingPlayers,
    followingTeams,
    playersFollowAbbrevs,
    teamsFollowAbbrevs,
    sortedFollowings,
    getIsFollowPlayer,
    getIsFollowTeam,
    isFetchingFollow,
    getPlayerById,
    getTeamById,
  };
};
