import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import {
  IDaysStreak,
  IReputationPopupData,
  ReputationPointsCategories,
} from 'stores/reputations-points/interfaces/reputation-points.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { DaysStreakAchievementBadge } from 'components/achievements/days-streak-achievement-badge/days-streak-achievement-badge.component';
import { AvatarFrame } from 'components/profile-info/profile-info.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';
import { IReputationBoxItem } from 'components/user-details/reputation-box-item/interfaces/reputation-box-item.interface';
import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';
import { ReputationBoxItem } from 'components/user-details/reputation-box-item/reputation-box-item.component';
import { prepareReputationPointsItems } from 'components/user-details/utils/prepare-reputation-points-items';

import bronzeFrame from 'assets/images/svg/bronze-frame.svg';
import goldFrame from 'assets/images/svg/gold-frame.svg';
import silverFrame from 'assets/images/svg/silver-frame.svg';
import defaultThumbnailUrl from 'assets/images/svg/user-default-background.svg';

import styles from './user-details.module.less';

interface IUserDetailsProps {
  username: string;
  realName?: Maybe<string>;
  team: Maybe<ITeamsStats>;
  player: Maybe<IPlayerStats>;
  avatarSrc: Maybe<string>;
  thumbnailURL: Maybe<string>;
  reputationPoints: Maybe<IReputationPoints>;
  frame?: AvatarFrame;
  openReputationPopUp?: (data: Maybe<IReputationPopupData>) => void;
  onClose: () => void;
  daysStreak: Maybe<IDaysStreak>;
}

export const defaultReputationPoints: IReputationPoints = {
  totalPoints: 0,
  totalPointsRank: 0,
  playerPoints: 0,
  playerPointsRank: 0,
  participationPoints: 0,
  participationPointsRank: 0,
  predictionPoints: 0,
  predictionPointsRank: 0,
};

const DEFAULT_THUMBNAIL_URL = defaultThumbnailUrl;

const AVATAR_FRAME_MAP: Record<AvatarFrame, string> = {
  [AvatarFrame.Bronze]: bronzeFrame,
  [AvatarFrame.Silver]: silverFrame,
  [AvatarFrame.Gold]: goldFrame,
};

export const UserDetails: FC<IUserDetailsProps> = memo((props: IUserDetailsProps) => {
  const {
    daysStreak,
    username,
    realName,
    team,
    player,
    thumbnailURL,
    avatarSrc,
    reputationPoints,
    frame,
    onClose,
    openReputationPopUp,
  } = props;

  const navigate = useNavigate();

  const handleMockCallback = useCallback(() => {}, []);

  const handleLeaderboardClick = useCallback(() => {
    onClose();
    navigate(path.LEADERBOARD);
  }, [onClose, navigate]);

  const handleSeeAllAchievementsClick = useCallback(() => {
    onClose();
    navigate(
      getPath(path.USER_PUBLIC_ACHIEVEMENTS, {
        [path.USERNAME_PARAM]: username,
      }),
    );
  }, [onClose, navigate, username]);

  const reputationPointsBoxItems = useMemo<IReputationBoxItem[]>(() => {
    if (!reputationPoints) {
      return prepareReputationPointsItems(defaultReputationPoints);
    }

    return prepareReputationPointsItems(reputationPoints);
  }, [reputationPoints]);

  const avatarClasses = useMemo(() => {
    return cn(styles.AuthorCard__Avatar, {
      [styles.AuthorCard__Avatar__withFrame]: frame,
    });
  }, [frame]);

  const handleOpenUserProfile = useCallback(() => {
    navigate(
      getPath(path.USER_PUBLIC_PROFILE, {
        [path.USERNAME_PARAM]: username,
      }),
    );
    onClose();
  }, [username, navigate, onClose]);

  const handlePointSectionClick = useCallback(
    (type: ReputationPointsCategories) => {
      openReputationPopUp?.({
        type,
        subTypeId: null,
        username,
      });
    },
    [openReputationPopUp, username],
  );

  return (
    <div className={styles.AuthorCard}>
      <div className={styles.AuthorCard__Header}>
        <div className={styles.AuthorCard__HeaderThumbnail}>
          <img
            src={thumbnailURL || DEFAULT_THUMBNAIL_URL}
            alt="thumbnail background"
            className={styles.AuthorCard__Background}
          />
        </div>
        <div className={avatarClasses}>
          {frame && (
            <>
              <img
                className={styles.AuthorCard__Avatar__Frame}
                src={AVATAR_FRAME_MAP[frame]}
                alt="avatar frame"
              />
              {/* Uncomment when backend is ready */}
              {/* <div className={styles.AuthorCard__Avatar__Badge}> */}
              {/*  <img */}
              {/*    src={playerAvatar} */}
              {/*    alt="achievement badge" */}
              {/*  /> */}
              {/* </div> */}
            </>
          )}
          <Avatar username={username} size={AvatarSize.MEGA} src={avatarSrc} />
        </div>
      </div>
      <div className={styles.AuthorCard__Details}>
        <div className={styles.AuthorCard__Names}>
          <h4>{realName}</h4>
          <div role="presentation" onClick={handleOpenUserProfile}>
            @{username}
          </div>
        </div>
        <TeamPlayerLabel
          team={team}
          player={player}
          onTeamClick={handleMockCallback}
          onPlayerClick={handleMockCallback}
        />
      </div>
      <div className={styles.ReputationPoints}>
        <div className={styles.ReputationPoints__ReputationHeader}>
          <div className={styles.ReputationPoints__Title}>Reputation points</div>
          <button className={styles.ReputationPoints__Action} onClick={handleLeaderboardClick}>
            Leaderboard
          </button>
        </div>
        <div className={styles.ReputationPoints__Table}>
          {reputationPointsBoxItems.map((item) => (
            <ReputationBoxItem
              type={item.type}
              key={item.title}
              title={item.title}
              content={item.content}
              rank={item.rank}
              onClick={() => handlePointSectionClick(item.type)}
            />
          ))}
        </div>
        <div className={styles.ReputationPoints__ReputationHeader}>
          <div className={styles.ReputationPoints__Title}>Achievements</div>
          <button
            className={styles.ReputationPoints__Action}
            onClick={handleSeeAllAchievementsClick}
          >
            See All
          </button>
        </div>
      </div>
      {daysStreak && (
        <div className={styles.ReputationPoints__Achievements}>
          <DaysStreakAchievementBadge value={daysStreak.current} />
        </div>
      )}
    </div>
  );
});
