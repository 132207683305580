import { FC } from 'react';
import cn from 'classnames';

import { IFollow } from 'services/follow/interfaces/follow.interface';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';

import { IS_ENABLE_OTHER_DIGITS_PLAYERS } from 'configs/feature.config';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './favorites.module.less';

interface IFavoritesProps {
  favorites: IFollow[];
  digitsAthletes: IAthlete[];
  onFollowClick: () => void;
  onFollowedItemClick: (following: IFollow) => () => void;
  onAthleteClick: (athlete: IAthlete) => () => void;
}

export const Favorites: FC<IFavoritesProps> = (props) => {
  const { favorites, digitsAthletes } = props;

  return (
    <div className={styles.Favorites}>
      <div className={styles.Favorites__Navigation}>
        <h3 className={styles.Favorites__Header}>Favorites</h3>
        <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={props.onFollowClick}>
          Edit
        </Button>
      </div>
      <div className={styles.Favorites__List}>
        <button className={styles.Favorites__Item} onClick={props.onFollowClick}>
          <div className={styles.Favorites__Button}>
            <IconFont name={IconFontName.Add} size={IconFontSize.Big} />
          </div>
        </button>
        {favorites.map((item) => (
          <button
            key={item.id}
            className={styles.Favorites__Item}
            onClick={props.onFollowedItemClick(item)}
          >
            <Avatar
              size={AvatarSize.XL}
              username={`${item.firstName} ${item.lastName}`}
              src={item.thumbnailUrl}
            />
            <div className={styles.Favorites__Name}>
              {item.type === 'team' ? item.label : `${item.firstName} ${item.lastName}`}
            </div>
          </button>
        ))}
      </div>
      {IS_ENABLE_OTHER_DIGITS_PLAYERS && (
        <>
          <div className={styles.Favorites__Navigation}>
            <h3 className={styles.Favorites__Header}>Other Digits Players</h3>
          </div>
          <div className={cn(styles.Favorites__List, styles['Favorites__List--athlete'])}>
            {digitsAthletes.map((item) => (
              <button
                key={item.playerSlug}
                className={styles.Favorites__Item}
                onClick={props.onAthleteClick(item)}
              >
                <Avatar
                  size={AvatarSize.XL}
                  username={`${item.firstName} ${item.lastName}`}
                  src={item.mediumLogoUrl}
                />
                <div className={styles.Favorites__Name}>{`${item.firstName} ${item.lastName}`}</div>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
