import { memo, useMemo } from 'react';
import { ContentEditable as LexicalContentEditable } from '@lexical/react/LexicalContentEditable';
import cn from 'classnames';

import { EditorType } from '../base-editor/base-editor.component';

import styles from './content-editable-with-scroll.module.less';

interface IContentEditableProps {
  editorType?: EditorType;
  error?: string;
  isActive: boolean;
}
export const ContentEditableWithScroll = memo((props: IContentEditableProps): JSX.Element => {
  const { editorType, error, isActive } = props;

  const contentEditableClasses = useMemo(
    () =>
      cn(styles.ContentEditable, {
        [styles['ContentEditable--height-22']]: editorType === EditorType.SimplePost,
        [styles['ContentEditable--height-12']]:
          editorType === EditorType.GroupPost ||
          editorType === EditorType.CommentMobile ||
          editorType === EditorType.Collaboration ||
          editorType === EditorType.VideoPost,
        [styles['ContentEditable--comment-desktop']]: editorType === EditorType.CommentDesktop,
        [styles['ContentEditable--error']]: error,
        [styles['ContentEditable--active']]: isActive && editorType !== EditorType.CommentDesktop,
      }),
    [editorType, error, isActive],
  );

  return <LexicalContentEditable autoCapitalize="on" className={contentEditableClasses} />;
});
