import { FC, useMemo } from 'react';

import { IGameTeamInfo } from 'stores/game/interfaces/game.interface';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './game-header.module.less';

export interface IGameHeaderProps {
  date: string;
  homeTeam: IGameTeamInfo;
  visitorsTeam: IGameTeamInfo;
  onShareClick: () => void;
}

export const GameHeader: FC<IGameHeaderProps> = (props) => {
  const { homeTeam, visitorsTeam, date } = props;

  const title = useMemo(() => {
    return `${visitorsTeam.teamInfo.code} - ${homeTeam.teamInfo.code} `;
  }, [homeTeam, visitorsTeam]);

  return (
    <div className={styles.Header}>
      <span className={styles.Header__Title}>{title}</span>
      <span className={styles.Header__Date}>{date}</span>
      <IconButton
        iconName={IconFontName.Share}
        theme={IconButtonTheme.Secondary}
        onClick={props.onShareClick}
      />
    </div>
  );
};
