import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { IFollow } from 'services/follow/interfaces/follow.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { IGame } from 'services/statistics/interface/game.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useResponsive } from 'hooks/use-responsive';

import { Statistics } from 'components/statistics/statistics.component';
import { StatisticsType } from 'components/statistics/statistics-type.enum';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './shortcut-bar.module.less';

interface IShortcutBarProps {
  initialExpandState: boolean;
  favorites: IFollow[];
  games: IGame[];
  firstGameIds: number[];
  firstGameTodayId?: Maybe<number>;
  onFollowClick: () => void;
  onGameClick: (gameId: number, opponents?: string) => void;
  onFollowedItemClick: (following: IFollow) => () => void;
  sendGoogleAnalytics: (buttonTapType: ButtonTapsEnum) => void;
}

export const ShortcutBar = memo((props: IShortcutBarProps) => {
  const {
    initialExpandState,
    favorites,
    games,
    firstGameIds,
    firstGameTodayId,
    sendGoogleAnalytics,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const elementRef = useRef<HTMLDivElement>(null);

  const [expanded, setExpanded] = useState(initialExpandState);

  const shortcutBarClasses = useMemo(
    () =>
      cn(styles.ShortcutBar, TOUCH_IGNORE_CLASS, {
        [styles['ShortcutBar--expanded']]: expanded,
        [styles['ShortcutBar--freezed']]: !favorites.length,
      }),
    [expanded, favorites],
  );

  const handleFavoritesExpand = useCallback(() => {
    if (!expanded && favorites.length) {
      setExpanded(true);

      sendGoogleAnalytics(ButtonTapsEnum.ShortcutExpandFavorites);
    }
  }, [expanded, favorites, sendGoogleAnalytics]);

  const handleGamesExpand = useCallback(() => {
    if (expanded) {
      setExpanded(false);

      sendGoogleAnalytics(ButtonTapsEnum.ShortcutExpandGames);
    }
  }, [expanded, sendGoogleAnalytics]);

  useEffect(() => {
    if (!expanded) {
      elementRef.current?.scrollTo(0, 0);
    }
  }, [expanded]);

  useEffect(() => {
    if (isDesktopPlus) {
      setExpanded(false);
    } else {
      setExpanded(initialExpandState);
    }
  }, [isDesktopPlus, initialExpandState]);

  return (
    <div className={shortcutBarClasses}>
      <div role="presentation" className={styles.ShortcutBarGames} onClick={handleGamesExpand}>
        <Statistics
          expanded={!expanded}
          type={StatisticsType.ShortcutBar}
          games={games}
          firstGameIds={firstGameIds}
          firstGameTodayId={firstGameTodayId}
          onGameClick={props.onGameClick}
        />
      </div>
      {!isDesktopPlus && (
        <div
          role="presentation"
          ref={elementRef}
          className={styles.ShortcutBarFavorites}
          onClick={handleFavoritesExpand}
        >
          <button className={styles.ShortcutBarFavorites__Item} onClick={props.onFollowClick}>
            <div className={styles.ShortcutBarFavorites__Button}>
              <IconFont name={IconFontName.Add} size={IconFontSize.Big} />
            </div>
          </button>
          {favorites.map((item) => (
            <button
              key={item.id}
              className={styles.ShortcutBarFavorites__Item}
              onClick={props.onFollowedItemClick(item)}
            >
              <Avatar
                size={AvatarSize.SIZE_80}
                username={`${item.firstName} ${item.lastName}`}
                src={item.thumbnailUrl}
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
});
