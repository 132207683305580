import { useRef, useState } from 'react';

import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { Loader } from 'components/ui/loader/loader.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './toolbar.module.less';

interface IVideoPostToolbarPluginProps {
  isVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  onUploadVideo?: (video: File) => void;
}

export const VideoPostToolbarPlugin = (props: IVideoPostToolbarPluginProps) => {
  const { postVideo, isVideoLoading } = props;
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);

  return (
    <div ref={toolbarRef} className={styles.VideoToolbar}>
      {postVideo && (
        <div className={styles.SingleVideo}>
          {isVideoLoading ? (
            <Loader isShow />
          ) : (
            <VideoPreview size={VideoPreviewSize.SIZE_80} url={postVideo.url} />
          )}
        </div>
      )}
      <PostVideoInput
        isVideoInputOpen={isVideoInputOpen}
        setIsVideoInputOpen={setIsVideoInputOpen}
        accept={FILES_VIDEO_TYPES.join(', ')}
        onVideoLoad={props.onUploadVideo}
        id="attachVideo"
        name="attachVideo"
        isVideoPermissionsRequested={isVideoPermissionsRequested}
        setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
      >
        <Button
          size={ButtonSize.Small}
          theme={ButtonTheme.Secondary}
          fluid
          disabled={isVideoLoading}
          onClick={() => setIsVideoInputOpen(true)}
        >
          Change Video
        </Button>
      </PostVideoInput>
    </div>
  );
};
