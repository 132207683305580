import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  BodyScrollEvent,
  GridApi,
  GridReadyEvent,
  IGetRowsParams,
  ModelUpdatedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { SortType } from 'types/sort-type.type';

import { AuthStore } from 'stores/auth/auth.store';
import { BucketsStore } from 'stores/buckets/buckets.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutStore } from 'stores/layout/layout.store';
import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { LeaderboardStore } from 'stores/leaderboard/leaderboard.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { capitalizeEachWord } from 'helpers/string/capitalize-each-word.util';

import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useResponsive } from 'hooks/use-responsive';

import { Leaderboard } from 'components/leaderboard/leaderboard.component';
import { LeaderboardHeader } from 'components/leaderboard/leaderboard-header/leaderboard-header.component';
import { LeaderboardFilters } from 'components/leaderboard-filters/leaderboard-filters.component';

import styles from './leaderboard-container.module.less';

export const LeaderboardContainer = observer(() => {
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const bucketsStore = useInjection<BucketsStore>(TYPES.BucketsStore);
  const leaderboardStore = useInjection<LeaderboardStore>(TYPES.LeaderboardStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const gridContainerRef = useRef<HTMLDivElement>(null);
  const agGridRef = useRef<AgGridReact>();

  const renderHeader = useCallback(() => {
    return <LeaderboardHeader />;
  }, []);

  useLayoutEntity({ type: LayoutEntity.HeaderCenter, value: renderHeader });

  useEffect(() => {
    return () => {
      leaderboardStore.reset();
    };
  }, [leaderboardStore]);

  const handleGridReady = useCallback(
    (e: GridReadyEvent) => {
      const dataSource = {
        rowCount: undefined,
        getRows: async ({ successCallback, endRow }: IGetRowsParams) => {
          const { rows, count } = await leaderboardStore.retrieveUserBalances();
          let lastRow = -1;

          if (count <= endRow) {
            lastRow = count;
          }

          successCallback(rows, lastRow);
        },
      };
      e.api.setDatasource(dataSource);
    },
    [leaderboardStore],
  );

  const handleUserClick = useCallback(
    async (username: string) => {
      const userDetails = await leaderboardStore.getUserDetails(username);

      if (!userDetails) {
        return;
      }

      userPublicStore.setUserPopUpDetails(userDetails);
      layoutStore.setIsUserDetailsPopUpOpen(true);
    },
    [userPublicStore, layoutStore, leaderboardStore],
  );

  const handleSortChanged = useCallback(
    (e: SortChangedEvent) => {
      leaderboardStore.setCurrentPage(1);
      const firstRow = e.api.getDisplayedRowAtIndex(0);

      e.api.refreshHeader();
      e.api.refreshCells();

      if (firstRow) {
        e.api.ensureIndexVisible(0, 'top');
      }

      const focusedCell = e.columnApi.getColumns()?.find((column) => column.getSort());

      if (focusedCell?.getSort()) {
        leaderboardStore.setOrder(focusedCell.getSort()!.toUpperCase() as SortType);
      }
    },
    [leaderboardStore],
  );

  const shouldRowBePinned = useCallback(
    (api: GridApi<IUserBalance>, scroll = 0): boolean => {
      const rows = api.getRenderedNodes();

      if (!rows[0]?.data) {
        return false;
      }

      const { rowHeight, headerHeight } = api.getSizesForCurrentTheme();
      const bottomOffset = isDesktopPlus ? 24 : 0;

      const currentUserRendered = rows.find(
        (row) => row.data?.user?.username === authStore.userMe?.username,
      );

      if (currentUserRendered) {
        const tableHeight = gridContainerRef.current?.clientHeight ?? 0;

        const position = currentUserRendered.rowTop!;

        const topBorder = position - tableHeight + headerHeight + rowHeight - bottomOffset;
        const bottomBorder = position + headerHeight;

        return !(scroll > topBorder && scroll < bottomBorder);
      }

      return true;
    },
    [authStore, isDesktopPlus],
  );

  const handleBodyScroll = useCallback(
    async (e: BodyScrollEvent<IUserBalance>) => {
      if (e.top < 0) {
        return;
      }

      const pinnedRowData = await leaderboardStore.getCurrentUserBalance();
      const isPinned = shouldRowBePinned(e.api, e.top);

      e.api.setPinnedBottomRowData(isPinned && pinnedRowData ? [pinnedRowData] : undefined);
    },
    [shouldRowBePinned, leaderboardStore],
  );

  const handleModelUpdated = useCallback(
    async (e: ModelUpdatedEvent<IUserBalance>) => {
      const pinnedRowData = await leaderboardStore.getCurrentUserBalance();
      const isPinned = shouldRowBePinned(e.api);

      e.api.setPinnedBottomRowData(isPinned && pinnedRowData ? [pinnedRowData] : undefined);
    },
    [shouldRowBePinned, leaderboardStore],
  );

  useEffect(() => {
    let previousWidth = 0;

    const resizeObserver = new ResizeObserver(async () => {
      if (!agGridRef.current || !agGridRef.current.api || !gridContainerRef.current) {
        return;
      }

      const isWidthChanged = previousWidth !== gridContainerRef.current.clientWidth;

      if (isWidthChanged) {
        previousWidth = gridContainerRef.current.clientWidth;

        return;
      }

      const { api } = agGridRef.current;

      const pinnedRowData = await leaderboardStore.getCurrentUserBalance();
      const isPinned = shouldRowBePinned(api);

      api.setPinnedBottomRowData(isPinned && pinnedRowData ? [pinnedRowData] : undefined);
    });

    if (gridContainerRef.current) {
      resizeObserver.observe(gridContainerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [leaderboardStore, shouldRowBePinned]);

  const handleFilterChange = useCallback(
    (value: string, type: 'season' | 'bucket') => {
      leaderboardStore.setCurrentPage(1);
      leaderboardStore.setCurrentUserBalance(null);

      if (type === 'bucket') {
        leaderboardStore.setBucketId(value);
      } else {
        leaderboardStore.setSeasonId(value);
      }

      if (agGridRef.current) {
        const { api } = agGridRef.current;

        if (api) {
          const firstRow = api.getDisplayedRowAtIndex(0);

          api.refreshHeader();
          api.refreshCells();

          if (firstRow) {
            api.ensureIndexVisible(0, 'top');
          }

          api.onFilterChanged();
        }
      }
    },
    [leaderboardStore],
  );

  const tableTitle = useMemo(() => {
    return isDesktopPlus
      ? 'Total Points'
      : `Best in ${capitalizeEachWord(leaderboardStore.sortParam.replace('_', ' '))}`;
  }, [leaderboardStore.sortParam, isDesktopPlus]);

  return (
    <div className={styles.Leaderboard} ref={gridContainerRef}>
      <h4 className={styles.Leaderboard__Title}>{tableTitle}</h4>
      <LeaderboardFilters
        seasonId={leaderboardStore.seasonId ?? ''}
        bucketId={leaderboardStore.bucketId ?? ''}
        seasonsOptions={seasonsStore.reputationSeasonsSelectOptions}
        bucketsOptions={bucketsStore.bucketsSelectOptions}
        onSeasonIdChange={(value) => handleFilterChange(value, 'season')}
        onBucketIdChange={(value) => handleFilterChange(value, 'bucket')}
      />
      <Leaderboard
        onGridReady={handleGridReady}
        sortParam={leaderboardStore.sortParam}
        order={leaderboardStore.order}
        onUserClick={handleUserClick}
        onSortChanged={handleSortChanged}
        onSetSortParam={leaderboardStore.setSortParam}
        onBodyScroll={handleBodyScroll}
        onModelUpdated={handleModelUpdated}
        isLoading={leaderboardStore.fetching}
        ref={(node: AgGridReact) => {
          agGridRef.current = node;
        }}
      />
    </div>
  );
});
