import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { useFollowData } from 'query-hooks/followings/use-follow-data';

import { StatsMode } from 'stores/global-stats/enums/global-stats.enums';
import {
  GLOBAL_STATS_EMPTY_STATE_MESSAGE,
  GLOBAL_STATS_EMPTY_STATE_TITLE,
  PLAYER_SLUG_SEARCH_PARAM,
  SORT_DIRECTION_PARAM,
  SORT_SEARCH_PARAM,
} from 'stores/global-stats/global-stats.config';
import { GlobalStatsStore } from 'stores/global-stats/global-stats.store';
import { getSortedPlayersItems } from 'stores/global-stats/utils/get-sorted-player-items.util';
import { isPlayersGlobalStatsSortValue } from 'stores/global-stats/utils/is-players-global-stats-sort-value.util';
import { isSortDirection } from 'stores/global-stats/utils/is-sort-direction.util';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useTeamClick } from 'hooks/use-team-click';

import { PAGE_PARAM } from 'components/base-table/tables.configs';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { GlobalStatsPlayers } from 'components/stats/global-stats/global-stats-players.component';
import { Glossary } from 'components/ui/glossary/glossary.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from '../stats.module.less';

export const GlobalStatsPlayersContainer = observer(() => {
  const globalStatsStore = useInjection<GlobalStatsStore>(TYPES.GlobalStatsStore);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const sortField = searchParams.get(SORT_SEARCH_PARAM);
  const sortDirection = searchParams.get(SORT_DIRECTION_PARAM);
  const playerSlug = searchParams.get(PLAYER_SLUG_SEARCH_PARAM);
  const currentPage = Number(searchParams.get(PAGE_PARAM)) || 1;

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(
        getPath(paths.PLAYER_PROFILE, {
          [paths.PLAYER_SLUG_PARAM]: slug,
        }),
      );
    },
    [navigate],
  );

  const handleTeamClick = useTeamClick();

  const sortedPlayersItems = useMemo(() => {
    if (isPlayersGlobalStatsSortValue(sortField) && isSortDirection(sortDirection)) {
      return getSortedPlayersItems(
        sortField,
        globalStatsStore.playersGlobalStatsRowData,
        currentPage,
        sortDirection,
      );
    }

    return getSortedPlayersItems('points', globalStatsStore.playersGlobalStatsRowData, currentPage);
  }, [sortField, sortDirection, globalStatsStore.playersGlobalStatsRowData, currentPage]);

  const { getTeamById } = useFollowData();

  const selectedTeam = getTeamById(globalStatsStore.playersFilters.teamId);

  const handleSortChange = useCallback(
    (sort: string, direction: string) => {
      setSearchParams(
        {
          sort,
          direction,
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const loading = useMemo(
    () =>
      // !globalStatsStore.playersGlobalStats.length ||
      !sortedPlayersItems.length || !globalStatsStore.seasonId || globalStatsStore.fetching,
    [
      globalStatsStore.fetching,
      globalStatsStore.seasonId,
      // globalStatsStore.playersGlobalStats.length,
      sortedPlayersItems.length,
    ],
  );

  if (globalStatsStore.shouldDisplayEmptyState[StatsMode.PLAYERS]) {
    return (
      <div className={styles.EmptyStateWrapper}>
        <EmptyState
          title={GLOBAL_STATS_EMPTY_STATE_TITLE}
          message={GLOBAL_STATS_EMPTY_STATE_MESSAGE}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className={styles.LoaderWrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <>
      <GlobalStatsPlayers
        playerSlug={playerSlug}
        sortedField={sortField}
        sortDirection={sortDirection}
        sortedItems={sortedPlayersItems}
        playersStatsRowData={globalStatsStore.playersGlobalStatsRowData}
        selectedTeam={selectedTeam}
        onSortChange={handleSortChange}
        onPlayerClick={handlePlayerClick}
        onTeamClick={handleTeamClick}
      />
      <Glossary entities={globalStatsStore.glossaryEntities} />
    </>
  );
});
