import { memo, useCallback, useState } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { trimEditorContent } from 'helpers/trim-editor-content.util';

import { useResponsive } from 'hooks/use-responsive';

import { TextCommentEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/text-comment-editor-bottom-sheet.component';
import { CommentCreateDataType } from 'components/forms/text-comment-create/text-comment-create-form.component';
import { CreateTextCommentModal } from 'components/posts/create-text-comment-modal/create-text-comment-modal.component';

interface IHandlePollQuoteProps {
  onClose: () => void;
  onPollQuoteUpdate: (quoteContent: string) => Promise<void>;
  placeholder: string;
  title: string;
  initialValues?: CommentCreateDataType;
}

export const HandlePollQuote = memo((props: IHandlePollQuoteProps) => {
  const {
    onClose: handleClose,
    onPollQuoteUpdate: handlePollContent,
    initialValues,
    placeholder,
    title,
  } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [processing, setProcessing] = useState(false);

  const handleSubmit = useCallback(
    async ({ editor }: CommentCreateDataType) => {
      setProcessing(true);

      let content = '';

      if (editor.content?.length && editor.content.length > 0) {
        content = editor.content.value;

        const editorState = JSON.parse(editor?.content?.value);
        const children = editorState?.root?.children;

        const newEditorState = {
          ...editorState,
          root: {
            ...editorState.root,
            children: trimEditorContent(children),
          },
        };

        content = JSON.stringify(newEditorState);
      }

      await handlePollContent(content);
      setProcessing(false);
    },
    [handlePollContent, setProcessing],
  );

  return isDesktopPlus ? (
    <CreateTextCommentModal
      isVisible
      placeholder={placeholder}
      onClose={handleClose}
      onSubmit={handleSubmit}
      processing={processing}
      isCreateCommentSuccess={!processing}
      isNeedActivateEditor
      initialValues={initialValues}
    />
  ) : (
    <TextCommentEditorBottomSheet
      visible
      onCloseBottomSheet={handleClose}
      onSubmit={handleSubmit}
      processing={processing}
      placeholder={placeholder}
      title={title}
      initialValues={initialValues}
    />
  );
});
