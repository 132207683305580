import { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { followQueryKeys } from 'query-hooks/followings/contastants/query-keys.constants';
import { useGetAllFollowQuery } from 'query-hooks/followings/queries/use-get-all-follow.query';
import { useFollowData } from 'query-hooks/followings/use-follow-data';

import { IFollow } from 'services/follow/interfaces/follow.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { IAthlete } from 'stores/auth/interfaces/athlete.interface';
import { ContentCardsStore } from 'stores/content-cards/content-cards.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { PlayerStore } from 'stores/player/player.store';
import { SplitsGamesStore } from 'stores/splits-games/splits-games.store';
import { TeamsStore } from 'stores/teams/teams.store';

import { DeeplinkBannerContainer } from 'containers/deeplink-banner/deeplink-banner.container';
import { HeaderContainer } from 'containers/header/header.container';
import { getSubMenuItems } from 'containers/layout/layout.utils';
import { SidebarContainer } from 'containers/sidebar/sidebar.container';

import { DEFAULT_GREY_ICON_COLOR, SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { CAPACITOR_ENV } from 'configs/environment.config';
import { NBA_FILTER_NAME } from 'configs/google-analytics.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import {
  CSS_VAR_BACKDROP_OPACITY,
  CSS_VAR_BACKDROP_VISIBILITY,
  CSS_VAR_CONTAINER,
  MAX_BACKDROP_OPACITY,
  MAX_LEFT_SIDEBAR_WIDTH,
} from 'configs/swipe-navigation.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { ARENA_PART, PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { useAthleteRedirect } from 'hooks/use-athlete-redirect';
import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';
import { useScrollContainer } from 'hooks/use-scroll-container';
import { useScrollToTop } from 'hooks/use-scroll-to-top';
import { useViewportHeight } from 'hooks/use-viewport-height';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType, BarMultipleAction } from 'components/bars/bar-action.type';
import { BarActionsListType } from 'components/bars/bar-actions-list-type.enum';
import { IBarItem } from 'components/bars/bar-item.interface';
import { BottomBarMobile } from 'components/bars/bottom-bar-mobile/bottom-bar-mobile.component';
import { FavoritesIonBottomSheet } from 'components/bottom-sheet/favorites-ion-bottom-sheet/favorites-ion-bottom-sheet.component';
import { Refresher } from 'components/refresher/refresher.component';
import { SwipeNavigationType } from 'components/swipe-navigation/enums/swipe-navigation-type.enum';
import { SwipeNavigation } from 'components/swipe-navigation/swipe-navigation.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './layout.module.less';

interface ILayoutContainerProps {
  children: ReactNode;
}

export const LayoutContainer: FC<ILayoutContainerProps> = observer(
  (props: PropsWithChildren<ILayoutContainerProps>) => {
    const { children } = props;

    const params = useParams<{
      [paths.TEAM_ID_PARAM]: string;
      [paths.POST_ID_PARAM]: string;
      [PLAYER_SLUG_PARAM]: string;
      [paths.USERNAME_PARAM]: string;
    }>();

    const { teamId, postId, playerSlug, username } = params;

    const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
    const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
    const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
    const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
    const splitsGamesStore = useInjection<SplitsGamesStore>(TYPES.SplitsGamesStore);
    const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
    const authStore = useInjection<AuthStore>(TYPES.AuthStore);
    const contentCardsStore = useInjection<ContentCardsStore>(TYPES.ContentCardsStore);

    const navigate = useNavigate();
    const location = useLocation();

    const { isNativeApp } = useMainProvider();

    useAthleteRedirect(authStore.isAthlete, authStore.userMe?.athlete?.playerSlug);

    const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
    const scrollElementRef = useRef<Maybe<HTMLElement>>(null);

    const queryClient = useQueryClient();

    const { sortedFollowings, getTeamById } = useFollowData();

    const { data: followings } = useGetAllFollowQuery();

    const currentTeam = getTeamById(teamsStore.teamId);

    // temporary solution while auth store will not be migrate to react query
    useEffect(() => {
      if (authStore.isAuthorised || authStore.isAuthorisedAnonymously) {
        queryClient.invalidateQueries({ queryKey: followQueryKeys.prefix });
      }
    }, [authStore.isAuthorised, authStore.isAuthorisedAnonymously, queryClient]);

    // temporary solution while content cards store will not be migrate to react query
    useEffect(() => {
      if (followings) {
        contentCardsStore.refreshContentCards();
      }
    }, [followings, contentCardsStore]);

    // temporary solution while teamFeeds store will not be migrate to react query
    useEffect(() => {
      if (followings) {
        teamsStore.fetchTeams();
      }
    }, [followings, teamsStore]);

    useEffect(() => {
      if (location.pathname.includes(ARENA_PART)) {
        return;
      }

      if (!playerSlug && (splitsGamesStore.selectedTeamId || splitsGamesStore.splitSize)) {
        splitsGamesStore.setSplitSize(null);
        splitsGamesStore.setSelectedTeamId(null);
      }
    }, [
      splitsGamesStore,
      location.pathname,
      splitsGamesStore.selectedTeamId,
      splitsGamesStore.splitSize,
      playerSlug,
    ]);

    useScrollToTop<HTMLElement>(scrollElementRef);
    useViewportHeight();
    useKeyboardTrigger(isNativeApp);

    const { isFixedSliderToggle, isHiddenSliderToggle, handleScrollContainer } = useScrollContainer(
      !!layoutStore.tabs?.length,
      location.pathname,
    );

    useEffect(() => {
      layoutStore.setHiddenSliderToggle(isHiddenSliderToggle);
    }, [isHiddenSliderToggle, layoutStore]);

    useEffect(() => {
      layoutStore.setFixedSliderToggle(isFixedSliderToggle);
    }, [isFixedSliderToggle, layoutStore]);

    useEffect(() => {
      if (teamId) {
        teamsStore.setTeamId(Number(teamId));
      } else {
        teamsStore.reset();
      }
    }, [teamId, teamsStore]);

    const isGlobalMenu = useMemo(() => {
      return !teamId && !playerSlug && !username;
    }, [teamId, playerSlug, username]);

    const bottomBarItems = useMemo<IBarItem<BarMultipleAction<BarActionsListType>>[]>(
      () => [
        {
          name: 'Home',
          action: {
            type: BarAction.Link,
            path: paths.HOME_FEED,
            payload: BarActionsListType.Home,
          },
          isDisabled: false,
          icon: { default: IconFontName.Home, active: IconFontName.HomeFilled },
        },
        {
          name: 'Scores',
          action: {
            type: BarAction.Link,
            path: paths.HOME_SCORES,
            payload: BarActionsListType.Scores,
          },
          isDisabled: false,
          icon: { default: IconFontName.Arena, active: IconFontName.ArenaFilled },
        },
        {
          name: 'Favorites',
          isDisabled: false,
          action: {
            type: BarAction.Click,
            payload: BarActionsListType.Favorites,
          },
          icon: { default: IconFontName.Star, active: IconFontName.Star },
        },
      ],
      [],
    );

    const subBottomBarItems = useMemo<IBarItem<BarMultipleAction<BarActionsListType>>[]>(() => {
      const isAllowedStatsMenuItem = !(
        username === authStore.userMe?.username && authStore.userMe?.athlete
      );

      return getSubMenuItems(
        isGlobalMenu,
        playerSlug || null,
        teamId || null,
        username || null,
        isAllowedStatsMenuItem,
      );
    }, [
      username,
      authStore.userMe?.username,
      authStore.userMe?.athlete,
      isGlobalMenu,
      playerSlug,
      teamId,
    ]);

    useEffect(() => {
      if (isDesktopPlus) {
        layoutStore.setMobileSidebarOpen(false);
      }
    }, [isDesktopPlus, layoutStore]);

    const handleScrollTopClick = useCallback(() => {
      const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

      topElement?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const handleCloseFavorites = useCallback(() => {
      layoutStore.setIsFavoritesPopupOpen(false);
    }, [layoutStore]);

    const handleToggleFavorites = useCallback(() => {
      layoutStore.setIsFavoritesPopupOpen(!layoutStore.isFavoritesPopupOpen);
    }, [layoutStore]);

    const handleClickActions = useCallback(
      (payloadType: string) => {
        if (payloadType === BarActionsListType.Favorites) {
          handleToggleFavorites();
        }
      },
      [handleToggleFavorites],
    );

    const handleGaBottomBarButtonTap = useCallback((barActionType: string) => {
      if (BarActionsListType.Home === barActionType) {
        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.HomeFeed,
            feed: NBA_FILTER_NAME,
          },
        });
      }
    }, []);

    const handleItemClick = useCallback(
      (action: BarActionType) => {
        if (action.payload) {
          handleGaBottomBarButtonTap(action.payload);
        }
        if (action.type === BarAction.Link) {
          navigate(action.path, {
            replace: true,
          });
        }
        if (action.type === BarAction.Click && action.payload) {
          handleClickActions(action.payload);
        }

        handleScrollTopClick();
        layoutStore.setMobileSidebarOpen(false);
      },
      [layoutStore, navigate, handleClickActions, handleScrollTopClick, handleGaBottomBarButtonTap],
    );

    const handleSidebarItemClick = useCallback(
      (action: BarActionType) => {
        if (action.type === BarAction.Link) {
          navigate(action.path, {
            replace: true,
          });
        }
        if (action.type === BarAction.Click && action.payload === 'contact') {
          navigate(paths.CONTACT_US);
        }
      },
      [navigate],
    );

    const handleCloseSidebar = useCallback(() => {
      if (layoutStore.isMobileSidebarOpen) {
        layoutStore.setMobileSidebarOpen(false);
      }
      if (layoutStore.isSidePanelOpen) {
        layoutStore.setSidePanelActiveTab(null);
      }
    }, [layoutStore]);

    const classNames = useMemo<string>(
      () =>
        cn(styles.Layout, {
          [styles['Layout--shifted']]: layoutStore.isMobileSidebarOpen,
        }),
      [layoutStore.isMobileSidebarOpen],
    );

    useEffect(() => {
      metaTagsStore.setRoute(location.pathname);
    }, [location.pathname, metaTagsStore]);

    useEffect(() => {
      if ((layoutStore.isMobileSidebarOpen || layoutStore.isSidePanelOpen) && !isDesktopPlus) {
        document.documentElement.style.setProperty(
          CSS_VAR_BACKDROP_OPACITY,
          `${MAX_BACKDROP_OPACITY}`,
        );
        document.documentElement.style.setProperty(CSS_VAR_BACKDROP_VISIBILITY, 'visible');
      } else {
        document.documentElement.style.removeProperty(CSS_VAR_BACKDROP_OPACITY);
        document.documentElement.style.removeProperty(CSS_VAR_BACKDROP_VISIBILITY);
      }
    }, [layoutStore.isMobileSidebarOpen, layoutStore.isSidePanelOpen, isDesktopPlus]);

    useEffect(() => {
      if (layoutStore.isMobileSidebarOpen) {
        document.documentElement.style.setProperty(CSS_VAR_CONTAINER, MAX_LEFT_SIDEBAR_WIDTH);
      } else {
        document.documentElement.style.removeProperty(CSS_VAR_CONTAINER);
      }
    }, [layoutStore.isMobileSidebarOpen]);

    useEffect(() => {
      const title = metaTagsStore.metaTags?.title.replace('Digits - ', '');

      if (title && applicationStore.isNativeApp) {
        GoogleAnalyticService.setScreenAppName(title);
      }
    }, [metaTagsStore.metaTags?.title, applicationStore.isNativeApp]);

    useEffect(() => {
      const isNativeAppInDevMode = applicationStore.isNativeApp && CAPACITOR_ENV === 'dev';

      if (isNativeAppInDevMode) {
        const marker = document.querySelector<HTMLDivElement>('#marker');

        if (marker) {
          marker.style.display = 'block';
        }
      }
    }, [applicationStore.isNativeApp]);

    const subMenuBackgroundColor = useMemo(() => {
      if (currentTeam) {
        return currentTeam.color;
      }

      if (playerStore.playerDetails?.team) {
        return playerStore.playerDetails.team.color;
      }

      return null;
    }, [currentTeam, playerStore.playerDetails]);

    const subMenuTextColor = useMemo(() => {
      if (currentTeam) {
        return currentTeam.secondaryTextColor;
      }

      if (playerStore.playerDetails?.team) {
        return DEFAULT_GREY_ICON_COLOR;
      }

      return null;
    }, [currentTeam, playerStore.playerDetails]);

    const handleFollowClick = useCallback(() => {
      navigate(paths.FOLLOWING);
      handleCloseFavorites();
    }, [navigate, handleCloseFavorites]);

    const handleFollowedItemClick = useCallback(
      (following: IFollow) => () => {
        handleCloseFavorites();
        if (following.type === 'team') {
          navigate(
            getPath(paths.TEAM_FEED, {
              [paths.TEAM_ID_PARAM]: following.id.replace(/\D/g, ''),
            }),
          );
        }

        if (following.type === 'player') {
          navigate(
            getPath(paths.PLAYER_PROFILE_FAN_ZONE, {
              [paths.PLAYER_SLUG_PARAM]: following.slug,
            }),
          );
        }
      },
      [navigate, handleCloseFavorites],
    );

    const handleAthleteClick = useCallback(
      (athlete: IAthlete) => () => {
        handleCloseFavorites();

        navigate(
          getPath(paths.PLAYER_PROFILE, {
            [paths.PLAYER_SLUG_PARAM]: athlete.playerSlug,
          }),
        );
      },
      [navigate, handleCloseFavorites],
    );

    return (
      <>
        <Helmet>
          <title>{metaTagsStore.metaTags?.title}</title>
        </Helmet>
        <SwipeNavigation
          tabs={layoutStore.tabs}
          isAllowedSwipeNavBack={layoutStore.isAllowedSwipeNavBack}
          isLeftSidebarOpen={layoutStore.isMobileSidebarOpen}
          setLeftSidebarOpen={layoutStore.setMobileSidebarOpen}
          setDisabledScroll={layoutStore.setDisabledScroll}
          swipeNavType={SwipeNavigationType.TopLevel}
        >
          <div className={classNames}>
            {!layoutStore.isHiddenHeaderMobile && !layoutStore.isHiddenMobileSidebar && (
              <SidebarContainer onSidebarItemClick={handleSidebarItemClick} />
            )}
            <div className={styles.LayoutContentWrapper}>
              {!layoutStore.isHiddenHeaderMobile && (
                <div className={styles.LayoutTopInner}>
                  <HeaderContainer />
                </div>
              )}
              <button
                aria-label="Backdrop"
                onClick={handleCloseSidebar}
                className={styles.Backdrop}
              />
              <Refresher
                ref={scrollElementRef}
                isPulledRefresher={layoutStore.isPulledRefresher}
                isDisabledScroll={layoutStore.isDisabledScroll || layoutStore.isScrollForceDisabled}
                isRefresherTopPosition={layoutStore.isRefresherTopPosition}
                isEnabledRefresher={layoutStore.isEnabledRefresher}
                isHiddenBottomBar={layoutStore.isHiddenBottomBar}
                isHeaderDisabled={layoutStore.isHiddenHeaderMobile}
                isRemovedSafeAreaTop={layoutStore.isRemovedSafeAreaTop}
                isDesktopPlus={isDesktopPlus}
                isNativeApp={isNativeApp}
                isMobileSidebarOpen={layoutStore.isMobileSidebarOpen}
                isSidePanelOpen={layoutStore.isSidePanelOpen}
                isDisplayedSmartBanner={layoutStore.isDisplayedSmartBanner}
                hasPostId={!!postId}
                hasTabs={!!layoutStore.tabs?.length}
                setPulledRefresher={layoutStore.setPulledRefresher}
                onScrollContainer={handleScrollContainer}
              >
                {children}
              </Refresher>
              {!isDesktopPlus && !layoutStore.isHiddenBottomBar && (
                <div className={styles.Layout__BottomBar}>
                  <BottomBarMobile
                    activePath={location.pathname}
                    items={bottomBarItems}
                    subBottomBarItems={subBottomBarItems}
                    onClick={handleItemClick}
                    subMenuTextColor={subMenuTextColor}
                    subMenuBackgroundColor={subMenuBackgroundColor}
                  />
                </div>
              )}
              <DeeplinkBannerContainer />
            </div>
          </div>
          <FavoritesIonBottomSheet
            visible={layoutStore.isFavoritesPopupOpen}
            onClose={handleCloseFavorites}
            favorites={sortedFollowings}
            digitsAthletes={applicationStore.digitsAthletes}
            onFollowClick={handleFollowClick}
            onFollowedItemClick={handleFollowedItemClick}
            onAthleteClick={handleAthleteClick}
          />
        </SwipeNavigation>
      </>
    );
  },
);
